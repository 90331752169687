<template>
  <button
    type="button"
    aria-label="Site Wide Search"
    :aria-expanded="navOpen ? 'true' : 'false'"
    aria-controls="navigation-drawer"
    :class="`header__button header__button--search${navOpen ? ' header__button--search-open' : ''}`"
    @click="handleClick"
  >
    <icon-component
      name="magnifying-glass"
      class="header__button-icon"
    />
    <span class="header__button-text">Search</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SearchButton',
  computed: {
    ...mapGetters(['navOpen']),
  },
  methods: {
    handleClick(event) {
      if (this.navOpen) {
        this.$store.dispatch('setFocusSearch', true);
      } else {
        this.$emit('topNavigation', { label: 'toggle', opening: true });
        this.$store.dispatch('setNavOpen', true);
        this.$emit('search-focus');
        this.$store.dispatch('setFocusSearch', true);
      }

      this.$snowplow.trackButtonEvent({
        data: {
          type: 'search',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
    },
  },
};
</script>
