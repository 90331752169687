import gql from 'graphql-tag';

export const ADD_REACTION = gql`
  mutation createReaction($assocId: NexusId!, $reactedType: ReactableType!, $type: String!) {
    createReaction(assocId: $assocId, reactedType: $reactedType, type: $type) {
      type
    }
  }
`;

export const REMOVE_REACTION = gql`
  mutation removeReaction($assocId: NexusId!, $reactedType: ReactableType!) {
    removeReaction(assocId: $assocId, reactedType: $reactedType) {
      type
    }
  }
`;
