<template>
  <div v-if="showLeadGen" class="lead-gen">
    <site-logo v-if="showLogo" />
    <form-component
      class="lead-gen__form"
      method="post"
      data-test-id="lead-gen-form"
      @submit.prevent="handleSubscribe"
    >
      <input type="hidden" name="campaign" :value="campaign">
      <input type="hidden" name="source" :value="source">
      <slot v-if="$slots.default" />
      <label
        v-else
        class="lead-gen__label"
        :for="id"
      >
        {{ labelText }}
      </label>
      <div class="lead-gen__input-container">
        <text-input
          :id="id"
          v-model="email"
          exclude-label
          placeholder="Enter your email"
          input-type="email"
          required
          name="email"
          class="lead-gen__input text-input--full-width"
        />
        <button-component
          class="lead-gen__button"
          data-gtm_category="lead_gen"
          data-gtm_action="form_submit"
          data-gtm_label="lead_gen_shortcode"
          button-type="subscribe to newsletter"
        >
          Subscribe
        </button-component>
      </div>
      <p class="lead-gen__disclaimer disclaimer-text">
        By providing your email address, you are agreeing to our
        <a
          href="https://health-union.com/privacy-policy/"
          target="_blank"
          rel="noopener"
        >Privacy Policy</a>
        and
        <a
          href="https://health-union.com/terms-of-use/"
          target="_blank"
          rel="noopener"
        >Terms of Use</a>.
      </p>
    </form-component>
  </div>
</template>

<script>
import SiteLogo from '@/components/Global/SiteLogo.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import { mapGetters } from 'vuex';
import { GENERATE_LEAD } from '@/graphql/mutations/lead-gen-mutations';
import dataLayerMixin from '@/mixins/data-layer-mixin';

export default {
  name: 'LeadGen',
  components: {
    SiteLogo,
    TextInput,
    ButtonComponent,
  },
  mixins: [dataLayerMixin],
  props: {
    // Used to set the id attribute for the leadgen form, useful if there are multiple
    // Leadgen components on a single page
    id: {
      type: String,
      default: 'leadgen-email-address',
    },
    campaign: {
      type: String,
      default: 'site',
    },
    source: {
      type: String,
      default: 'shortcode',
    },
    form: {
      type: String,
      default: '',
    },
    medium: {
      type: String,
      default: null,
    },
    showIfLoggedIn: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Sign up for email from {{site-name}}:',
    },
    success: {
      type: String,
      default: 'Thanks for signing up!',
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
    ]),
    showLeadGen() {
      if (this.showIfLoggedIn) { return true; }
      return !this.userIsLoggedIn;
    },
    labelText() {
      return this.title.replace('{{site-name}}', this.$site.name);
    },
  },
  methods: {
    handleSubscribe() {
      this.$apollo.mutate({
        mutation: GENERATE_LEAD,
        variables: {
          campaign: this.campaign,
          email: this.email,
          form: this.form || this.source,
          pageviewId: this.$store.getters.pageViewId,
          medium: this.medium || 'Site',
        },
      }).catch(() => {
        this.$logger.warn('problem submitting new lead.');
      });

      this.$store.dispatch('setUserHasSubscribed', true);
      this.setDataLayer();
      this.pushDataLayer();
      this.setTrackingInAC(this.email);

      // always display success toast to avoid delay between submitting & getting a response from API
      this.$store.dispatch('addToastNotification', {
        toastType: 'success',
        description: this.success,
        type: 'Opt In',
      });
      this.email = '';

      this.$gtm.trackEvent({
        event: 'nexus.event',
        action: 'form_submit',
        label: 'lead_gen_shortcode',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_lead-gen';
</style>

<docs>
Renders a form allowing visitors to sign up for email newsletters

</docs>
