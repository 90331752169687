<template>
  <button
    class="reaction-button"
    @click="handleReactionButtonClick"
  >
    <icon-component
      v-if="selectedReaction"
      :key="selectedReaction"
      title="Reactions"
      :name="reactionName"
      :class="`reaction-button__icon reaction-button__icon--${selectedReaction}`"
    />
    <!-- if the user hasn't selected a reaction, the default is 'like' -->
    <icon-component
      v-else
      key="like"
      title="Reactions"
      name="like"
      class="reaction-button__icon reaction-button__icon--default"
    />
    <span
      v-if="showReactionCount"
      class="reaction-button__text reaction-button__text--count"
    >{{ reactionCount }}</span>
    <span
      class="reaction-button__text reaction-button__text--name"
    >reactions</span>
  </button>
</template>

<script>
export default {
  name: 'ReactionButton',
  props: {
    reactionCount: {
      type: Number,
      required: true,
    },
    selectedReaction: {
      type: String,
      default: '',
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  computed: {
    reactionName() {
      return this.selectedReaction;
    },
    showReactionCount() {
      if (this.contentType === 'comment' && this.reactionCount === 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleReactionButtonClick(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'reaction',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('toggle-reaction-menu');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_reaction-button';
</style>

<style lang="scss"> // no scoped
  // because these styles require reaching classes of child components this must
  // be in a non-scoped style tag. There is a "deep" combinator available in vue
  // (::v-deep, /deep/, or >>>)
  // but it is not compatible with our usage of sass-loader

  .reaction-button {
    @for $i from 1 through length($reactions) {
      .reaction-button__icon--#{nth($reactions, $i)} {

        .contrast-light & {
          fill: nth($reactions-outline-colors, $i);
        }
        fill: nth($reactions-outline-colors, $i);

        .contrast-dark & {
          fill: nth($reactions-outline-colors, $i);
        }

        .reaction-icon-background {
          fill: nth($reactions-bg-colors, $i);

          .contrast-light & {
            fill: nth($reactions-bg-colors, $i);
          }

          .contrast-dark & {
            fill: nth($reactions-bg-colors, $i);
          }
        }
      }
    }

    @include landscape-tablet-up {
      .social-share &:hover {
        .reaction-icon-background {
          fill: rgba($cyan, 0.5);
        }
      }
    }
  }
</style>

<docs>

Reaction button takes a Reaction Count, and selected Reaction
(default is 'like').  It will show the Reaction Menu when clicked on.

</docs>
