<template>
  <button
    class="send-message-button text-button"
    type="button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span class="visually-hidden">Send message</span>
    <icon-component
      name="send"
      title="Send message"
      class="send-message-button__icon"
    />
  </button>
</template>

<script>
export default {
  name: 'SendMessageButton',
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_send-message-button';
</style>

<docs>

A button used to send a private message

</docs>
