<template>
  <li
    class="nav-item-sub-community"
    :class="`nav-item-sub-community--${navItemSubCommunityClassName(community.name)}`"
  >
    <a
      :href="`https://${community.name.toLowerCase()}`"
      class="nav-item-sub-community__link link link--unstyled"
      target="_blank"
    >
      <span class="nav-item-sub-community__text">
        {{ formattedCommunityName(community.name) }}
      </span>
      <icon-component
        name="website"
        title="icon"
        class="nav-item-sub-community__icon"
      />
    </a>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import childSitesMixin from '@/mixins/child-sites-mixin';

export default {
  name: 'NavItemSubCommunity',
  mixins: [childSitesMixin],
  props: {
    community: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isParentSite']),
  },
  methods: {
    navItemSubCommunityClassName(community) {
      const communityName = community.split('.')[0];
      return communityName.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_nav-item-sub-community';
</style>
