const PostComponent = () => import('@/pages/Blog/PostComponent.vue');
const QuizCollection = () => import('@/pages/Quiz/QuizCollection.vue');

export default [
  {
    path: '/quizzes',
    name: 'quizzes',
    component: QuizCollection,
  }, {
    path: '/quizzes/:slug',
    name: 'quiz',
    component: PostComponent,
    meta: { types: ['quiz'] },
  },
];
