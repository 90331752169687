const RegisterPage = () => import('@/pages/Auth/RegisterPage.vue');
const LoginPage = () => import('@/pages/Auth/LoginPage.vue');

export default [
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
    meta: { notLoggedIn: true },
  },
  {
    path: '/member-login',
    name: 'login',
    component: LoginPage,
    meta: { notLoggedIn: true },
  },
  // not sure if we're doing this wp redirect in cloudfront or not, put here just in case or until we know
  { path: '/admin', redirect: { name: 'login' } },
  // For users that created an account in WP but hadn't activated before the Nexus migration
  { path: '/dashboard/wp-login.php', redirect: '/forgot-password' },
];
