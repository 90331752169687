<template>
  <transition
    appear
    name="toast-notification"
  >
    <div
      class="toast-notification"
      :class="`toast-notification--${notification.toastType}`"
      aria-role="alert"
      aria-live="assertive"
    >
      <p class="toast-notification__message">
        {{ notification.description }}
      </p>
      <p
        v-for="(action, index) in notification.actions"
        :key="index"
        class="toast-notification__action"
        @click="action.handler"
      >{{ action.text }}</p>
      <button
        id="close"
        class="toast-notification__action"
        @click="closeToast"
      >
        <icon-component
          name="x"
          class="toast-notification__icon icon--smallest"
        />
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ToastNotification',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    this.$snowplow.trackToastNotification({
      data: {
        toast_type: this.notification.type || '',
        toast_url: '',
        toast_text: this.notification.description || '',
        class_list: ['toast-notification'],
        toast_id: this.notification.id,
      },
    });
    this.timer = window.setTimeout(() => {
      this.clearSelf();
    }, this.notification.clearAfter || 5000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    clearSelf() {
      this.$store.dispatch('removeToastNotification', this.notification.id);
    },
    closeToast(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'toast notification',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$store.dispatch('removeToastNotification', this.notification.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_toast-notification';
</style>

<docs>
Renders a Toast-style notification with appropriate data

Trigger a notification using a Vuex action.

Remove a notification programmatically using a Vuex action.
</docs>
