/**
 * Reactions Mediator plugin
 *
 * This plugin is responsible for querying for reactions and providing subscribing
 * components with the data once it is available.
 */
import { REACTIONS_QUERY } from '@/graphql/queries/reaction-queries';
import { contentTypeToReactionType } from '@/utils/reaction-helpers';
import { loggerApi } from '@/plugins/logger';

// Timer delay set to 0 waits for the call stack to clear
// This works well locally, we may need to increase this in production
const timerDelay = 0;
let timer;

const assocToId = (reactionType, assocId) => `${reactionType}:${assocId}`;

const assocFromID = (assocId) => {
  const values = assocId.split(':');
  return {
    assocType: values[0],
    assocId: values[1],
  };
};

const api = ({ apolloProvider, context }) => ({
  logger: loggerApi({ context }),
  subscribers: {},
  reactables() {
    return Object.keys(this.subscribers).map(assocFromID);
  },
  async getReactions() {
    const apollo = apolloProvider.clients.defaultClient;

    await apollo.query({
      query: REACTIONS_QUERY,
      variables: {
        reactables: this.reactables(),
      },
    }).then(({ data }) => {
      if (!data.reactions) { return; }

      data.reactions.forEach((reactionSet) => {
        // TODO: we probably want to push the reactions into vueX here so the state can be shared
        // later when ADD_REACTION is called in the component
        const subscriberId = assocToId(reactionSet.assocType, reactionSet.assocId);
        this.publish(subscriberId, reactionSet.reactionAggregates);
      });
    }).catch((error) => {
      this.logger.error(error);
    });
  },
  subscribe(contentType, id, callback) {
    const reactionType = contentTypeToReactionType(contentType);
    const subscriberId = assocToId(reactionType, id);

    if (!this.subscribers[subscriberId]) {
      this.subscribers[subscriberId] = [];
    }

    this.subscribers[subscriberId].push({ callback });

    if (timer) {
      clearTimeout(timer);
    }

    timer = window.setTimeout(() => {
      this.getReactions();
    }, timerDelay);
  },
  publish(subscriberId, reactionAggregates) {
    const subscriptions = this.subscribers[subscriberId];
    if (!subscriptions) { return false; }

    subscriptions.forEach((subscription) => {
      subscription.callback(reactionAggregates);
    });

    delete this.subscribers[subscriberId];

    return true;
  },
  clearSubscriptions() {
    this.subscribers = {};
    if (timer) {
      clearTimeout(timer);
    }
  },
});

const ReactionsMediator = {
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$reactions = api(options);
    Vue.mixin({
      beforeRouteLeave(to, from, next) {
        this.$reactions.clearSubscriptions();
        next();
      },
    });
  },
};

export default ReactionsMediator;
