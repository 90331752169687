<template>
  <transition
    name="profile-menu"
    appear
  >
    <div
      id="profile-menu"
      v-click-outside="onClickOutside"
      tabindex="0"
      class="profile-menu"
      :class="{ 'profile-menu--open': profileMenuOpen }"
      aria-description="Profile Menu, links related to your profile and account."
    >
      <div class="profile-menu__heading">
        <router-link
          :to="{ name: 'userProfileOverview' }"
          class="profile-menu__heading-link"
          @click.native="closeProfileMenu"
        >
          <h3 class="profile-menu__username">{{ currentUser.username }}</h3>
          <role-badge :user="currentUser" />
        </router-link>
      </div>
      <ul class="profile-menu__actions list-reset">
        <li class="profile-menu__item">
          <router-link
            :to="{ name: 'userProfileOverview' }"
            class="profile-menu__item-link"
            @click.native="closeProfileMenu"
          >
            My Profile
          </router-link>
        </li>
        <li class="profile-menu__item">
          <router-link
            :to="{ name: 'userNotifications' }"
            class="profile-menu__item-link"
            aria-label="Notifications"
            @click.native="closeProfileMenu"
          >
            Notifications
            <span
              v-if="unreads.notifications > 0"
              class="profile-menu__item-count"
            >({{ unreads.notifications }})</span>
          </router-link>
        </li>
        <li v-if="siteHasFeature('private_messaging')" class="profile-menu__item">
          <router-link
            :to="{ name: 'messages' }"
            class="profile-menu__item-link"
            :aria-label="messagesName"
            @click.native="closeProfileMenu"
          >
            {{ messagesName }}
            <span
              v-if="unreads.messages > 0"
              class="profile-menu__item-count"
            >({{ unreads.messages }})</span>
          </router-link>
        </li>
        <li class="profile-menu__item profile-menu__item--secondary">
          <router-link
            :to="{ name: 'userEditProfile' }"
            class="profile-menu__item-link"
            @click.native="closeProfileMenu"
          >
            <icon-component
              name="gear"
              title="gear icon"
              class="profile-menu__icon"
            />
            Account Settings
          </router-link>
        </li>
        <li
          v-if="isModeratorOrAdmin"
          class="profile-menu__item profile-menu__item--secondary"
        >
          <a
            href="/moderation"
            class="profile-menu__item-link"
            @click="closeProfileMenu"
          >
            Moderation
          </a>
        </li>
        <li
          v-if="isAdmin"
          class="profile-menu__item profile-menu__item--secondary"
        >
          <a
            href="/wp-admin/"
            class="profile-menu__item-link"
            @click="closeProfileMenu"
          >
            WP Admin
          </a>
        </li>
        <li class="profile-menu__item profile-menu__item--secondary">
          <logout-button />
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import LogoutButton from '@/components/Auth/LogoutButton.vue';
import RoleBadge from '@/components/User/RoleBadge.vue';
import { UNREAD_COUNTS } from '@/graphql/queries/user-queries';
import { isFeatureFlagEnabled } from '@/utils/utils';

export default {
  name: 'ProfileMenu',
  components: {
    LogoutButton,
    RoleBadge,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      unreads: {
        messages: 0,
        notifications: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'profileMenuOpen',
      'isAdmin',
      'isModeratorOrAdmin',
      'siteHasFeature',
    ]),
    messagesName() {
      return isFeatureFlagEnabled(this.$site, 'restrict_private_messaging') ? 'Messages' : 'Private Messages';
    },
  },
  mounted() {
    this.$el.focus();
  },
  methods: {
    closeProfileMenu() {
      this.$store.dispatch('setProfileMenuOpen', false);
    },
    onClickOutside(event) {
      const targetIsTrigger = event.target.classList.contains('header__button--profile');
      if (!this.profileMenuOpen || targetIsTrigger) { return; }

      this.closeProfileMenu();
    },
  },
  apollo: {
    unreads: {
      query: UNREAD_COUNTS,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_profile-menu';
</style>

<docs>
</docs>
