const postState = () => ({
  id: '',
});

const mutations = {
  setCurrentPostId(state, data) {
    state.id = data;
  },
};

const actions = {
  setCurrentPostId({ commit }, data) {
    commit('setCurrentPostId', data);
  },
};

export default () => ({
  state: postState(),
  mutations,
  actions,
});
