const LeadGen = () => import('@/components/Global/LeadGen.vue');
const LeadGenCustomForm = () => import ('./LeadGenCustomForm.vue');
const PostTeaser = () => import('@/components/Posts/PostTeaser.vue');
const AdSlot = () => import('@/components/Ads/AdSlot.vue');
const FeatureSection = () => import('@/components/Content/Sections/FeatureSection.vue');
const ThreadSection = () => import('@/components/Content/Sections/ThreadSection.vue');
const RecommendedReading = () => import('@/components/Content/Sections/RecommendedReading.vue');
const RecommendationComponent = () => import('@/components/Content/Sections/RecommendationComponent.vue');
const ImageGallery = () => import('@/components/ImageGallery/ImageGallery.vue');
const ExplorerQuiz = () => import('@/components/Quiz/ExplorerQuiz.vue');
const SymptomChecker = () => import('@/components/SymptomChecker/SymptomChecker.vue');
const ColumnsSection = () => import('./ColumnsSection.vue');
const ContentHTML = () => import('./ContentHtml.vue');
const TableSection = () => import('./TableSection.vue');
const ThisOrThat = () => import('@/components/EngagementToolkit/ThisOrThat.vue');
const ForumDriver = () => import ('@/components/EngagementToolkit/ForumDriver.vue');
const ForumStarter = () => import ('@/components/EngagementToolkit/ForumStarter.vue');
const QuizletComponent = () => import ('@/components/EngagementToolkit/QuizletComponent.vue');
const PollQuery = () => import ('@/components/EngagementToolkit/PollQuery.vue');
const Giveaway = () => import ('@/components/EngagementToolkit/Giveaway.vue');
const PatientInsiderHero = () => import ('@/components/Content/Sections/PatientInsiderHero.vue');
const PatientInsiderReadingList = () => import ('@/components/Content/Sections/PatientInsiderReadingList.vue');
const CopyItem = () => import ('@/components/Content/Sections/CopyItem.vue');
const QuoteComponent = () => import ('@/components/Content/Sections/QuoteComponent.vue');
const ImageSection = () => import ('@/components/Content/Sections/ImageSection.vue');
const StoryWithAd = () => import ('@/components/Content/Sections/StoryWithAd.vue');
const StickyCornerAd = () => import ('@/components/Ads/StickyCornerAd.vue');
const VideoWithCopy = () => import ('@/components/Content/Sections/VideoWithCopy.vue');
const WordRx = () => import ('@/components/Game/WordRx.vue');

// Quiz results page sections
const SegmentHeader = () => import('@/components/Quiz/SegmentHeader.vue');
const SectionHeader = () => import('@/components/Quiz/SectionHeader.vue');
const RecommendedVideo = () => import('@/components/Quiz/RecommendedVideo.vue');
const TopArticles = () => import('@/components/Content/Sections/TopArticles.vue');
const OtherSegments = () => import('@/components/Content/Sections/OtherSegments.vue');

// Homepage only sections
const HomepageSlider = () => import('@/components/Content/Sections/HomepageSlider.vue');
const HomepageRecents = () => import('@/components/Content/Sections/HomepageRecents.vue');
const HomepageCommunity = () => import('@/components/Content/Sections/HomepageCommunity.vue');
const HomepageSubcommunities = () => import('@/components/Content/Sections/HomepageSubcommunities.vue');
const HomepageEngagement = () => import('@/components/Content/Sections/HomepageEngagement.vue');
const HomepageCollections = () => import('@/components/Content/Sections/HomepageCollections.vue');
const ConditionSearchSection = () => import('@/components/Content/Sections/ConditionSearchSection.vue');

export default {
  sectionComponents: {
    ContentHTML,
    ColumnsSection,
    LeadGen,
    LeadGenCustomForm,
    PostTeaser,
    AdSlot,
    FeatureSection,
    RecommendedReading,
    RecommendationComponent,
    HomepageSlider,
    HomepageRecents,
    HomepageCommunity,
    HomepageSubcommunities,
    HomepageEngagement,
    HomepageCollections,
    ImageGallery,
    ExplorerQuiz,
    SymptomChecker,
    OtherSegments,
    SegmentHeader,
    SectionHeader,
    TableSection,
    ThreadSection,
    TopArticles,
    ThisOrThat,
    ForumDriver,
    ForumStarter,
    Giveaway,
    QuizletComponent,
    PollQuery,
    RecommendedVideo,
    PatientInsiderHero,
    PatientInsiderReadingList,
    CopyItem,
    QuoteComponent,
    ImageSection,
    StoryWithAd,
    StickyCornerAd,
    VideoWithCopy,
    ConditionSearchSection,
    WordRx,
  },
  sectionTypesMap: {
    HTML: 'ContentHTML',
    AdSlot: 'AdSlot',
    Native: 'AdSlot',
    Columns: 'ColumnsSection',
    PostTeaser: 'PostTeaser',
    LeadGen: 'LeadGen',
    ClientLeadForm: 'LeadGenCustomForm',
    Feature: 'FeatureSection',
    Reading: 'RecommendedReading',
    Recommendation: 'RecommendationComponent',
    HomepageHero: 'HomepageSlider',
    HomepageRecents: 'HomepageRecents',
    HomepageCommunity: 'HomepageCommunity',
    HomepageSubcommunities: 'HomepageSubcommunities',
    HomepageEngagement: 'HomepageEngagement',
    HomepageCollections: 'HomepageCollections',
    Gallery: 'ImageGallery',
    Quiz: 'ExplorerQuiz',
    OtherSegments: 'OtherSegments',
    SegmentHeader: 'SegmentHeader',
    SectionHeader: 'SectionHeader',
    SlidingTable: 'TableSection',
    ThreadSection: 'ThreadSection',
    SymptomChecker: 'SymptomChecker',
    TopArticles: 'TopArticles',
    ThisOrThat: 'ThisOrThat',
    ForumDriver: 'ForumDriver',
    ForumStarter: 'ForumStarter',
    Giveaway: 'Giveaway',
    Quizlet: 'QuizletComponent',
    Poll: 'PollQuery',
    RecommendedVideo: 'RecommendedVideo',
    PatientInsiderHero: 'PatientInsiderHero',
    PatientInsiderReadingList: 'PatientInsiderReadingList',
    CopyItem: 'CopyItem',
    Quote: 'QuoteComponent',
    ImageSection: 'ImageSection',
    StickyCornerAd: 'StickyCornerAd',
    StoryWithAd: 'StoryWithAd',
    VideoWithCopy: 'VideoWithCopy',
    ConditionSearchSection: 'ConditionSearchSection',
    WordRx: 'WordRx',
  },
};
