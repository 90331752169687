<template>
  <form-component
    method="post"
    class="request-password-reset"
    @submit.prevent="requestPasswordResetEmail"
  >
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.emailOrUsername.$invalid }"
    >
      <validation-error
        ref="emailOrUsername"
        class="validation-error--label"
        :model="emailOrUsername"
        :validator="$v.emailOrUsername"
      />
      <text-input
        id="email-address-user-name"
        v-model="emailOrUsername"
        class="text-input--full-width"
        data-test-id="request-password-reset-input"
        name="email or username"
      >Username or email address</text-input>
      <recaptcha-component ref="recaptcha" @verify="onCaptchaVerified" />
    </div>
    <loading-button
      class="request-password-reset__button button--center"
      data-test-id="request-password-reset-button"
      type="submit"
      :loading="tryingToRequestPasswordReset"
      button-type="password reset request"
    >
      Send
    </loading-button>
  </form-component>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import ValidationError from '@/components/Global/ValidationError.vue';
import RecaptchaComponent from '@/components/Auth/RecaptchaComponent.vue';

export default {
  name: 'RequestPasswordReset',
  components: {
    TextInput,
    LoadingButton,
    ValidationError,
    RecaptchaComponent,
  },
  data() {
    return {
      emailOrUsername: '',
      tryingToRequestPasswordReset: false,
    };
  },
  validations: {
    emailOrUsername: {
      required,
    },
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      return this.$auth.requestPasswordReset({
        email_or_username: this.emailOrUsername,
        recaptcha_token: recaptchaToken,
      })
        .then((response) => {
          if (response.status === 'success') {
            this.$store.dispatch('addToastNotification', {
              toastType: 'success',
              description: 'Check your email!  If an account exists, we have sent you a link to create a new password',
              type: 'Registration',
            });
          }
          this.tryingToRequestPasswordReset = false;
          this.$store.dispatch('closeAuthDialog');
        })
        .catch((error) => {
          this.$logger.error(error);
          this.tryingToReset = false;
        });
    },
    requestPasswordResetEmail() {
      this.tryingToRequestPasswordReset = true;

      this.$v.$touch();
      this.$refs.emailOrUsername.checkErrors();

      if (this.$v.$invalid) {
        this.tryingToRequestPasswordReset = false;
        return false;
      }

      return this.$refs.recaptcha.execute();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_request-password-reset';
</style>
