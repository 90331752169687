<template>
  <div
    v-click-outside="onClickOutside"
    class="condition-select"
    :class="{
      'condition-select--open': !collapsible || showList || showNoMatches,
    }"
    @focuswithin="optionsAreVisible = true"
    @focusout="handleFocusout"
  >
    <label v-if="renderLabel" :for="inputId" class="visually-hidden">
      Search for your condition
    </label>
    <icon-component
      name="magnifying-glass"
      class="condition-select__search-icon"
    />
    <input
      :id="inputId"
      ref="input"
      v-model="searchTerm"
      class="condition-select__search"
      data-test-id="condition-select-search-input"
      type="text"
      placeholder="Search your condition"
      @focus="optionsAreVisible = true"
      @keyup="inputKeyup"
    >
    <button
      v-if="clearSearchIsVisible"
      class="condition-select__clear-search-button"
      data-test-id="condition-select-clear-search-button"
      aria-label="Clear Search"
      @click="handleClear($event, null)"
    >
      <icon-component
        name="x"
        title="clear"
        class="condition-select__clear-search-button-icon"
      />
    </button>
    <div class="condition-select__options">
      <ul v-if="showList" class="condition-select__option-list">
        <li
          v-for="(disorder, index) in matchingDisorders"
          :key="`disorder_${index}`"
          class="condition-select__option"
          data-test-id="condition-select-option"
        >
          <input
            :id="disorderInputId(disorder)"
            :ref="disorderInputId(disorder)"
            v-model="selectedDisorder"
            class="condition-select__input visually-hidden"
            name="disorder"
            type="radio"
            :value="disorder.id"
            @keydown="handleOptionKeydown($event, disorder)"
            @focus="optionsAreVisible = true"
          >
          <label
            class="condition-select__label"
            :for="disorderInputId(disorder)"
            @mousedown="handleLabelMousdown"
            @click="handleOptionClick($event, disorder)"
          >
            {{ disorder.name }}
          </label>
        </li>
      </ul>
      <div
        v-if="showNoMatches"
        class="condition-select__no-matches"
      >No matches found.</div>
    </div>
  </div>
</template>

<script>
import { DISORDER_SEARCH } from '@/graphql/queries/disorder-queries';
import vClickOutside from 'v-click-outside';

export default {
  name: 'ConditionSelect',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    clickHandlerForOptions: {
      type: Function,
      default: () => {},
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    inputId: {
      type: String,
      default: 'condition-search',
    },
    renderLabel: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'SITE',
    },
  },
  data() {
    return {
      searchTerm: '',
      matchingDisorders: [],
      selectedDisorder: null,
      optionsAreVisible: false,
      clearSearchIsVisible: false,
    };
  },
  computed: {
    showNoMatches() {
      return this.searchTerm.length > 0 && this.matchingDisorders.length === 0 && !this.$apollo.loading && this.optionsAreVisible;
    },
    showList() {
      return this.searchTerm.length > 0 && this.matchingDisorders.length > 0 && this.optionsAreVisible;
    },
    skipQuery() {
      return this.searchTerm === '';
    },
  },
  methods: {
    handleClear(event, disorder) {
      this.searchTerm = '';
      this.selectedDisorder = disorder;
      this.clickHandlerForOptions(event, disorder);
      this.clearSearchIsVisible = false;
    },
    disorderInputId(disorder) {
      return `disorder-input-${disorder.id}`;
    },
    inputKeyup(event) {
      if (event.code === 'ArrowDown' && this.showList) {
        const disorder = this.matchingDisorders[0];
        this.$refs[this.disorderInputId(disorder)][0].focus();
        this.selectedDisorder = disorder.id;
        this.clearSearchIsVisible = true;
      }
    },
    onClickOutside() {
      this.optionsAreVisible = false;
    },
    handleSelection(event, disorder) {
      this.$emit('conditionSelected', event, disorder);
      this.searchTerm = disorder.name;
      this.optionsAreVisible = false;
    },
    handleOptionClick(event, disorder) {
      event.target.previousElementSibling.focus();
      this.handleSelection(event, disorder);
      this.clickHandlerForOptions(event, disorder);
      this.clearSearchIsVisible = true;
    },
    handleOptionKeydown(event, disorder) {
      const isSelectionAction = ['Space', 'Enter'].includes(event.code);
      if (!isSelectionAction) return;

      this.handleSelection(event, disorder);
      this.clickHandlerForOptions(event, disorder);
      this.clearSearchIsVisible = true;
    },
    handleLabelMousdown(event) {
      if (event.button === 0 && !event.ctrlKey) event.preventDefault();
    },
    handleFocusout(event) {
      if (!event || !event.relatedTarget) return;

      const focusIsStillWithinComponent = event.relatedTarget.closest('.condition-select');
      if (!focusIsStillWithinComponent) {
        this.optionsAreVisible = false;
      }
    },
    focusInput() {
      this.optionsAreVisible = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    disordersIncludingSynonyms(searchResults) {
      const disorders = [];
      searchResults.forEach((disorder) => {
        disorders.push(disorder);

        if (!disorder.synonyms) return;

        disorder.synonyms.forEach((synonym) => {
          const synonymDisorder = { ...disorder };
          synonymDisorder.synonyms = null;
          synonymDisorder.name = synonym;
          disorders.push(synonymDisorder);
        });
      });

      return disorders;
    },
  },
  apollo: {
    disorderSearch: {
      query: DISORDER_SEARCH,
      variables() {
        return {
          search: this.searchTerm,
          offset: 0,
          type: this.type,
        };
      },
      result({ data }) {
        this.matchingDisorders = this.disordersIncludingSynonyms(data.disorderSearch.disorders);
      },
      skip() {
        return this.skipQuery;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_condition-select';
</style>

<docs>
Renders a searchable dropdown menu for rare disease conditions.

</docs>
