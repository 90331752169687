<template>
  <div
    class="recommendation-post-teaser"
    :class="{ 'recommendation-post-teaser--no-image': !hasFeaturedImage }"
  >
    <app-link
      v-if="hasFeaturedImage"
      :to="link"
      class="recommendation-post-teaser__image-link"
    >
      <img
        :src="featuredImage"
        :alt="imageAlt"
        loading="lazy"
        class="recommendation-post-teaser__image"
      >
    </app-link>
    <h3
      v-if="!suppressType"
      class="recommendation-post-teaser__type"
    >
      {{ contentHeaderForType(post.type) }}
    </h3>
    <cross-publishing-indicator
      v-if="showCrossPublishing"
      :prefixes="allSitesForPost"
      :post-path="post.permalink"
      :original-site="post.originalSite"
    />
    <h2 class="recommendation-post-teaser__heading">
      <router-link
        :to="link"
        class="recommendation-post-teaser__heading-link link link--unstyled"
        v-html="post.title"
      />
    </h2>
    <engagement-bar
      v-if="showEngagement && isOriginalSite"
      :object-type="post.type"
      :the-object="post"
      :comment-count="post.commentCount"
      :is-condensed="false"
    />
  </div>
</template>

<script>
import EngagementBar from '@/components/Engagement/EngagementBar.vue';
import postTeaserMixin from '@/mixins/post-teaser-mixin';
import { validURL } from '@/utils/utils';
import CrossPublishingIndicator from '@/components/Posts/CrossPublishingIndicator.vue';

export default {
  name: 'RecommendationPostTeaser',
  components: {
    EngagementBar,
    CrossPublishingIndicator,
  },
  mixins: [postTeaserMixin],
  props: {
    post: {
      type: Object,
      required: true,
    },
    suppressType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEngagement: true,
    };
  },
  computed: {
    featuredImage() {
      const mediaData = this.post.featuredMedia[0];
      const mediumImagePath = mediaData.urlMedium;

      if (mediumImagePath && validURL(mediumImagePath)) {
        return mediumImagePath;
      }

      return mediaData.url;
    },
    hasFeaturedImage() {
      return this.post.featuredMedia && this.post.featuredMedia.length > 0;
    },
    imageAlt() {
      if (this.hasFeaturedImage && this.hasFeaturedImage.alt) {
        return this.hasFeaturedImage.alt;
      }
      return `${this.post.title} image`;
    },
    showCrossPublishing() {
      return this.post?.crossPublishedSites?.length > 0;
    },
    isOriginalSite() {
      return this.post?.originalSite === this.$site.prefix;
    },
    allSitesForPost() {
      if (!this.post) return [];

      return [
        this.post.originalSite,
        ...this.post.crossPublishedSites,
      ];
    },
  },
  created() {
    const hideEngagementBarPostTypes = ['cas', 'page', 'sponsored_article', 'quiz'];

    if (hideEngagementBarPostTypes.includes(this.post.type)) {
      this.showEngagement = false;
    }
  },
  methods: {
    contentHeaderForType(postType) {
      const contentHeaders = {
        post: 'Recommended Article',
        page: 'Recommended Article',
        story: 'Recommended Story',
        recipe: 'Recommended Recipe',
        sponsored_article: 'Sponsored',
        cas: 'Featured Collection',
      };
      return contentHeaders[postType];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_recommendation-post-teaser';
</style>
