// default accessibility settings come from sitedata in plugins/current-site.js
// where setSite is dispatched, which then dispatches setAccessibilitySettings.
const accessibilityState = () => ({
  settings: {
    fontSize: 'font-reg',
    contrast: 'contrast-reg',
  },
  menuOpen: false,
});

const getters = {
  accessibilityClasses: (state) => Object.values(state.settings),
};

const mutations = {
  setAccessibilitySettings(state, data) {
    state.settings = Object.assign(state.settings, data);
  },
  setA11yMenuOpen(state, data) {
    state.menuOpen = data;
  },
};

const actions = {
  setAccessibilitySettings({ commit, dispatch }, data) {
    commit('setAccessibilitySettings', data);
    dispatch('updateA11yContext', data);
  },
  resetAccessibilitySettings({ commit, dispatch, rootState }) {
    commit('setAccessibilitySettings', rootState.currentSite.site.settings.accessibility);
    dispatch('updateA11yContext', rootState.currentSite.site.settings.accessibility);
  },
  setA11yMenuOpen({ commit }, data) {
    commit('setA11yMenuOpen', data);
  },
  initializeAccessibilitySettings({ commit, dispatch }, data) {
    // eslint-disable-next-line camelcase
    const { menu_open, ...settings } = data;
    commit('setA11yMenuOpen', menu_open);
    dispatch('setAccessibilitySettings', settings);
  },
};

export default () => ({
  state: accessibilityState(),
  getters,
  mutations,
  actions,
});
