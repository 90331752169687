<template>
  <div
    class="layout"
    data-test-id="default-layout"
  >
    <div
      v-show="showLeaderboardAd"
      class="layout__leaderboard-container"
      data-test-id="layout-leaderboard-container"
    >
      <ad-slot mapping="leaderboard" />
    </div>
    <div
      id="layout-content"
      class="layout__content"
      data-test-id="layout-content"
    >
      <not-found v-if="is404" />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';
import NotFound from '@/components/Global/NotFound.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DefaultLayout',
  components: {
    AdSlot,
    NotFound,
  },
  props: {
    showLeaderboardAd: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['is404']),
  },
};
</script>
