import { mapGetters } from 'vuex';
import { validURL } from '@/utils/utils';

export default {
  computed: {
    ...mapGetters([
      'authDialogRedirect',
      'userIsUnverified',
      'userIsLoggedIn',
    ]),
    redirect() {
      return this.$route?.query?.redirect
        || this.$route?.query?.redirect_to // The redirect_to query param is typically set by wordpress
        || this.authDialogRedirect;
    },
    redirectParams() {
      // eslint-disable-next-line camelcase, no-unused-vars
      const { redirect, redirect_to, ...redirectParams } = this.$route.query;

      return redirectParams;
    },
    redirectIsSameOrigin() {
      if (!this.redirect) return false;
      if (this.redirect.startsWith('/')) return true;
      if (!validURL(this.redirect)) return false;
      return (new URL(this.redirect)).origin === (new URL(window.location)).origin;
    },
    redirectToInfluitive() {
      return /^\/?api\/dashboard-redirect/.test(this.redirect);
    },
    redirectToWP() {
      return /admin|wp-admin|dashboard/.test(this.redirect);
    },
    redirectToModeration() {
      return /moderation/.test(this.redirect);
    },
    redirectOutsideTheSpa() {
      return this.redirect.startsWith('http')
      || this.redirect.startsWith('www.')
      || this.redirectToInfluitive
      || this.redirectToModeration
      || this.redirectToWP;
    },
    fullRedirectUrl() {
      if (!this.redirect) return null;
      let redirectUrl = `${this.redirect}?`;

      try {
        redirectUrl = new URL(this.redirect);
        Object.keys(this.redirectParams).forEach((key) => redirectUrl.searchParams.append(key, this.redirectParams[key]));
        redirectUrl = redirectUrl.toString();
      } catch {
        Object.keys(this.redirectParams).forEach((key) => {
          redirectUrl = `${redirectUrl}&${key}=${this.redirectParams[key]}`;
        });
      }

      return redirectUrl;
    },
    redirectAsRoute() {
      if (!this.redirect) return null;
      const nextRoute = this.$router.match(this.redirect);
      return nextRoute.name ? nextRoute : null;
    },
    userMustVerifyFirst() {
      if (!this.redirectAsRoute) return false;

      return this.redirectAsRoute.meta.requiresVerify && this.userIsUnverified;
    },
    userMustLoginFirst() {
      const routeIsRestricted = this.redirectAsRoute?.meta?.requiresAuth
        || this.redirectAsRoute?.meta?.requiresVerify
        || this.redirectToInfluitive
        || this.redirectToModeration
        || this.redirectToWP;

      return routeIsRestricted && !this.userIsLoggedIn;
    },
    userQualifiesForRedirect() {
      return !this.userMustLoginFirst && !this.userMustVerifyFirst;
    },
  },
  methods: {
    cleanupAuthDialog() {
      if (this.$store.state.authOpen) {
        this.$store.dispatch('closeAuthDialog');
        this.$store.dispatch('clearAuthDialogRedirect');
      }
    },
    handleLoginRedirect() {
      // If this gets called when a user has not authenticated then there is no reason to redirect them.
      if (!this.userIsLoggedIn) return;

      // If no redirect is set, the function returns after making sure the user isn't
      // left on the login or register page.
      if (!this.redirect) {
        if (
          this.userIsLoggedIn
          && (this.$route.name === 'login' || this.$route.name === 'register')
        ) {
          this.$router.push({ name: 'userProfileOverview' });
          this.cleanupAuthDialog();
        }

        return;
      }

      // This should cover moderation, wordpress, and influtive while preventing
      // any redirects to other origins.
      if (this.redirectOutsideTheSpa) {
        if (!this.redirectIsSameOrigin) {
          this.$router.push('/');
          return;
        }

        window.location = this.fullRedirectUrl;
        this.cleanupAuthDialog();
        return;
      }

      // Some routes require verification. If a user is unverified
      // we send them to the profile page, preserve the redirect param,
      // and ask them to verify.
      if (this.userMustVerifyFirst) {
        this.$router.push({
          name: 'userProfileOverview',
          query: this.$route.query,
        });

        this.$store.dispatch(
          'openVerificationPrompt',
          { dialogHeading: 'You must verify your account to proceed' },
        );

        return;
      }

      // All other qualifiers and contingencies being met
      // we redirect the user.
      this.$router.push({
        path: this.redirect,
        query: this.redirectParams,
      });

      this.cleanupAuthDialog();
    },
  },
};
