const contentTypeToReactionType = (contentType) => {
  const types = {
    post: 'POST',
    cas: 'POST',
    story: 'POST',
    quiz: 'POST',
    recipe: 'POST',
    thread: 'THREAD',
    comment: 'COMMENT',
    reply: 'REPLY',
    status: 'USER_PROFILE_STATUS',
    statusReply: 'USER_PROFILE_STATUS_REPLY',
    message: 'MESSAGE',
  };
  return types[contentType];
};

export {
  contentTypeToReactionType,
};
