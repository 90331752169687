/* eslint-disable no-underscore-dangle */
import { datadogLogs } from '@datadog/browser-logs';
import WinstonLog from '@/services/winston-logger';

export const initDatadog = (siteDataLayer) => {
  const { env, property } = siteDataLayer;
  const service = property ? property.split('.')[0].toLowerCase() : '';

  datadogLogs.init({
    clientToken: 'pub8382572cd8a6d13dc4f66561031b9b36',
    forwardErrorsToLogs: true,
    service,
    env,
  });
};

export const loggerApi = (appOptions) => ({
  winstonLogger: WinstonLog(appOptions.context),
  defaultAttrs() {
    // SSR defaults are set in WinstonLog by the context.req
    if (!process.browser) { return {}; }
    return {
      metadata: {
        ssr: false,
        http: {
          host: window.location.host,
          url: window.location.href,
          useragent: navigator.userAgent,
        },
      },
    };
  },
  log(message, level = 'info', attrs = {}) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console[level](message, attrs);
    } else {
      const attrsWithDefaults = { ...this.defaultAttrs(), ...attrs };
      this.winstonLogger[level](message, attrsWithDefaults);
    }
  },
  error(message, attrs) {
    this.log(message, 'error', attrs);
  },
  info(message, attrs) {
    this.log(message, 'info', attrs);
  },
  warn(message, attrs) {
    this.log(message, 'warn', attrs);
  },
  debug(message, attrs) {
    this.log(message, 'debug', attrs);
  },
});

export default (appOptions, inject) => new Promise((resolve) => {
  inject(appOptions, '$logger', loggerApi(appOptions));

  resolve();
});
