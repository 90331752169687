/**
 * OneTrust plugin
 *
 * Injects one trust to the page
 * Triggers full page refresh when cookie preferences are updated
 * Handles re loading the banner on SPA navigation
 */
import { loggerApi } from '@/plugins/logger';

function reloadOTBanner() {
  if (!process.browser) { return; }
  const otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (typeof window.OneTrust === 'function') {
    window.OneTrust.Init();

    window.setTimeout(() => {
      window.OneTrust.LoadBanner();
      const toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings',
      );

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = (event) => {
          event.stopImmediatePropagation();
          window.OneTrust.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
}

function getDefaultCategoryIds() {
  // Get all categories and sort them in the order they appear in OnetrustActiveGroups
  const OneTrustGroups = window.OneTrust.GetDomainData().Groups.sort((a, b) => ((a.Order > b.Order) ? 1 : -1));

  // Find the category IDs which are active by default and are enabled on this specific website
  return OneTrustGroups.filter(
    (c) => ((c.Status === 'active') || (c.Status === 'always active')) && ((c.Cookies.length !== 0) || (c.Hosts.length !== 0)),
  ).map((c) => c.OptanonGroupId);
}

function getCurrentCategoryIdsString() {
  return (window.OnetrustActiveGroups || '').replace(/(^,|,$)/g, '');
}

// When a user updates their tracking preferences, we need to refresh the page
// so that changes to 3rd party cookies can be applied in GTM
function handleConsentChange() {
  if (!process.browser) { return; }
  if (typeof window.OneTrust === 'object') {
    window.OneTrust.OnConsentChanged(() => {
      const defaultCategoryIdsString = getDefaultCategoryIds().join(',');
      const currentCategoryIdsString = getCurrentCategoryIdsString();
      // eslint-disable-next-line no-console
      console.log(`default: ${defaultCategoryIdsString} current: ${currentCategoryIdsString}`);
      // If the current consent is not the default one, the page needs to be reloaded
      if ((defaultCategoryIdsString !== currentCategoryIdsString) && !window.disableOTReload) {
        // reload with a 50 ms delay to allow navigation to complete
        window.setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    });
  } else {
    window.setTimeout(handleConsentChange, 100);
  }
}

export default (app, inject) => new Promise((resolve) => {
  const logger = loggerApi({ context: app.context });
  let oneTrustId = app.$site.settings.one_trust_id;
  let isProd;
  if (process.browser) {
    // eslint-disable-next-line no-underscore-dangle
    isProd = /prod/.test(window.__INITIAL_STATE__.dataLayer.siteDataLayer.env);
  } else {
    isProd = /prod/.test(app.store.getters.siteDataLayer.env);
  }

  if (!isProd && oneTrustId) {
    oneTrustId = `${oneTrustId}-test`;
  }

  if (!oneTrustId && isProd) {
    logger.warn('OneTrust ID not configured');
  }

  if (app.router.afterEach) {
    app.router.afterEach((to, from) => {
      if (from.name) { // not first page load
        reloadOTBanner();
      } else {
        handleConsentChange();
      }
    });
  }

  const oneTrustApi = {
    tags() {
      const scriptTag = `<script>window.addEventListener('OneTrustGroupsUpdated',function(){window.oneTrustFired=true},{once:true})</script><script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="${oneTrustId}" async></script><script>function OptanonWrapper() { }</script>`;
      return oneTrustId ? scriptTag : '';
    },
    getCurrentCategoryIdsString,
  };
  inject(app, '$oneTrust', oneTrustApi);
  resolve();
});
