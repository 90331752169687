<template>
  <div class="facebook-one-tap">
    <dialog-base
      v-if="showFacebookOneTapLogin"
      class="facebook-one-tap__dialog"
      dialog-type="Facebook Log in"
      unobtrusive-mode
      @close="toggleFacebookOneTapDialog(false)"
    >
      <div class="facebook-one-tap__dialog-contents">
        <p class="facebook-one-tap__text">Sign in to {{ $site.name }}</p>
        <social-login
          class="facebook-one-tap__login"
          :account-providers="['facebook']"
        />
      </div>
    </dialog-base>
  </div>
</template>

<script>
import SocialLogin from '@/components/Social/SocialLogin.vue';
import DialogBase from '@/components/Global/DialogBase.vue';

export default {
  name: 'FacebookOneTap',
  components: {
    SocialLogin,
    DialogBase,
  },
  data() {
    return {
      showFacebookOneTapLogin: false,
    };
  },
  mounted() {
    this.toggleFacebookOneTapDialog();
  },
  methods: {
    toggleFacebookOneTapDialog(showDialog) {
      if (this.$store.getters.userIsLoggedIn) {
        this.showFacebookOneTapLogin = false;
        return;
      }
      this.showFacebookOneTapLogin = showDialog !== undefined ? showDialog : !this.showFacebookOneTapLogin;
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '@/stylesheets/components/_facebook-one-tap';
</style>

<docs>
</docs>
