<template>
  <div
    :class="`password-strength-indicator password-strength-indicator--${lowercaseStrengthStatus}`"
    aria-live="polite"
  >
    <span class="password-strength-indicator__pill" />
    <span class="password-strength-indicator__pill" />
    <span class="password-strength-indicator__pill" />
    <span class="password-strength-indicator__text">
      {{ strengthStatus }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrengthIndicator',
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  computed: {
    isWeak() {
      const weakPassword = new RegExp('(?=.*[a-z])(?=.*[0-9]).{8,}');
      return weakPassword.test(this.password);
    },
    isModerate() {
      const moderatePassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}');
      return moderatePassword.test(this.password);
    },
    isStrong() {
      const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-]).{9,}');
      return strongPassword.test(this.password);
    },
    strengthStatus() {
      if (this.isWeak && this.isModerate && this.isStrong) {
        return 'Strong';
      }
      if (this.isWeak && this.isModerate && !this.isStrong) {
        return 'Moderate';
      }
      if (this.isWeak && !this.isModerate) {
        return 'Weak';
      }
      return '';
    },
    lowercaseStrengthStatus() {
      return this.strengthStatus.toLowerCase().trim();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_password-strength-indicator';
</style>
