const dynamicRoutesState = () => ({
  taxonamyQueryResult: {},
});

const getters = {
  taxonamyQueryResult: (state) => state.taxonamyQueryResult,
};

const mutations = {
  setTaxonamyQueryResult(state, data) {
    state.taxonamyQueryResult = data;
  },
};

const actions = {
  setTaxonamyQueryResult({ commit }, data) {
    commit('setTaxonamyQueryResult', data);
  },
};

export default () => ({
  state: dynamicRoutesState(),
  getters,
  mutations,
  actions,
});
