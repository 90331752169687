<template>
  <div class="social-follow">
    <ul class="social-follow__list">
      <li
        v-for="(network, key) in socialNetworks"
        :key="key"
        class="social-follow__item"
      >
        <a
          v-if="network"
          :href="network"
          class="social-follow__link link link--unstyled"
          target="_blank"
          rel="noopener noreferrer"
        >
          <icon-component
            :name="key"
            :title="`Follow us on ${key}`"
            class="social-follow__icon"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialFollow',
  props: {
    useSiteSocials: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      huSocials: {
        facebook: 'https://www.facebook.com/HealthUnion',
        twitter: 'https://twitter.com/HealthUnion',
        linkedin: 'https://www.linkedin.com/company/health-union-llc',
      },
    };
  },
  computed: {
    socialNetworks() {
      if (this.useSiteSocials) {
        return this.$site.settings.social;
      }
      return this.huSocials;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_social-follow';
</style>

<docs>
Renders social network links, either using the set HU socials or the using the
site's socials set in moderation.

</docs>
