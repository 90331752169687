<template>
  <footer id="footer" class="footer">
    <div v-if="hasFooterTop" class="footer__top">
      <site-masthead />
      <subscription-form v-if="hasSubscribeForm" />
      <social-follow v-if="siteHasSocial" use-site-socials />
    </div>
    <div class="footer__bottom">
      <div class="footer__flex-container">
        <div class="footer__left">
          <a
            href="https://health-union.com/communities/"
            class="footer__hu-link link link--unstyled"
            rel="noopener"
            aria-label="health-union.com"
          >
            <div
              class="footer__hu-logo"
              aria-roledescription="Health Union logo"
              v-html="require(`../../assets/health-union.svg`)"
            />
          </a>
          <p class="footer__introduction-copy">
            {{ this.$site.name }} is a Health Union community. Health Union
            reaches millions of people through condition-specific online health
            communities and a Social Health Network of patient leaders across
            virtually all health conditions.
          </p>
          <social-follow />
        </div>
        <all-site-links />
      </div>
      <footer-links />
      <lazy-hydrate never>
        <p class="footer__disclaimer disclaimer-text">
          © {{ new Date().getFullYear() }} Health Union, LLC. All rights reserved.
          This information is not designed to replace a physician’s independent
          judgment about the appropriateness or risks of a procedure for a given
          patient. Always consult your doctor about your medical conditions.
          {{ this.$site.name }} does not provide medical advice, diagnosis or
          treatment. Use of the site is conditional upon your acceptance of our
          terms of use. This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">
            Privacy Policy</a> and
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener">
            Terms of Service</a> apply.
        </p>
      </lazy-hydrate>
    </div>
  </footer>
</template>

<script>
import AllSiteLinks from '@/components/Footer/AllSiteLinks.vue';
import FooterLinks from '@/components/Footer/FooterLinks.vue';
import SocialFollow from '@/components/Social/SocialFollow.vue';
import SubscriptionForm from '@/components/Footer/SubscriptionForm.vue';
import SiteMasthead from '@/components/Footer/SiteMasthead.vue';

export default {
  name: 'TheFooter',
  components: {
    AllSiteLinks,
    FooterLinks,
    SocialFollow,
    SubscriptionForm,
    SiteMasthead,
  },
  computed: {
    hasFooterTop() {
      return this.hasSubscribeForm || this.siteHasSocial;
    },
    siteHasSocial() {
      if (!this.$site.settings.social) {
        return false;
      }
      return Object.values(this.$site.settings.social).filter((social) => social !== '').length > 0;
    },
    hasSubscribeForm() {
      return this.$store.getters.siteHasFeature('Accounts');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_footer';
</style>

<docs>
</docs>
