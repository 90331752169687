<template>
  <button-component
    v-if="canMessage"
    class="start-conversation-button"
    data-test-id="start-conversation-button"
    @click="handleClick"
  >
    <slot>Private message</slot>
  </button-component>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'StartConversationButton',
  components: {
    ButtonComponent,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
    canMessage() {
      return this.user.id && this.user.id !== this.currentUser.id && this.user.username !== 'Editorial Team';
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
      if (this.checkAuth()) {
        this.startConversation();
      }
    },
    checkAuth() {
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account to send a private message.' });
        return false;
      }
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return false;
      }
      return true;
    },
    startConversation() {
      this.$store.dispatch('openConversationPopOver', {
        userId: this.user.id,
        username: this.user.username,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_start-conversation-button';
</style>

<docs>

A button that starts a conversation, or opens an existing conversation with
a given user. Requires current user to be logged in.

</docs>
