import gql from 'graphql-tag';

export const STATUS_REPLY_FRAGMENT = gql`
fragment StatusReplyFragment on UserProfileStatusReply {
  body,
  id,
  insertedAt,
  user {
    id
    username
    insertedAt
    roleId
    avatar {
      thumb
    }
  }
  inReplyTo {
    id
  }
}`;

export const USER_STATUS_REPLIES_FRAGMENT = gql`
${STATUS_REPLY_FRAGMENT}
fragment UserStatusRepliesFragment on UserProfileStatus {
  replies {
    ...StatusReplyFragment
  }
}`;
