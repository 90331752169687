import { contentTypeToReactionType } from '@/utils/reaction-helpers';
import { REMOVE_REACTION, ADD_REACTION } from '@/graphql/mutations/reaction-mutation';
import { REACT_TO_MESSAGE } from '@/graphql/mutations/private-messaging-mutations';

export default {
  data() {
    return {
      selectedReaction: '',
      previouslySelectedReaction: '',
      reactions: {
        like: {
          id: `${this.id}-${this.contentType}-like`,
          count: 0,
          isSelected: false,
        },
        support: {
          id: `${this.id}-${this.contentType}-support`,
          count: 0,
          isSelected: false,
        },
        sad: {
          id: `${this.id}-${this.contentType}-sad`,
          count: 0,
          isSelected: false,
        },
        wow: {
          id: `${this.id}-${this.contentType}-wow`,
          count: 0,
          isSelected: false,
        },
        same: {
          id: `${this.id}-${this.contentType}-same`,
          count: 0,
          isSelected: false,
        },
      },
    };
  },
  created() {
    // Reaction subscriptions are currently disabled in SSR due to a memory leak
    // it may be possible to re-enable this in the future but you should pay attention
    // to server memory usage before removing this guard.
    if (process.browser || process.env.NODE_ENV === 'test') {
      this.$reactions.subscribe(this.contentType, this.id, this.setReactions);
    }
  },
  computed: {
    totalReactions() {
      return Object.values(this.reactions).reduce(
        (acc, reaction) => acc + reaction.count, 0,
      );
    },
    userReactionType() {
      return Object.keys(this.reactions).filter(
        (reactionType) => this.reactions[reactionType].isSelected,
      )[0];
    },
    priorReactions() {
      const reactionTypeCount = Object.keys(this.reactions).map(
        (reaction) => [reaction, this.reactions[reaction].count],
      );

      // we need to track the prior reactions below in
      // the snowplow enableReactionTracking
      // it should be formatted to look like:
      // {like: 0,
      // support: 1,
      // sad: 2,
      // wow: 3,
      // same: 1}
      return Object.fromEntries(reactionTypeCount);
    },
  },
  methods: {
    // Callback method for $reactions.publish
    setReactions(reactionData) {
      reactionData.forEach((reactionAggregate) => {
        // dont do anything if we get an undefined or null type
        if (!this.reactions[reactionAggregate.type]) { return; }
        this.reactions[reactionAggregate.type].count = reactionAggregate.count;
        this.reactions[reactionAggregate.type].isSelected = reactionAggregate.isSelected;
      });
      this.setupCurrentUserPreviousReaction();
    },
    setupCurrentUserPreviousReaction() {
      if (this.userReactionType) {
        this.previouslySelectedReaction = this.userReactionType;
        this.selectedReaction = this.userReactionType;
      }
    },
    incrementReaction(reactionType) {
      this.reactions[reactionType].count += 1;
    },
    decrementReaction(reactionType) {
      if (this.reactions[reactionType].count > 0) {
        this.reactions[reactionType].count -= 1;
      }
    },
    selectReaction(reactionType) {
      if (this.contentType === 'message') {
        return this.reactToMessage(reactionType);
      }

      return this.$apollo.mutate({
        mutation: ADD_REACTION,
        variables: {
          assocId: this.id,
          reactedType: contentTypeToReactionType(this.contentType),
          type: reactionType,
        },
      }).then(() => {
        this.$snowplow.enableReactionTracking({
          data: {
            reaction_type: reactionType,
            prior_reactions: this.priorReactions,
            reaction_toggle: 'reaction made',
          },
        });
        this.selectedReaction = reactionType;
        this.incrementReaction(reactionType);

        if (
          this.previouslySelectedReaction
          && this.previouslySelectedReaction !== this.selectedReaction
        ) {
          this.decrementReaction(this.previouslySelectedReaction);
        }

        this.previouslySelectedReaction = reactionType;
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
    removeReaction(reactionType) {
      if (this.contentType === 'message') {
        return this.removeMessageReaction(reactionType);
      }
      return this.$apollo.mutate({
        mutation: REMOVE_REACTION,
        variables: {
          assocId: this.id,
          reactedType: contentTypeToReactionType(this.contentType),
        },
      }).then(() => {
        this.selectedReaction = '';
        this.decrementReaction(reactionType);
        this.previouslySelectedReaction = '';
        this.$snowplow.enableReactionTracking({
          data: {
            reaction_type: reactionType,
            prior_reactions: this.priorReactions,
            reaction_toggle: 'reaction rescinded',
          },
        });
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
    removeMessageReaction(reactionType) {
      return this.$apollo.mutate({
        mutation: REACT_TO_MESSAGE,
        variables: {
          messageID: this.id,
          reaction: null,
        },
      }).then(() => {
        this.selectedReaction = '';
        this.decrementReaction(reactionType);
        this.previouslySelectedReaction = '';
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
    // Private messaging has to use a separate reaction mutation as it will be
    // used in the back-end to trigger a websocket event, allowing us to show reactions
    // in real time.
    reactToMessage(reactionType) {
      return this.$apollo.mutate({
        mutation: REACT_TO_MESSAGE,
        variables: {
          messageID: this.id,
          reaction: reactionType.toUpperCase(),
        },
      }).then(() => {
        this.selectedReaction = reactionType;
        this.incrementReaction(reactionType);

        if (
          this.previouslySelectedReaction
          && this.previouslySelectedReaction !== this.selectedReaction
        ) {
          this.decrementReaction(this.previouslySelectedReaction);
        }
        this.previouslySelectedReaction = reactionType;
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
  },
};
