<template>
  <component
    :is="container"
    class="icon"
    @click="handleClick"
    v-html="iconMarkup"
  />
</template>

<script>
import { iconFromContext } from '@/utils/image-helpers';

const validContainers = ['div', 'span', 'i', 'button'];

export default {
  name: 'IconComponent',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    /**
     * Valid container elements are 'div', 'span', 'i', 'button'
     */
    container: {
      type: String,
      default: 'span',
      validator: (value) => validContainers.includes(value),
    },
  },
  computed: {
    iconMarkup() {
      const file = iconFromContext(`${this.name}.svg`);

      if (this.title && file) {
        return this.addTitleTag(file);
      }
      return file;
    },
  },
  methods: {
    addTitleTag(iconString) {
      return iconString.replace('>', `><title>${this.title}</title>`);
    },
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<docs>
Icons are rendered by name.

Icons receive BEM style class modifiers for size:

Icon container can be set using the 'container' prop, default is span.

Icons can be made more accessibile using the title prop to inject a `<title>` tag
which is used by screenreader technologies, similar to alt attributes on images.

If you want to dynamically change an icon, you need to pass it a :key
</docs>
