<template>
  <div>
    <div class="role-badge">
      <div v-if="isModeratorContributor" class="role-badge__half" />
      <icon-component
        v-if="primaryBadgeIcon"
        :name="primaryBadgeIcon"
        title="Primary Badge"
        class="role-badge__icon"
      />
      <span class="role-badge__text" data-test-id="user-role-badge-text">
        {{ primaryBadgeText }}
      </span>
    </div>
    <div v-if="showSecondaryBadge" class="role-badge">
      <icon-component
        :name="secondaryBadgeIcon"
        title="Secondary Badge"
        class="role-badge__icon"
      />
      <span class="role-badge__text" data-test-id="user-secondary-role-badge-text">
        {{ secondaryBadgeText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleBadge',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      primaryBadges: {
        admin: 'Community Admin',
        moderator: 'Moderator',
        moderator_contributor: 'Moderator & Contributor',
        contributor: 'Contributor',
        member: 'Member',
        null: 'Member',
      },
      secondaryBadges: {
        null: '',
        digital_creator: 'Digital Creator',
        healthcare_provider: 'Healthcare Provider',
        patient_leader: 'Patient Leader',
      },
      primaryBadgeIcons: {
        admin: 'badge-community-admin',
        contributor: 'badge-contributor',
        member: 'badge-member',
        moderator: 'badge-moderator',
        moderator_contributor: 'badge-moderator',
        null: 'badge-member',
      },
      secondaryBadgeIcons: {
        digital_creator: 'badge-digital-creator',
        healthcare_provider: 'badge-healthcare-provider',
        patient_leader: 'badge-patient-leader',
        null: '',
      },
    };
  },
  computed: {
    primaryBadgeIcon() {
      return this.primaryBadgeIcons[this.user.badge];
    },
    secondaryBadgeIcon() {
      return this.secondaryBadgeIcons[this.user.secondaryBadge] || '';
    },
    showSecondaryBadge() {
      return !!this.user.secondaryBadge;
    },
    primaryBadgeText() {
      return this.primaryBadges[this.user.badge];
    },
    secondaryBadgeText() {
      return this.secondaryBadges[this.user.secondaryBadge];
    },
    isModeratorContributor() {
      return this.user.badge === 'moderator_contributor';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_role-badge';
</style>

<docs>
Renders a Role Badge

</docs>
