const websocketState = () => ({
  connectionState: 'open',
});

const getters = {
  websocketState: (state) => state.connectionState,
};

const mutations = {
  setWebsocketConnectionState(state, data) {
    state.connectionState = data;
  },
};

const actions = {
  setWebsocketConnectionState({ commit }, data) {
    commit('setWebsocketConnectionState', data);
  },
};

export default () => ({
  state: websocketState(),
  getters,
  mutations,
  actions,
});
