export const config = {
  teaserTruncationLength: 40,
  /**
   * Only certain content types allow engagement.
   * Pages, for example, cannot be commented on or reacted to.
   * Individual posts may also disable comments or engagement.
   */
  engagementTypes: ['post', 'thread', 'comment', 'story', 'recipe'],

  sidebarAdSections: [
    {
      type: 'AdSlot',
      options: {
        mapping: 'square',
        displayInSidebar: true,
      },
    },
    {
      type: 'AdSlot',
      options: {
        mapping: 'halfpage',
        displayInSidebar: true,
      },
    },
    {
      type: 'AdSlot',
      options: {
        mapping: 'square',
        displayInSidebar: true,
      },
    },
  ],

  /**
   * This value matches the height of the header and should be used as the offset
   * when using the scroll-to mixin
   */
  scrollToOffset: -55,

  copyleaksScanWordMinimum: 6,
};
