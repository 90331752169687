import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { apiPath } from '@/utils/host-helpers';
import { createHttpLink } from 'apollo-link-http';
import createWebsocketLink from '@/graphql/absinthe-socket-link';
import { hasSubscription } from '@jumpn/utils-graphql';
import errorLink from '@/graphql/error-link';
import { split, from, ApolloLink } from 'apollo-link';
import { prepRequestHeaders } from '@/utils/request-headers';
import { Tracking } from '../utils/tracking';

Vue.use(VueApollo);
const addBrowserHeaders = new ApolloLink((operation, forward) => {
  if (typeof window !== 'undefined') {
    operation.setContext({
      headers: {
        'NEX-PATH': window.location.pathname,
        'NEX-SSR': 0,
        'AD-BLOCK': (document.querySelector('.ad-slot.ad-detect') || {}).offsetParent ? 0 : 1,
      },
    });
  }
  return forward(operation);
});

const buildApolloOptions = (context, store, setCookieLink) => {
  const httpLink = createHttpLink({
    uri: `${apiPath(context)}/api`,
    credentials: 'include',
    headers: prepRequestHeaders(context, store),
  });

  let link = from([
    addBrowserHeaders,
    errorLink(new Tracking(store), store, context),
    setCookieLink,
    httpLink,
  ]);

  // If the query contains a subscription, send it through the
  // websocket link. Otherwise, send it through the HTTP link.
  const { absintheSocket, phoenixSocket } = createWebsocketLink(context.req, store);
  if (absintheSocket) {
    link = split(
      (operation) => hasSubscription(operation.query),
      absintheSocket,
      link,
    );
  }

  const cache = new InMemoryCache();

  // If on the client, recover the injected state
  if (process.browser) {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-underscore-dangle
      const state = window.__APOLLO_STATE__;
      if (state) {
        // If you have multiple clients, use `state.<client_id>`
        cache.restore(state.defaultClient);
      }
    }
  }

  const defaultOptions = {
    link,
    cache,
    wsEndpoint: null,
    persisting: false,
    websocketsOnly: false,
    defaultHttpLink: false,
    ssr: true,
    ...(context.ssr ? {
      // Set this on the server to optimize queries when SSR
      ssrMode: true,
    } : {
      // This will temporary disable query force-fetching
      ssrForceFetchDelay: 100,
    }),
    apollo: {},
  };

  const apolloOptions = {
    ...defaultOptions,
  };

  return { apolloOptions, phoenixSocket };
};

export default function createProvider(context = {}, store) {
  const setCookieLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
    if (operation.getContext().response) {
      const { headers } = operation.getContext().response;
      const setCookie = headers.get('set-cookie');

      if (setCookie && context?.req?.res) {
        try {
          context.req.res.setHeader('set-cookie', setCookie);
        } catch {
          // probably a redirect and headers already sent
        }
      }
    }
    return response;
  }));

  const { apolloOptions, phoenixSocket } = buildApolloOptions(context, store, setCookieLink);
  const { apolloClient, wsClient } = createApolloClient(apolloOptions);

  apolloClient.wsClient = wsClient;

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        loadingKey: 'loading',
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  // Exposes $apolloProvider.phoenixSocket to the application
  // This allows us to connect and disconnect the phx socket as needed in the code
  // If we add WS functionality for unauthenticated users we will need to do this on login and logout
  // Example usage: this.$apolloProvider.phoenixSocket.connect();
  Object.defineProperty(apolloProvider, 'phoenixSocket', { value: phoenixSocket });

  return apolloProvider;
}
