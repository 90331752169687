import { config } from '@/configuration/nexus-config';

export default {
  methods: {
    scrollTo(hash, behavior) {
      if (!process.browser) { return; }
      const targetElement = document.querySelector(hash);

      if (!targetElement) return;

      const yOffset = config.scrollToOffset;
      const skipToElementPosition = targetElement.getBoundingClientRect().top;
      const destinationY = skipToElementPosition + window.pageYOffset + yOffset;
      const windowIsAlreadyInPosition = window.pageYOffset.toFixed() === destinationY.toFixed();

      if (windowIsAlreadyInPosition) return;

      window.scrollTo({
        top: destinationY,
        behavior: behavior || 'smooth',
      });
    },
  },
};
