import { generateId } from '@/utils/utils';

const toastState = () => ({
  notifications: [],
});

const getters = {
  anyToasts: (state) => state.notifications.length > 0,
  toastNotifications: (state) => state.notifications,
};

const mutations = {
  addToastNotification(state, data) {
    state.notifications.push(data);
  },
  removeToastNotification(state, data) {
    state.notifications.splice(state.notifications.map((n) => n.id).indexOf(data), 1);
  },
};

const actions = {
  addToastNotification({ commit }, data) {
    commit('addToastNotification', { ...data, id: generateId() });
  },
  removeToastNotification({ commit }, data) {
    commit('removeToastNotification', data);
  },
  addGenericErrorNotification({ commit }) {
    const notification = {
      id: generateId(),
      toastType: 'error',
      description: 'Something went wrong. Please try again.',
    };
    commit('addToastNotification', notification);
  },
};

export default () => ({
  state: toastState(),
  getters,
  mutations,
  actions,
});
