<template>
  <transition
    name="pop-over"
    appear
  >
    <div
      tabindex="0"
      class="pop-over"
    >
      <div class="pop-over__header">
        <p v-if="heading" class="pop-over__heading" :title="heading">{{ heading }}</p>
        <icon-component
          container="button"
          name="x"
          class="pop-over__close-button text-button"
          type="button"
          title="close"
          @click="close"
        />
      </div>
      <div class="pop-over__body">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'PopOverBase',
  props: {
    heading: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.$el.focus();
    document.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) {
        this.close();
      }
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_pop-over';
</style>

<docs>
Renders a pop over window that can render things like a SingleConversation

**Note:** The `close` event is emitted by the pop over base and must be handled
by the pop over itself.
</docs>
