const iconFromContext = (path) => require.context('@/assets/icons/', true)(`./${path}`);

const EngagementToolkitSvgFromContext = (path) => require.context('@/assets/engagement-toolkit-illustrations/', true)(`./${path}`);

function defaultAvatarSrc(size = 'thumb') {
  return `/common/nexus/default-user-${size}.png`;
}

function legacyOrNexusPath(imagePath) {
  // Avatars uploaded to WP do not need /content appending
  if (/wp-content\//.test(imagePath)) {
    return imagePath;
  }
  return `/content/${imagePath}`;
}

function avatarSrc(avatar, size = 'thumb') {
  if (!avatar || !avatar[size]) {
    return defaultAvatarSrc(size);
  }
  return legacyOrNexusPath(avatar[size]);
}

// Used for preview, where the image could be from the user's avatar or a direct upload
function avatarImageSrc(imagePath, size = 'thumb') {
  if (!imagePath) {
    return defaultAvatarSrc(size);
  }
  return legacyOrNexusPath(imagePath);
}

export {
  iconFromContext,
  EngagementToolkitSvgFromContext,
  defaultAvatarSrc,
  avatarSrc,
  avatarImageSrc,
};
