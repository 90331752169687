import gql from 'graphql-tag';

// NOTE: Changes to this fragment must be applied to the MESSAGES_SUBSCRIPTION
// in src/graphql/subscriptions/private-messaging-subscriptions.js
export const MESSAGE_FRAGMENT = gql`
fragment messageFragment on Message {
  id
  body
  userId
  insertedAt
  reactions {
    type
    user {
      id
    }
  }
}
`;

export const CONVERSATION_FRAGMENT = gql`
${MESSAGE_FRAGMENT}
fragment conversationFragment on Conversation {
  id
  insertedAt
  messages {
    ...messageFragment
  }
  isAccepted
  isArchived
  amBlocked
  user {
    id
    isDeleted
    roleId
    username
    avatar {
      thumb
    }
  }
}
`;
