import gql from 'graphql-tag';

export const SITE_CONFIGURATION_QUERY = gql`
  query site {
    site {
      id
      name
      prefix
      settings
      dataLayer
      defaultMeta
      parentSite {
        id
        name
        prefix
      }
      childSites {
        id
        name
        prefix
      }
      siblingSites {
        id
        name
        prefix
      }
      features
      recaptchaKey
    }
  }
`;

export const SITE_REDIRECTS_QUERY = gql`
  query site {
    site {
      id
      yoastRedirects {
        path
        redirect
      }
    }
  }
`;

export const GET_SITE_SUMMARIES = gql`
  query siteSummaries {
    siteSummaries {
      prefix
      name
      host
    }
  }
`;
