import jsonLd from '@/services/json-ld';

const jsonLdState = () => ({
  siteContexts: [],
  contentContexts: [],
});

const getters = {
  jsonLdPayload: (state) => JSON.stringify({
    '@context': 'https://schema.org',
    '@graph': state.siteContexts.concat(state.contentContexts),
  }),
};

const mutations = {
  setSiteContexts(state, data) {
    state.siteContexts = data;
    jsonLd.update(getters.jsonLdPayload(state));
  },
  setContentContexts(state, data) {
    state.contentContexts = data;
    jsonLd.update(getters.jsonLdPayload(state));
  },
};

const actions = {
  setSiteJsonLd({ commit }, data) {
    commit('setSiteContexts', data);
  },
  setContentJsonLd({ commit }, data) {
    commit('setContentContexts', data);
  },
  clearContentJsonLd({ commit }) {
    commit('setContentContexts', []);
  },
};

export default () => ({
  state: jsonLdState(),
  getters,
  mutations,
  actions,
});
