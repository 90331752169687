<template>
  <div class="textarea">
    <label
      class="textarea__label heading--4"
      :for="id"
    >
      <!-- @slot Label contents (optional) -->
      <slot />
    </label>
    <textarea
      :id="id"
      ref="input"
      v-bind="$attrs"
      class="textarea__input"
      :value="value"
      :placeholder="placeholder"
      @input="emitInputEvent"
      @keypress="handleKeypress"
      @focus="emitFocusEvent"
      @blur="emitBlurEvent"
    />
    <icon-component
      v-if="includeIcon"
      class="textarea__icon"
      name="pencil"
      title="Editable field"
    />
  </div>
</template>

<script>
export default {
  name: 'TextareaComponent',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    emitKeypress: {
      type: Boolean,
      default: false,
    },
    includeIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitFocusEvent() {
      this.$emit('focus');
    },
    emitBlurEvent() {
      this.$emit('blur');
    },
    emitInputEvent(event) { this.$emit('input', event.target.value); },
    handleKeypress(event) {
      if (!this.emitKeypress) { return; }
      this.$emit('keypress', event);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<docs>

For a larger text area, use the textarea--large class.

</docs>
