<template>
  <pop-over-base
    class="pop-over--conversation"
    :heading="headingText"
    @close="close"
  >
    <loading-spinner v-if="loading !== 0" />
    <single-conversation
      v-else-if="!!conversation.id"
      :conversation-id="conversation.id"
    />
  </pop-over-base>
</template>

<script>
import PopOverBase from '@/components/Global/PopOverBase.vue';
import SingleConversation from '@/components/PrivateMessaging/SingleConversation.vue';
import { CREATE_CONVERSATION } from '@/graphql/mutations/private-messaging-mutations';
import { mapGetters } from 'vuex';
import { isFeatureFlagEnabled } from '@/utils/utils';

export default {
  name: 'ConversationPopOver',
  components: {
    PopOverBase,
    SingleConversation,
  },
  data() {
    return {
      conversation: {},
      loading: 0,
    };
  },
  computed: {
    ...mapGetters(['popOverUsername', 'popOverUserId']),
    restrictPrivateMessaging() {
      return isFeatureFlagEnabled(this.$site, 'restrict_private_messaging');
    },
    headingText() {
      if (this.restrictPrivateMessaging) {
        return `Message with ${this.popOverUsername}`;
      }
      return `Private message with ${this.popOverUsername}`;
    },
  },
  watch: {
    // This watcher allows the pop over to switch between conversations if the
    // pop over is already open and the user clicks a button that would open it.
    popOverUserId() {
      this.conversation = {};
      this.findOrCreateConversation();
    },
  },
  created() {
    this.findOrCreateConversation();
  },
  methods: {
    close() {
      this.$store.dispatch('closeConversationPopOver');
    },
    findOrCreateConversation() {
      this.$apollo.mutate({
        mutation: CREATE_CONVERSATION,
        variables: {
          userId: this.popOverUserId,
        },
        // This mutation must be no-cache so that we can re-query once the conversation
        // is created. Otherwise we will get the initial response from the mutation
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        this.conversation = data.createConversation;
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
        this.close();
      });
    },
  },
};
</script>

<docs>
A component that renders a single conversation as a pop over.
This component is opened when a user clicks a StartConversationButton outside of
the MessageCenter.
</docs>
