<template>
  <div
    class="user-avatar"
    :class="`user-avatar--${size}`"
    @click="openUserProfileTooltip"
  >
    <img
      :src="src"
      :alt="`${username}'s avatar image`"
      :width="pxSizeMap[size]"
      :height="pxSizeMap[size]"
      loading="lazy"
      class="user-avatar__image"
      data-test-id="user-avatar-image"
      @error="handleImgError"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import openUserProfileTooltipMixin from '@/mixins/open-user-profile-tooltip-mixin';
import { avatarSrc, defaultAvatarSrc } from '@/utils/image-helpers';

const sizeOptions = ['full', 'thumb', 'header', 'profileTooltip', 'medium', 'large'];

export default {
  name: 'UserAvatar',
  mixins: [openUserProfileTooltipMixin],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isCrossPublished: {
      type: Boolean,
      default: false,
    },
    originalSite: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'thumb',
      validator: (value) => sizeOptions.includes(value),
    },
    displayTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // this needs to match _user-avatar.scss to for CLS
      pxSizeMap: {
        full: 150,
        thumb: 40,
        header: 28,
        profileTooltip: 100,
        medium: 125,
        large: 150,
      },
    };
  },
  computed: {
    ...mapGetters(['getSiteSummary']),
    username() {
      if (this.user) {
        return this.user.username;
      }
      return 'Anonymous';
    },
    isUserEditorialTeam() {
      return this.user && this.user.username === 'Editorial Team';
    },
    src() {
      if (this.user) {
        const { avatar, site } = this.user;
        const relativeAvatarPath = avatarSrc(avatar, this.size);
        const avatarSiteName = site?.name;

        if (this.isCrossPublished && this.$site.prefix !== this.originalSite) {
          const originalSiteHost = this.siteHost(this.originalSite);

          if (this.isUserEditorialTeam) {
            return `https://${this.siteHost(this.$site.prefix)}/images/logo_avatar.svg`;
          }

          return `https://${originalSiteHost}${relativeAvatarPath}`;
        }

        // Parent sites like SleepDisorders render avatars from their child sites
        if (avatarSiteName && avatarSiteName !== this.$site.name) {
          return `https://${avatarSiteName.toLowerCase()}${relativeAvatarPath}`;
        }

        return relativeAvatarPath;
      }

      return avatarSrc(null, this.size);
    },
    showTooltip() {
      return this.user ? this.displayTooltip : false;
    },
  },
  methods: {
    handleImgError(e) {
      e.target.src = defaultAvatarSrc(this.size);
    },
    siteHost(prefix) {
      return this.getSiteSummary(prefix) && this.getSiteSummary(prefix).host;
    },
    isEditorialTeam(user) {
      return user.username === 'Editorial Team';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_user-avatar';
</style>

<docs>
The User Avatar component is used anywhere on the site where a user's profile
picture is shown.

If no user param is provided, and no user is logged in, the default user avatar
will be shown. In reality, this should never happen.

If a user param is provided, the avatar for that user will be displayed. If no
user param is provided and a user is logged in, the current user's avatar will
be shown.

User avatars can be rendered at different sizes. The default size is medium.

</docs>
