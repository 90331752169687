/**
 * JsonLd service
 *
 * This service creates and manages the ld+json script element in the <head>
 *
 * Intended use:
 * - Metadata is created by yoast-seo WP plugin
 * - Router views will define their JSON schema using the response from apollo
 * - JSON schema is pushed into vuex
 *
 * The corresponding vuex module calls update() when mutations occur
 * The html method is used in entry-server to create the initial json ld script tag
 */

export default {
  TAG_ID: 'jsonLd',
  update(payload) {
    if (!process.browser) { return; }

    const tag = document.getElementById(this.TAG_ID);
    if (tag) {
      tag.innerHTML = payload;
    } else {
      const node = this.buildNode(payload);
      document.querySelector('head').appendChild(node);
    }
  },
  buildNode(jsonLd) {
    const node = document.createElement('script');
    node.setAttribute('id', this.TAG_ID);
    node.type = 'application/ld+json';
    node.text = jsonLd;
    return node;
  },
  html(jsonLd) {
    return `<script type="application/ld+json" id="${this.TAG_ID}">${jsonLd}</script>`;
  },
};
