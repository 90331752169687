<template>
  <div class="help-copy-section">
    <div
      :class="`help-copy-section__container help-copy-section__container--${kebabCaseDialogType}`"
    >
      <icon-component
        v-if="helpIcon"
        :name="helpIcon"
        class="help-copy-section__icon"
      />
      <p
        v-if="helpCopy"
        class="help-copy-section__help-text"
        data-test-id="help-copy-section-help-text"
      >
        {{ helpCopy }}
      </p>
      <p v-if="flowNavigation" class="help-copy-section__flow-navigation-text">
        <button
          class="help-copy-section__flow-navigation-link text-button text-button--link-style"
          data-test-id="help-copy-section-return-button"
          @click="handleClick(flowNavigation.returnAction)"
        >
          {{ flowNavigation.returnText }}
        </button>
        or
        <button
          class="help-copy-section__flow-navigation-link text-button text-button--link-style"
          data-test-id="help-copy-section-switch-flow-button"
          @click="handleClick(flowNavigation.switchFlowAction, flowNavigation.switchFlowAuthStep)"
        >
          {{ flowNavigation.switchFlowText }}
        </button>
      </p>
      <button
        v-for="(button, index) in buttonTextAndAction"
        :key="index"
        class="help-copy-section__link text-button text-button--link-style"
        data-test-id="help-copy-section-link"
        @click="handleClick(button.action)"
      >
        {{ button.text }}
      </button>
      <p
        v-if="helpCopySecondary || hasTwoActions"
        class="help-copy-section__secondary-text"
        data-test-id="help-copy-section-secondary-text"
      >
        <verification-help-actions
          v-if="hasTwoActions"
          :dialog-type="dialogType"
        />
        <template v-else>
          {{ helpCopySecondary }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import kebabCase from '@/utils/kebab-case';
import VerificationHelpActions from '@/components/Auth/VerificationHelpActions.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HelpCopySection',
  components: {
    VerificationHelpActions,
  },
  props: {
    dialogType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'siteHasFeature',
    ]),
    hasTwoActions() {
      return this.dialogType === 'enterVerificationNumber' || this.dialogType === 'verifyViaEmail';
    },
    isLogInOptions() {
      return this.dialogType === 'logIn';
    },
    kebabCaseDialogType() {
      return kebabCase(this.dialogType) || '';
    },
    buttonTextAndAction() {
      return {
        logIn: [
          { text: 'Create one now.', action: 'openRegisterDialog' },
        ],
        register: [
          { text: 'Log in', action: 'openLoginDialog' },
        ],
        loginWithEmail: [
          { text: 'Need help signing in?', action: 'openRequestPasswordReset' },
        ],
        requestVerificationText: [
          { text: 'Return to verification options', action: 'openVerificationPrompt' },
        ],
        requestPasswordReset: [
          { text: 'Return to log in options', action: 'openLoginDialog' },
        ],
      }[this.dialogType] || '';
    },
    helpCopyForDisorderDemographicInfo() {
      if (this.siteHasFeature('social_network')) {
        return 'Diagnosed with more than one condition?';
      }
      return 'Diagnosed with more than one rare condition?';
    },
    secondaryHelpCopyForDisorderDemographicInfo() {
      if (this.siteHasFeature('social_network')) {
        return 'Let us know in your profile.';
      }
      return 'Let us know in your profile. By providing us with this information, we are able to personalize a site experience that is specific to your condition.';
    },
    helpIcon() {
      return {
        verificationOptions: 'question',
        enterVerificationNumber: 'question',
        verifyViaEmail: 'question',
        requestDisorderDemographicInfo: 'question',
      }[this.dialogType] || '';
    },
    helpCopy() {
      return {
        logIn: 'Don\'t have an account?',
        register: 'Already a member?',
        verificationOptions: 'Why do I need to verify?',
        enterVerificationNumber: 'Didn’t receive a text message?',
        verifyViaEmail: 'Didn\'t receive the email?',
        requestDisorderDemographicInfo: this.helpCopyForDisorderDemographicInfo,
      }[this.dialogType] || '';
    },
    helpCopySecondary() {
      return {
        logIn: '(It\'s free and easy to do!)',
        verificationOptions: 'This helps us prevent spammers and keep our community secure.',
        requestDisorderDemographicInfo: this.secondaryHelpCopyForDisorderDemographicInfo,
      }[this.dialogType] || '';
    },
    flowNavigation() {
      return {
        loginWithEmail: {
          returnText: 'Return to log in options',
          returnAction: 'openLoginDialog',
          switchFlowText: 'create an account',
          switchFlowAction: 'openAuthDialog',
          switchFlowAuthStep: 'registerWithEmail',
        },
        registerWithEmail: {
          returnText: 'Return to sign up options',
          returnAction: 'openRegisterDialog',
          switchFlowText: 'log in',
          switchFlowAction: 'openLoginWithEmailDialog',
        },
      }[this.dialogType] || '';
    },
  },
  methods: {
    handleClick(buttonAction, buttonAuthStep = false) {
      if (buttonAuthStep) {
        this.$store.dispatch(buttonAction, { authStep: buttonAuthStep });
        return;
      }
      this.$store.dispatch(buttonAction);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_help-copy-section';
</style>
