import gql from 'graphql-tag';

export const UPDATE_FOLLOWING_USER = gql`
mutation updateFollow($isFollowing: Boolean, $userId: NexusId!) {
  updateFollow(isFollowing: $isFollowing, userId: $userId) {
    username
    id
    avatar {
      thumb
    }
    slug
  }
}
`;
