import gql from 'graphql-tag';
import { MESSAGE_FRAGMENT, CONVERSATION_FRAGMENT } from '@/graphql/fragments/private-messaging-fragments';

export const SEND_MESSAGE = gql`
${MESSAGE_FRAGMENT}
mutation sendMessage($body: Ugc!, $conversationId: NexusId!) {
  sendMessage(body: $body, conversationId: $conversationId) {
    ...messageFragment
  }
}
`;

export const REACT_TO_MESSAGE = gql`
${MESSAGE_FRAGMENT}
mutation reactToMessage($messageID: NexusId!, $reaction: MessageReaction) {
  reactToMessage(messageID: $messageID, reaction: $reaction) {
    ...messageFragment
  }
}
`;

export const CREATE_CONVERSATION = gql`
${CONVERSATION_FRAGMENT}
${MESSAGE_FRAGMENT}
mutation createConversation($body: Ugc, $userId: NexusId!) {
  createConversation(body: $body, userId: $userId) {
    ...conversationFragment
  }
}
`;

export const UPDATE_CONVERSATION = gql`
${MESSAGE_FRAGMENT}
mutation updateConversation($conversationId: NexusId!, $isAccepted: Boolean, $isArchived: Boolean) {
  updateConversation(conversationId: $conversationId, isAccepted: $isAccepted, isArchived: $isArchived) {
    id
    isArchived
    isAccepted
    messages {
      ...messageFragment
    }
  }
}
`;

export const BLOCK_USER = gql`
mutation blockUser($userId: NexusId!, $isBlocked: Boolean!) {
  blockUser(userId: $userId, isBlocked: $isBlocked) {
    id
    user {
      id
      username
      slug
    }
    isBlocked
  }
}
`;
