<template>
  <div class="inline-validation-error">
    <p class="inline-validation-error__text">
      {{ validationText }}
      <button
        class="inline-validation-error__button text-button text-button--link-style"
        @click="handleClick(validationButtonAction)"
      >
        {{ validationButtonText }}
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'InlineValidationError',
  props: {
    validationText: {
      type: String,
      default: '',
    },
    validationButtonText: {
      type: String,
      default: '',
    },
    validationButtonAction: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick(buttonAction) {
      this.$store.dispatch(buttonAction);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_inline-validation-error';
</style>
