<template>
  <component :is="linkType" v-if="vHtml" v-bind="linkProps" v-html="vHtml" />
  <component :is="linkType" v-else v-bind="linkProps">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AppLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    vHtml: {
      type: String,
      default: null,
    },
  },
  computed: {
    isFullUrl() {
      return this.to.match(/^http(s)?:\/\//);
    },
    linkType() {
      if (this.isFullUrl) {
        return 'a';
      }
      return 'RouterLink';
    },
    linkProps() {
      if (this.isFullUrl) {
        return {
          href: this.to,
          target: '_blank',
          rel: 'noopener',
        };
      }
      return { to: this.to, exact: this.exact };
    },
  },
};
</script>

<docs>
This component wraps router-link in order to allow for off-site linking.
</docs>
