<template>
  <p class="disclaimer-text">
    By providing your email address, you are agreeing to our
    <a
      class="auth-dialog__disclaimer-link"
      href="https://health-union.com/privacy-policy/"
      target="_blank"
      rel="noopener"
    >privacy policy</a>
    and
    <a
      class="auth-dialog__disclaimer-link"
      href="https://health-union.com/terms-of-use/"
      target="_blank"
      rel="noopener"
    >terms of use</a>.
  </p>
</template>

<script>
export default {
  name: 'EmailDisclaimer',
};
</script>

<docs>
Disclaimer for email address input.

</docs>
