<template>
  <div class="select-input">
    <label v-if="$slots.default" class="select-input__label" :for="$attrs['id']">
      <slot />
    </label>
    <div class="select-input__input-wrapper">
      <select v-bind="$attrs" @change="announceChange" @blur="emitBlurEvent">
        <!-- OPTIONS -->
        <option v-if="defaultBlank" disabled selected>Please select...</option>
        <option
          v-for="option in options"
          :key="`${$attrs['id']}-${option.value}`"
          :value="option.value"
          :disabled="option.disabled"
          :selected="option.selected"
        >
          {{ option.name }}
        </option>
      </select>
      <icon-component name="caret" class="select-input__arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectInput',
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
      validator: (options) => options.every((option) => 'value' in option && 'name' in option),
    },
    defaultBlank: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  methods: {
    announceChange(event) {
      this.$emit('change', event.target.value);
      this.$emit('input', event.target.value);
    },
    emitBlurEvent() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss"> // no scoped - so these styles can be overridden by component styles
  @import '@/stylesheets/components/_select-input';
</style>

<docs>
Renders a select type input with an array of options.

An option can be pre-selected using `selected: true` in the option object.

Can include disabled options using `disabled: true` in the option object.

Disabled and selected options can be combined to create a default placeholder.

</docs>
