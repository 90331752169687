const generateId = () => `${String.fromCharCode(65 + Math.floor(Math.random() * 26)).toLowerCase()}${Date.now()}`;

// Object isEmpty method
const isEmptyObject = (obj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) { return false; }
  }
  return true;
};

function notJustMention(value) {
  const hasImg = value.includes('<img');
  const hasText = value.replace(/<.*?>|&nbsp;|\s+/g, '').length > 0;
  return hasText || hasImg;
}

// Stole this from stack overflow
// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
// Shouldn't matter as long as it's "sufficiently unique"
function uuidv4() {
  // eslint-disable-next-line
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function msToDateAgoString(ms) {
  return new Date(Date.now() - ms).toString();
}

function yesterday() {
  const twentyFourHours = 24 * 60 * 60 * 1000;
  return msToDateAgoString(twentyFourHours);
}

function today() {
  const fourHours = 4 * 60 * 60 * 1000;
  return msToDateAgoString(fourHours);
}

function weekAgo() {
  const week = 24 * 7 * 60 * 60 * 1000;
  return msToDateAgoString(week);
}

function twoWeeksAgo() {
  const twoWeeks = 24 * 14 * 60 * 60 * 1000;
  return msToDateAgoString(twoWeeks);
}

function validURL(str) {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return !!url.host;
}

// A CloudFront Lambda is in place to handle webp conversion for
// images on our servers. Any image in Nexus can be requested in
//  .webp format.
function toWebp(url) {
  if (!url) { return ''; }
  const extensionToReplace = /(\.jpe?g|\.png|\.gif)/gi;

  return url.replace(extensionToReplace, '.webp');
}

function handleErrorOnImageSource(event) {
  const failedImage = event.target.currentSrc;
  const srcset = event.target.parentNode.querySelector(`[srcset="${failedImage}"]`);
  if (srcset) {
    srcset.remove();
    this.$logger.error(`Image Error: Removed source element with srcset ${failedImage}`);
  }
}

function isFeatureFlagEnabled(site, flag) {
  return !!(site.settings.feature_flags && site.settings.feature_flags[flag]);
}

function isFacebookApp(userAgent) {
  // Adapted from libraries that infer browser from user agent & samples from https://user-agents.net/applications/facebook-app
  const userAgentString = userAgent || navigator.userAgent || navigator.vendor || window.opera || '';
  // please keep in sync with api/lib/nexus_web/controllers/auth_controller.ex#user_agent_is_facebook_browser?/1
  return /FBAN|FBAV|FB_IAB/.test(userAgentString);
}

export {
  generateId,
  notJustMention,
  isFeatureFlagEnabled,
  isEmptyObject,
  msToDateAgoString,
  yesterday,
  today,
  weekAgo,
  twoWeeksAgo,
  validURL,
  uuidv4,
  toWebp,
  handleErrorOnImageSource,
  isFacebookApp,
};
