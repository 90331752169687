import Vue from 'vue';
import { xssPrevent } from '@/utils/regex';

const decodeHtml = (value) => {
  if (!value) return '';
  if (xssPrevent.test(value)) { return value; }
  if (!process.browser) { return value; }
  const textArea = document.createElement('textarea');
  textArea.innerHTML = value;
  return textArea.value;
};

export {
  decodeHtml,
};

Vue.filter('decodeHtml', decodeHtml);
