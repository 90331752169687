<template>
  <div class="verification-help-actions">
    <component :is="actionOne">Resend now</component>
    or
    <component :is="actionTwo" />
  </div>
</template>

<script>
import ResendVerificationText from '@/components/Auth/ResendVerificationText.vue';
import ResendVerificationEmail from '@/components/Auth/ResendVerificationEmail.vue';
import VerifyViaTextMessageButton from '@/components/Auth/VerifyViaTextMessageButton.vue';

export default {
  name: 'HelpCopySection',
  components: {
    ResendVerificationText,
    ResendVerificationEmail,
    VerifyViaTextMessageButton,
  },
  props: {
    dialogType: {
      type: String,
      required: true,
    },
  },
  computed: {
    actionOne() {
      return {
        enterVerificationNumber: 'resend-verification-text',
        verifyViaEmail: 'resend-verification-email',
      }[this.dialogType] || '';
    },
    actionTwo() {
      return {
        enterVerificationNumber: 'resend-verification-email',
        verifyViaEmail: 'verify-via-text-message-button',
      }[this.dialogType] || '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_verification-help-actions';
</style>
