/*
Mixin for triggering experiment bucketing. This should probably be done in the experiment store
as an action, but for some reason I CANNOT get it to work properly with SSR there, so here it is.
*/
import { EXPERIMENT_VARIANT } from '@/graphql/queries/experiment-queries';

export default {
  methods: {
    bucket(symbol) {
      let variantData = this.$store.getters.getVariantKey(symbol);
      if (typeof variantData !== 'undefined') return Promise.resolve(variantData);
      return this.$apollo.query({
        query: EXPERIMENT_VARIANT,
        variables: {
          symbol,
        },
      }).then(({ data }) => {
        const key = data.getExperimentVariant?.key;
        variantData = {
          symbol, key, experimentId: data.getExperimentVariant?.experimentId, versionId: data.getExperimentVariant?.versionId,
        };
        this.$store.commit('setVariant', variantData);

        if (key) {
          this.$store.commit('addExperimentBucket', variantData);
          if (this.$snowplow) this.$snowplow.trackExperimentBucket(symbol, key);
        }

        return key;
      });
    },
  },
};
