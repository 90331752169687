<template>
  <div class="enter-verification-number">
    <form-component
      novalidate="true"
      class="enter-verification-number__form"
      @submit.prevent="tryToSubmit(verificationCode)"
    >
      <composite-input
        ref="codeInput"
        :disabled="tryingToSubmit"
        @fullcodeentered="tryToSubmit($event)"
      />
      <inline-validation-error
        v-if="errorText"
        :validation-text="errorText"
        class="enter-verification-number__error"
      />
      <loading-spinner v-if="tryingToSubmit" />
    </form-component>
    <progress-indicator v-if="!siteHasFeature('social_network')" current-step="3" />
  </div>
</template>

<script>
import { VERIFY_PHONE } from '@/graphql/mutations/user-mutations';
import CompositeInput from '@/components/Inputs/CompositeInput.vue';
import InlineValidationError from '@/components/Auth/InlineValidationError.vue';
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EnterVerificationNumber',
  components: {
    CompositeInput,
    InlineValidationError,
    LoadingSpinner,
    ProgressIndicator,
  },
  data() {
    return {
      tryingToSubmit: false,
      errorText: '',
    };
  },
  computed: {
    ...mapGetters(['siteHasFeature']),
    verificationCode() {
      return `${this.$refs.codeInput?.fullCode}`;
    },
  },
  methods: {
    tryToSubmit(verificationCode) {
      this.tryingToSubmit = true;
      this.errorText = '';

      this.$apollo.mutate({
        mutation: VERIFY_PHONE,
        variables: {
          code: verificationCode,
        },
      }).then((response) => {
        const success = response.data.verifyPhone;
        const isRareDisease = this.siteHasFeature('rare_disease');
        if (success) {
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Account verified.',
          });
          this.$store.dispatch('verifyUser');

          this.$store.dispatch('setAuthStep', { authStep: isRareDisease ? 'accountConfirmationWithNoCTA' : 'accountConfirmation' });
        } else {
          this.errorText = 'Incorrect code.';
          this.tryingToSubmit = false;
          this.$refs.codeInput.clearDigits();
          this.$nextTick(this.$refs.codeInput.focusFirstInput);
        }
      }).catch((error) => {
        this.$logger.error(`[Verify Phone] ${error}`);
        this.errorText = `${error}`.replace('Error: GraphQL error: ', '');
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_enter-verification-number';
</style>
