<template>
  <button
    id="login-button"
    type="button"
    aria-label="Site Log in"
    class="header__button header__button--log-in"
    aria-haspopup="true"
    @click="openLoginDialog"
  >
    <icon-component
      name="user"
      class="header__button-icon"
    />
    <span class="header__button-text">Log in</span>
  </button>
</template>

<script>
export default {
  name: 'LoginButton',
  methods: {
    openLoginDialog() {
      this.$store.dispatch('openLoginDialog');
    },
  },
};

</script>
