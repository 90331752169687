<template>
  <form-component
    class="reset-password"
    @submit.prevent="tryToResetPassword"
  >
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.password.$invalid }"
    >
      <validation-error
        ref="password"
        :model="password"
        :validator="$v.password"
      />
      <password-input
        id="password"
        v-model="password"
        name="password"
        class="reset-password__input password-input--full-width"
        data-test-id="reset-password-input"
        show-disclaimer
      > New password</password-input>
    </div>
    <password-strength-indicator v-if="showPasswordStrengthIndicator" :password="password" />
    <loading-button
      class="reset-password__button button--center button--large"
      data-test-id="reset-password-submit-button"
      type="submit"
      :loading="tryingToSetPassword"
      button-type="setting password"
    >
      Set password
    </loading-button>
  </form-component>
</template>

<script>
import PasswordInput from '@/components/Auth/PasswordInput.vue';
import PasswordStrengthIndicator from '@/components/Auth/PasswordStrengthIndicator.vue';
import { required, helpers } from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';

const passwordRulesHelper = helpers.regex('passwordRulesHelper', /^(?=.*\d).{8,}$/i);
const notJustNums = helpers.regex('notJustNums', /\D/);

export default {
  name: 'ResetPassword',
  components: {
    PasswordInput,
    PasswordStrengthIndicator,
    ValidationError,
    LoadingButton,
  },
  data() {
    return {
      password: '',
      resetKey: '',
      emailOrUsername: '',
      tryingToSetPassword: false,
    };
  },
  validations: {
    password: {
      required,
      passwordRulesHelper,
      notJustNums,
    },
  },
  computed: {
    showPasswordStrengthIndicator() {
      return this.password.length && !this.$v.password.$invalid;
    },
  },
  mounted() {
    if (!this.$route || !this.$route.query) { return; }
    this.resetKey = this.$route.query.reset_key || this.$route.query.key;
    this.emailOrUsername = this.$route.query.email_or_username || this.$route.query.login;
  },
  methods: {
    resetSuccess() {
      this.$store.dispatch('addToastNotification', {
        toastType: 'success',
        description: 'Success! Your password has been reset',
        type: 'Registration',
      });
      this.tryingToSetPassword = false;

      this.$store.dispatch('closeAuthDialog');
    },
    resetFailed() {
      this.password = '';
      this.$store.dispatch('addToastNotification', {
        toastType: 'error',
        description: 'Something went wrong, password was not saved.',
        type: 'Registration',
      });
      this.tryingToSetPassword = false;
    },
    tryToResetPassword() {
      this.tryingToSetPassword = true;

      this.$v.$touch();
      this.$refs.password.checkErrors();

      if (this.$v.$invalid) {
        this.tryingToSetPassword = false;
        return false;
      }

      return this.$auth.resetPassword({
        email_or_username: this.emailOrUsername,
        reset_key: this.resetKey,
        password: this.password,
      })
        .then((response) => {
          if (response.status === 'success') {
            this.resetSuccess();
          } else {
            this.resetFailed();
          }
        })
        .catch((error) => {
          this.$logger.error(error);
          this.tryingToSetPassword = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_reset-password';
</style>
