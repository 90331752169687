import gql from 'graphql-tag';

export const DISORDER_SEARCH = gql`
query disorderSearch($offset: Int, $search: String, $type: DisorderSearchType) {
  disorderSearch(offset: $offset, search: $search, type: $type) {
    total
    disorders {
      id
      name
      slug
      synonyms
    }
  }
}
`;

export const FEATURED_DISORDERS = gql`
query featuredDisorders {
  featuredDisorders {
    id
    name
    slug
  }
}
`;

export const DISORDER_DETAIL = gql`
query disorderDetail($slug: String!) {
  disorderDetail(slug: $slug) {
    id
    name
    slug
    summary
    cream
    datalayer
    synonyms
    onsetAge
    orphaCode
    tableOfContents {
      id
      text
    }
    prevalences {
      class
      geographic
    }
    inheritanceType
    seo {
      allowIndexing
      title
      metaDescription
      canonical
      ogTitle
      ogDescription
      ogImage
      twitterTitle
      twitterDescription
      twitterImage
      keywords
    }
  }
}
`;
