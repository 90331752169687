<template>
  <div class="not-found">
    <div class="not-found__image-box">
      <img
        src="/common/nexus/magnifying-glass.png"
        alt=""
        class="not-found__image"
      >
    </div>
    <h1 class="not-found__heading heading--2">
      Oops! We can’t find that page.
    </h1>
    <p class="not-found__message">
      Try searching or check out what’s popular in the community below.
    </p>
    <site-search />
    <div class="not-found__links">
      <router-link
        v-if="siteHasFeature('Forums')"
        :to="{ name: 'forums' }"
        class="not-found__cta"
      >
        Discuss in the forums
        <icon-component
          name="caret"
          title="caret icon"
          class="not-found__cta-icon icon--smallest"
        />
      </router-link>
      <router-link
        v-if="siteHasFeature('community') && !siteHasFeature('social_network')"
        :to="{ name: 'community' }"
        class="not-found__cta"
      >
        Visit our community hub
        <icon-component
          name="caret"
          title="caret icon"
          class="not-found__cta-icon icon--smallest"
        />
      </router-link>
      <a
        v-if="siteHasFeature('social_network')"
        :href="dashboardURL"
        class="not-found__cta"
      >
        Patient leader certification
        <icon-component
          name="caret"
          title="caret icon"
          class="not-found__cta-icon icon--smallest"
        />
      </a>
    </div>
    <recommendation-component class="not-found__recommendations" use-recents />
  </div>
</template>

<script>
import SiteSearch from '@/components/Search/SiteSearch.vue';
import RecommendationComponent from '@/components/Content/Sections/RecommendationComponent.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NotFound',
  components: {
    SiteSearch,
    RecommendationComponent,
  },
  data() {
    return {
      dashboardURL: '/api/dashboard-redirect',
    };
  },
  computed: {
    ...mapGetters(['siteHasFeature']),
  },
  metaInfo: {
    title: 'Page not found',
    meta: [
      {
        name: 'robots',
        vmid: 'robots',
        content: 'noindex, follow',
      },
    ],
    link: [
      { vmid: 'canonical', content: null },
    ],
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_not-found';
</style>
