// This module manages the Snowplow contexts which will be sent with snowplow events
// Use the getters in this module when sending schemas in a snowplow event
// Specification: https://docs.google.com/spreadsheets/d/1Hrsi1xqUELhxaYYIRNf3C4Z7UN7ynHUhmYPJKLCUluU/edit#gid=1704498901

import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

const contextsState = () => ({
  schemas: {
    compliance: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_compliance_context/jsonschema/1-0-0',
    global: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_global_context/jsonschema/3-0-0',
    user: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_user_context/jsonschema/3-0-0',
    a11y: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_a11y_menu_context/jsonschema/1-0-0',
    source: 'https://raw.githubusercontent.com/Health-Union/iglu-schemas/master/schemas/com.health-union/nexus_source_context/jsonschema/1-0-0',
  },
  complianceContext: {
    cookie_consent: '',
  },
  globalContext: {
    active_ab_tests: [],
    site_id: '',
    version_id: '',
    git_commit_id: '',
    page_category: '',
    nexus_page_id: '',
    elastic_search_type: '',
    elastic_search_query: '',
    top_content_ids: [],
    top_content_location: '',
    featured_content_ids: [],
    featured_content_location: '',
    recommendation_engine: 'Default', // static for now
    recommended_content_ids: [],
    recommendation_location: '',
    synthetic_pageview_id: '',
  },
  userContext: {
    snowplow_user_id: '',
    google_analytics_id: '',
    wordpress_user_id: '',
    facebook_pixel_user_id: '',
    qualtrics_user_id: '',
    liveramp_user_id: '',
    krux_user_id: '',
    krux_segments: '',
    pendo_user_id: '',
    pendo_segments: '',
    login_status: '',
    user_role: '',
    fingerprint: '',
  },
  a11yMenuContext: {
    font_selection: 'reg',
    contrast_selection: 'reg',
  },
  sourceContext: {
    source_trigger_page: '',
    source_trigger_type: '',
    source_trigger_text: '',
    source_trigger_class_list: [],
    source_trigger_id: '',
  },
});

const getters = {
  complianceContext: (state) => ({ schema: state.schemas.compliance, data: state.complianceContext }),
  globalContext: (state) => ({ schema: state.schemas.global, data: state.globalContext }),
  userContext: (state) => ({ schema: state.schemas.user, data: state.userContext }),
  a11yContext: (state) => ({ schema: state.schemas.a11y, data: state.a11yMenuContext }),
  sourceContext: (state) => ({ schema: state.schemas.source, data: state.sourceContext }),
};

const mutations = {
  addExperimentBucket(state, variantData) {
    if (!state.globalContext.active_ab_tests.some((t) => t.symbol === variantData.symbol)) {
      state.globalContext.active_ab_tests.push(variantData);
    }
  },
  updateComplianceContext(state, data) {
    state.complianceContext = { ...state.complianceContext, ...data };
  },
  updateGlobalContext(state, data) {
    state.globalContext = { ...state.globalContext, ...data };
  },
  updateA11yContext(state, data) {
    state.a11yMenuContext = { ...state.a11yMenuContext, ...data };
  },
  updateUserContext(state, data) {
    state.userContext = { ...state.userContext, ...data };
  },
  updateSourceContext(state, data) {
    state.sourceContext = { ...state.sourceContext, ...data };
  },
};

const actions = {
  updateComplianceContext({ commit }, data) {
    const payload = {
      cookie_consent: data,
    };

    commit('updateComplianceContext', payload);
  },
  /**
   * Update the global context
   * This should happen on app init, route change, or route-change-like events
   * (pagination, sort, filter).
   * Pulls datalayer data from the rootState
   * @param {Object} data
   * An object that may contain the following keys
   * site: current $site
   * search: details about searchpages (including content indexes)
   * topContent: curated top content, like in TrendingTopics (community page)
   * featuredContent: featured threads displayed on any given page
   * recommendedContent: any content displayed via recommendations
   */
  updateGlobalContext({ commit }, data) {
    if (!data) { return; }

    const payload = {
      site_id: data.site ? data.site.id : undefined,
      // currently not defined, we need to start doing semver
      version_id: data.site ? data.site.version : undefined,
      // currently not defined, maybe an env var?
      git_commit_id: data.site ? data.site.sha : undefined,
      page_category: data.page ? data.page.type : undefined,
      nexus_page_id: data.page ? data.page.id : undefined,
      elastic_search_type: data.search ? data.search.type : undefined,
      elastic_search_query: data.search ? data.search.query : undefined,
      top_content_ids: data.topContent ? data.topContent.ids : undefined,
      top_content_location: data.topContent ? data.topContent.location : undefined,
      featured_content_ids: data.featuredContent ? data.featuredContent.ids : undefined,
      featured_content_location: data.featuredContent ? data.featuredContent.location : undefined,
      recommended_content_ids: data.recommendedContent ? data.recommendedContent.ids : undefined,
      recommendation_location: data.recommendedContent
        ? data.recommendedContent.location
        : undefined,
      synthetic_pageview_id: this.getters.pageViewId,
    };

    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'undefined') {
        delete payload[key];
      }
    });

    commit('updateGlobalContext', payload);
  },

  resetGlobalContext({ commit }) {
    const payload = {
      page_category: '',
      nexus_page_id: '',
      elastic_search_type: '',
      elastic_search_query: '',
      top_content_ids: [],
      top_content_location: '',
      featured_content_ids: [],
      featured_content_location: '',
      recommended_content_ids: [],
      recommendation_location: '',
      synthetic_pageview_id: '',
    };

    commit('updateGlobalContext', payload);
  },
  /**
   * Update the a11y context, this is connected to the accessibility module actions
   * reading rootState from that module
   */
  updateA11yContext({ commit, rootState }) {
    const accessibility = rootState.accessibility.settings;

    const payload = {
      font_selection: accessibility.fontSize.split('-')[1],
      contrast_selection: accessibility.contrast.split('-')[1],
    };

    commit('updateA11yContext', payload);
  },
  initUserContext({ dispatch }) {
    // _sp_id.bc71=85a97d9c-196e-40f8-a1f8-26a939777340.1…b6-77e6-4d21-af3a-6117c014f20b.1719846714960.280;
    const snowplowIdmatch = document.cookie.match(/_sp_id\.[a-f0-9]+=([-a-f0-9]+)/);
    const snowplowUserId = snowplowIdmatch ? snowplowIdmatch[1] : null;

    // _ga=GA1.1.1624558246.1715738438
    const gaIdmatch = document.cookie.match(/(?:GA[0-9]+.[0-9]+.)(.+?);/);
    const gaId = gaIdmatch ? gaIdmatch[1] : null;

    dispatch('updateUserContext', {
      snowplowUId: snowplowUserId,
      gaUId: gaId,
    });

    getFingerprint().then((fp) => {
      dispatch('updateUserContext', {
        fingerprint: fp,
      });
    });
  },
  /**
   * Update the user context, this is connected to the current-user module actions
   * reading rootState from that module
   */
  updateUserContext({ commit, rootState }, data) {
    const currentUser = rootState.currentUser.user;
    const { loggedIn } = rootState.currentUser;
    const payload = {
      snowplow_user_id: data ? data.snowplowUId : undefined,
      google_analytics_id: data ? data.gaUId : undefined,
      wordpress_user_id: loggedIn ? currentUser.id : '',
      facebook_pixel_user_id: undefined,
      qualtrics_user_id: undefined,
      liveramp_user_id: undefined,
      krux_user_id: process.browser && window.Krux ? window.Krux.user : undefined,
      krux_segments: process.browser && window.Krux ? window.Krux.segments : undefined,
      pendo_user_id: undefined,
      pendo_segments: undefined,
      login_status: loggedIn.toString(),
      user_role: loggedIn ? currentUser.roleId : '',
      fingerprint: data ? data.fingerprint : undefined,
    };

    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === 'undefined') {
        delete payload[key];
      }
    });

    commit('updateUserContext', payload);
  },
  /**
   * Update the source context
   * This context is primarily to be used in events where a pop-up occurs. For example,
   * when a user selects an option to "login" and they receive a prompt on top of another page,
   * we will use this context to get information about the source page that triggered the popup.
   */
  updateSourceContext({ commit, state }, data) {
    const payload = {
      source_trigger_page: state.globalContext.nexus_page_id,
      source_trigger_type: state.globalContext.page_category,
      source_trigger_text: data.trigger.text,
      source_trigger_class_list: data.trigger.classes,
      source_trigger_id: data.trigger.id,
    };

    commit('updateSourceContext', payload);
  },
  resetSourceContext({ commit }) {
    commit('updateSourceContext', {
      page_id: '',
      source_trigger_page: '',
      source_trigger_type: '',
      source_trigger_text: '',
      source_trigger_class_list: [],
      source_trigger_id: '',
    });
  },
};

export default () => ({
  state: contextsState(),
  getters,
  mutations,
  actions,
});
