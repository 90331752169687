<template>
  <button
    type="button"
    aria-label="Site Nav Menu"
    :aria-expanded="navOpen ? 'true' : 'false'"
    aria-controls="navigation-drawer"
    :class="`header__button header__button--menu${navOpen ? ' header__button--menu-open' : ''}`"
    @click="handleClick"
  >
    <div class="header__hamburger">
      <div class="header__hamburger-filling" />
    </div>
    <span class="header__button-text">Menu</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MenuButton',
  computed: {
    ...mapGetters(['navOpen']),
  },
  methods: {
    handleClick(event) {
      this.$emit('topNavigation', { label: 'toggle', opening: !this.navOpen });
      this.$store.dispatch('setNavOpen', !this.navOpen);
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'site menu',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
    },
  },
};
</script>
