// import { ApolloLink } from 'apollo-link';
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';
import { websocketPath } from '@/utils/host-helpers';

export default function createWebsocketLink(req, store) {
  if (!process.browser) {
    return false;
  }

  const phoenixSocket = new PhoenixSocket(websocketPath(req), {
    // params must be a function, otherwise we will have a stale token!
    params: () => ({
      token: store.getters.userToken,
    }),
  });

  const absintheSocket = createAbsintheSocketLink(AbsintheSocket.create(
    phoenixSocket,
  ));

  phoenixSocket.onOpen(() => {
    store.dispatch('setWebsocketConnectionState', 'open');
  });

  phoenixSocket.onError(() => {
    store.dispatch('setWebsocketConnectionState', 'error');
  });

  return { absintheSocket, phoenixSocket };
}
