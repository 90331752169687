/* eslint-disable func-names */
import CreatePersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

// eslint-disable-next-line import/no-mutable-exports
const storage = CreatePersistedState({
  paths: [
    'accessibility',
    'currentUser.user.token',
    'currentUser.hasLeadGenPopUpDisabledCookie',
    'currentUser.userHasSubscribed',
  ],
  storage: {
    getItem(key) {
      Cookies.get(key);
    },
    setItem(key, value) {
      Cookies.set(key, value, { expires: 30, secure: true });
    },
    removeItem(key) {
      Cookies.remove(key);
    },
  },
});

export default storage;
