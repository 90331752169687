const MemberProfile = () => import('@/pages/Member/MemberProfile.vue');
const MemberProfileActivity = () => import('@/pages/Member/MemberProfileActivity.vue');
const MemberProfileStatus = () => import('@/pages/Member/MemberProfileStatus.vue');
const MemberProfileStatusById = () => import('@/pages/Member/MemberProfileStatusById.vue');
const MemberAwards = () => import('@/pages/Member/MemberAwards.vue');

export default [
  {
    path: '/members/:slug',
    name: 'memberProfile',
    component: MemberProfile,
  },
  {
    path: '/members/:slug/activity',
    name: 'memberProfileActivity',
    component: MemberProfileActivity,
  },
  {
    path: '/members/:slug/status',
    name: 'memberProfileStatus',
    component: MemberProfileStatus,
  },
  {
    path: '/members/:slug/status/:id',
    name: 'MemberProfileStatusById',
    component: MemberProfileStatusById,
  },
  {
    path: '/members/:slug/awards',
    name: 'MemberAwards',
    component: MemberAwards,
  },
];
