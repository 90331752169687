/**
 * Google GAM plugin
 *
 * This plugin sets up Google Ad Manager (GAM)
 * including business logic specific to Health Union
 *
 * Based somewhat on the implementation of vue-dfp
 */

import { isFacebookApp } from '@/utils/utils';

export default {
  mappings: {
    home: [
      { window: [0, 0], sizes: [320, 50] },
      { window: [500, 0], sizes: [] },
    ],
    halfpage: [
      { window: [0, 0], sizes: [300, 250] },
      { window: [1000, 650], sizes: [300, 600] },
    ],
    square: [
      { window: [0, 0], sizes: [300, 250] },
    ],
    native: [
      { window: [0, 0], sizes: [320, 74] },
      { window: [960, 200], sizes: [520, 120] },
    ],
    leaderboard: [
      { window: [0, 0], sizes: [320, 50] },
      { window: [768, 90], sizes: [728, 90] },
    ],
    HomeRecents: [
      { window: [0, 0], sizes: [320, 50] },
      { window: [600, 0], sizes: [] },
      { window: [900, 0], sizes: [300, 250] },
    ],
    HomeCommunity: [
      { window: [0, 0], sizes: [300, 250] },
      { window: [768, 90], sizes: [728, 90] },
    ],
    HomeEngagement: [
      { window: [0, 0], sizes: [300, 250] },
      { window: [900, 0], sizes: [] },
    ],
    HomeCollections: [
      { window: [0, 0], sizes: [] },
      { window: [900, 0], sizes: [300, 250] },
    ],
    video: [
      { window: [0, 0], sizes: [320, 180] },
      { window: [768, 0], sizes: [640, 360] },
    ],
  },

  mappedSizes: {},

  timer: undefined,

  /**
   * Register all mappings in DFP
   * @param mapping
   * @returns {Object} built map of sizes
   */
  mapSize(mapping) {
    const mapper = window.googletag.sizeMapping();

    mapping.forEach((map) => {
      mapper.addSize(map.window, map.sizes);
    });

    return mapper.build();
  },

  prepareMappings() {
    // eslint-disable-next-line func-names
    Object.keys(this.mappings).forEach((key) => {
      window.googletag.mappedSizes[key] = this.mapSize(this.mappings[key]);
    });

    // Set window.debugAdMapping on a post to quickly test new ad sizes
    // with the shortcode `[ad_slot mapping="debug"]`
    // example:
    //   <script>window.debugAdMapping = [{ window: [0, 0], sizes: [500, 80] }];</script>
    //   <style>.ad-slot--debug { min-height: 80px; }</style>
    if (window.debugAdMapping) {
      window.googletag.mappedSizes.debug = this.mapSize(window.debugAdMapping);
    }

    return true;
  },

  // DFP gives us 30 seconds to load ads
  // Because of lazyloading, we want to ensure that
  // ads are loaded before that time period so we're not served
  // ads from different clients on the same page
  loadAllSlotsAfterTimeout(store) {
    this.timer = window.setTimeout(() => {
      const emptySlots = store.getters['gam/emptySlots'];

      if (!emptySlots.length) { return; }

      // Filter out ad slots where the element is hidden, this is necessary as
      // some ad slots should not be filled based on viewport size
      const slotsToRefresh = emptySlots.filter((slot) => {
        const slotEl = document.getElementById(slot.getSlotElementId());
        return slotEl && getComputedStyle(slotEl).display !== 'none';
      });

      window.googletag.cmd.push(() => {
        // slotsToRefresh *must* be an array, otherwise impressions will drop significantly.
        // https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_refresh
        window.googletag.pubads().refresh(slotsToRefresh, { changeCorrelator: false });
      });

      store.dispatch('gam/clearEmptySlots');
    }, 25000);
  },

  api: {
    // any boolean values must be stringified in initAds below
    targetingData: {
      ad_prefix: '',
      ad_category: '', // for posts within a CAS, this come from the parent CAS
      author_id: '',
      category: [],
      env: '',
      ga_user_id: '',
      krux_user_id: '',
      nexus_user_id: '',
      synthetic_pv_id: '',
      post_id: '',
      post_type: '',
      snowplow_user_d_id: '',
      tag: [], // for posts within a CAS, this must include the parent CAS tags
      ugc: false,
      wp_user_id: '',
      sess_refer_source: '',
      is_facebook_app: '',
      is_1p_identifiable: false,
      engagement_id: '',
    },

    /**
     * Sets the values for targetingData based on data from the dataLayer,
     * Then calls initAds to send the targetingData to DFP
     * Called from datalayer-afterware-link
     *
     * @param {object} dataLayer - the complete datalayer object
     */
    setTargeting(dataLayer) {
      const keyMap = {
        author_id: 'post_author',
        category: 'post_categories',
        tag: 'post_tags',
        ugc: 'has_user_generated_content',
        synthetic_pv_id: 'pageViewId',
      };
      Object.keys(this.targetingData).forEach((k) => {
        if (dataLayer[k]) {
          this.targetingData[k] = dataLayer[k];
        } else if (keyMap[k]) {
          this.targetingData[k] = dataLayer[keyMap[k]];
        }
      });
      this.targetingData.ugc = this.targetingData.ugc || this.isUgcRoute();
      if (sessionStorage.getItem('referer')) {
        this.targetingData.sess_refer_source = sessionStorage.getItem('referer');
      } else {
        // Traffic from sites that don't pass referer headers, or traffic with no referer (typed the URL, etc.)
        this.targetingData.sess_refer_source = 'Not Available';
      }
      this.targetingData.is_facebook_app = this.isFacebookApp();

      this.initAds();
    },

    isUgcRoute() {
      if (!process.browser) { return false; }
      return /comment-/.test(window.location.hash);
    },

    isFacebookApp,

    /**
     * Sets UGC on or off allowing ads to be hidden when comments are displayed
     *
     * @param {Boolean} enabled
     */
    setUgcFlag(enabled) {
      this.targetingData.ugc = enabled;
      window.googletag.cmd.push(() => {
        window.googletag.pubads().setTargeting('ugc', enabled.toString());
        window.googletag.pubads().clear();
        window.googletag.pubads().refresh();
      });
    },

    /**
     * Send targetingData to DFP
     * Excludes any undefined or falsey values
     */
    initAds() {
      window.googletag.cmd.push(() => {
        Object.keys(this.targetingData).forEach((key) => {
          const booleanKeys = ['ugc', 'is_facebook_app', 'is_1p_identifiable'];

          if (booleanKeys.includes(key) && typeof this.targetingData[key] !== 'undefined') {
            window.googletag.pubads().setTargeting(key, this.targetingData[key].toString());
            return;
          }
          if (!this.targetingData[key]) { return; }
          window.googletag.pubads().setTargeting(key, this.targetingData[key]);
        });
      });
    },

    destroyAds() {
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots();
      });
    },

    // Ads are disabled for dev environment and HuSite
    adsEnabled() {
      // eslint-disable-next-line no-underscore-dangle
      const initialState = window.__INITIAL_STATE__;
      const { env } = initialState.dataLayer.siteDataLayer;
      return env !== 'dev' || initialState.currentSite.site.settings.ads_enabled;
    },
  },

  install(Vue, store, router) {
    // This is only necessary for running the app in non-ssr mode
    window.googletag = window.googletag || { cmd: [] };

    window.googletag.cmd.push(() => {
      this.prepareMappings();
      window.googletag.pubads().enableSingleRequest();
      window.googletag.pubads().collapseEmptyDivs();
      window.googletag.pubads().disableInitialLoad();
      window.googletag.pubads().enableVideoAds();
      window.googletag.enableServices();
      // TODO: We may want to send this data to snowflake via snowplow or GTM later
      // window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
      //   console.log('event', event);
      //   console.log('ad campaign ID', event.campaignId);
      //   console.log('ad creative ID', event.creativeId);
      // });
    });

    if (router.afterEach) {
      router.afterEach(() => {
        this.loadAllSlotsAfterTimeout(store);
      });
    }

    if (router.beforeResolve) {
      router.beforeResolve((to, from, next) => {
        if (this.timer) {
          window.clearTimeout(this.timer);
        }
        this.api.destroyAds();
        store.dispatch('gam/resetAdsOnPage');
        next();
      });
    }

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$gam = Object.assign(
      this.api,
      {
        network_id: '3996134',
      },
    );
  },
};
