const gamState = () => ({
  adsOnPage: 0,
  empty_slots: [],
});

const getters = {
  emptySlots: (state) => state.empty_slots,
};

const mutations = {
  incrementAdsOnPage(state) {
    state.adsOnPage += 1;
  },
  resetAdsOnPage(state) {
    state.adsOnPage = 0;
  },
  pushEmptySlots(state, data) {
    state.empty_slots.push(data);
  },
  removeFromEmptySlots(state, data) {
    const renderedSlotIndex = state.empty_slots.indexOf(data);
    if (renderedSlotIndex > -1) {
      state.empty_slots.splice(renderedSlotIndex, 1);
    }
  },
  clearEmptySlots(state) {
    state.empty_slots = [];
  },
};

const actions = {
  incrementAdsOnPage({ commit }) {
    commit('incrementAdsOnPage');
  },
  resetAdsOnPage({ commit }) {
    commit('resetAdsOnPage');
    commit('clearEmptySlots');
  },
  pushEmptySlots({ commit }, data) {
    commit('pushEmptySlots', data);
  },
  removeFromEmptySlots({ commit }, data) {
    commit('removeFromEmptySlots', data);
  },
  clearEmptySlots({ commit }) {
    commit('clearEmptySlots');
  },
};

export default () => ({
  namespaced: true,
  state: gamState(),
  getters,
  mutations,
  actions,
});
