<template>
  <div id="g_id_onload"
       :data-client_id="clientId"
       data-callback="oneTapCallback"
       data-context="signin"
       data-prompt_parent_id="g_id_onload"
  />
</template>

<script>

import dataLayerMixin from '@/mixins/data-layer-mixin';

export default {
  name: 'OneTap',
  mixins: [dataLayerMixin],
  computed: {
    clientId() {
      return this.$site.settings.google_client_id;
    },
  },
  mounted() {
    window.oneTapCallback = (responseData) => {
      const r = new Request('/api/auth/one-tap', { method: 'POST', body: new URLSearchParams(responseData) });
      fetch(r)
        .then((response) => response.json())
        .then((res) => {
          this.setDataLayer();
          this.pushDataLayer();

          this.$store.dispatch('setCurrentUser', res.user);
          this.$snowplow.connectSocialAccountTracking({
            data: {
              connected_account: 'google',
            },
          });
        });
    };
    const googScript = document.createElement('script');
    googScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
    googScript.setAttribute('async', '');
    googScript.setAttribute('defer', '');
    document.head.appendChild(googScript);
  },
};
</script>
<style lang="scss" scoped>
  #g_id_onload {
    top: $height-header + 7px;
    position: absolute;
    right: 5px;
  }
</style>

<docs>
</docs>
