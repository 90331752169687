/* eslint-disable no-underscore-dangle */
import { host } from '@/utils/host-helpers';
import { SITE_CONFIGURATION_QUERY } from '@/graphql/queries/site-queries';
import { loggerApi } from '@/plugins/logger';
import { siteJsonLd } from '@/utils/structured-data';
import cache from '@/utils/lru-cache';

async function getSiteConfiguration(app) {
  const hit = cache.get(`site-${app.context.req.headers.host}`);
  if (hit) {
    return new Promise((resolve) => resolve(hit));
  }

  return app.apolloProvider.defaultClient.query({
    query: SITE_CONFIGURATION_QUERY,
  });
}

export default (app, inject) => new Promise((resolve, reject) => {
  const logger = loggerApi({ context: app.context });
  if (process.browser && window.__INITIAL_STATE__ && window.__INITIAL_STATE__.currentSite) {
    const siteData = window.__INITIAL_STATE__.currentSite.site;
    inject(app, '$site', siteData);
    resolve();
  } else {
    getSiteConfiguration(app)
      .then((res) => {
        if (!res.fromCache) {
          cache.set(`site-${app.context.req.headers.host}`, { ...res, fromCache: true });
        }
        const siteData = res.data.site;

        inject(app, '$site', siteData);

        const hostName = host(app.context);
        app.store.dispatch('setSiteJsonLd', siteJsonLd(hostName, siteData));
        app.store.dispatch('setSiteDataLayer', JSON.parse(siteData.dataLayer));

        // Initialize site in vuex, we may want to move to using vuex for siteData
        // but that is a large refactor that seems risky this close to launch
        app.store.dispatch('setSite', siteData);
        if (!process.browser && !app.context.req.cookies.vuex && siteData.settings.accessibility) {
          app.store.dispatch('initializeAccessibilitySettings', siteData.settings.accessibility);
        }
        resolve();
      }).catch((err) => {
        reject(err);
        logger.error(err);
      });
  }
});
