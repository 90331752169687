<template>
  <span
    class="inline-mention link link--unstyled"
    aria-haspopup="true"
    tabindex="0"
    @click="openUserProfileTooltip"
    @keyup.enter="openUserProfileTooltip"
  >
    {{ username }}
  </span>
</template>

<script>
import openUserProfileTooltipMixin from '@/mixins/open-user-profile-tooltip-mixin';

export default {
  name: 'InlineMention',
  mixins: [openUserProfileTooltipMixin],
  props: {
    username: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: true,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_inline-mention';
</style>

<docs>

Displays a mention in the context of a comment, thread, or other content type that
allows mentioning.

Clicking on an InlineMention will open the Mentioned User's ProfileTooltip.

This component should always be injected using the parse-mentions.js mixin.

This example of the component out of context, for a better example see CommentThread:

</docs>
