const privateMessagingState = () => ({
  popOverUserId: '',
  popOverUsername: '',
});

const getters = {
  popOverOpen: (state) => state.popOverUsername && state.popOverUserId,
  popOverUsername: (state) => state.popOverUsername,
  popOverUserId: (state) => state.popOverUserId,
};

const mutations = {
  openConversationPopOver(state, data) {
    state.popOverUserId = data.userId;
    state.popOverUsername = data.username;
  },
  closeConversationPopOver(state) {
    state.popOverUserId = '';
    state.popOverUsername = '';
  },
};

const actions = {
  openConversationPopOver({ commit }, data) {
    commit('openConversationPopOver', data);
  },
  closeConversationPopOver({ commit }) {
    commit('closeConversationPopOver');
  },
};

export default () => ({
  state: privateMessagingState(),
  getters,
  mutations,
  actions,
});
