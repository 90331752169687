<template>
  <div class="masthead">
    <h1
      v-if="isH1"
      class="masthead__heading"
      :class="{ 'masthead__heading--with-subtext': showSubtext }"
      data-test-id="masthead-heading"
    >
      <img
        :src="mastheadSrc"
        :alt="theSiteName"
        :class="`masthead__image masthead__image--${$site.prefix}`"
      >
    </h1>
    <app-link v-else :to="href" exact class="masthead__link link link--unstyled">
      <img
        :src="mastheadSrc"
        :alt="theSiteName"
        loading="lazy"
        :class="`masthead__image masthead__image--${$site.prefix}`"
      >
    </app-link>
    <p
      v-if="showSubtext"
      class="masthead__tagline"
      data-test-id="masthead-tagline"
      v-html="subTextContent"
    />
  </div>
</template>

<script>

export default {
  name: 'SiteMasthead',
  props: {
    mayHaveSubText: {
      type: Boolean,
      default: false,
    },
    isH1: {
      type: Boolean,
      default: false,
    },
    siteName: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '/',
    },
  },
  computed: {
    theSiteName() {
      return this.siteName || this.$site.name;
    },
    cleanedSiteName() {
      return this.theSiteName.split('.')[0].toLowerCase();
    },
    mastheadSrc() {
      return `/common/logo/masthead/${this.cleanedSiteName}.svg`;
    },
    foundingText() {
      const foundingYear = this.$site?.settings?.community_hub?.year_founded;

      if (!foundingYear) return null;

      return `A <strong>Health Union</strong> Community Since ${foundingYear}`;
    },
    childSiteText() {
      if (!this.$store.getters.isChildSite) return null;

      return `A ${this.$site.parentSite.name} community`;
    },
    subTextContent() {
      if (!this.mayHaveSubText) return null;
      if (this.childSiteText) return this.childSiteText;
      if (this.foundingText) return this.foundingText;
      return null;
    },
    showSubtext() {
      return this.mayHaveSubText && !!this.subTextContent;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_masthead';
</style>

<docs>
</docs>
