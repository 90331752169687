<template>
  <div class="request-verification-text">
    <form-component
      novalidate="true"
      class="request-verification-text__form"
      @submit.prevent="tryToSubmitPhoneNumber"
    >
      <text-input
        id="phone-number"
        v-model="phoneNumber"
        name="phone_number"
        input-type="tel"
        class="text-input--full-width text-input--lowercase"
        data-test-id="request-verification-text-phone-number-input"
        @input="formatPhoneNumber"
      >
        Phone Number
      </text-input>
      <inline-validation-error
        v-if="phoneNumberIsInvalid"
        :validation-text="errorText"
        class="request-verification-text__error"
      />
      <loading-button
        class="request-verification-text__submit button--large"
        type="submit"
        :loading="tryingToSubmit"
      >
        Send
      </loading-button>
    </form-component>
    <progress-indicator v-if="!siteHasFeature('social_network')" current-step="3" />
  </div>
</template>

<script>
import TextInput from '@/components/Inputs/TextInput.vue';
import InlineValidationError from '@/components/Auth/InlineValidationError.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import { SET_PHONE } from '@/graphql/mutations/user-mutations';
import { clearCountryCodeAndNonNumerics, formatPhoneNumber } from '@/utils/phone-number';
import { mapGetters } from 'vuex';

export default {
  name: 'RequestVerificationText',
  components: {
    TextInput,
    InlineValidationError,
    LoadingButton,
    ProgressIndicator,
  },
  data() {
    return {
      phoneNumber: '+1',
      phoneNumberIsInvalid: false,
      tryingToSubmit: false,
      errorText: 'Please enter a valid phone number',
    };
  },
  computed: {
    ...mapGetters(['siteHasFeature']),
  },
  methods: {
    formatPhoneNumber(enteredValue, event) {
      if (event.inputType.includes('delete')) return;

      const newValue = formatPhoneNumber(enteredValue);

      this.phoneNumber = newValue;

      if (clearCountryCodeAndNonNumerics(this.phoneNumber).length === 10) this.phoneNumberIsInvalid = false;
    },
    tryToSubmitPhoneNumber() {
      const phoneNumber = clearCountryCodeAndNonNumerics(this.phoneNumber);
      if (phoneNumber.length !== 10) {
        this.errorText = 'Please enter a valid phone number';
        this.phoneNumberIsInvalid = true;
        return;
      }

      this.tryingToSubmit = true;

      this.$apollo.mutate({
        mutation: SET_PHONE,
        variables: {
          phoneNumber,
        },
      }).then(() => {
        this.$store.dispatch('setAuthStep', { authStep: 'enterVerificationNumber' });
      }).catch((error) => {
        this.$logger.error(`[Set Phone Number] ${error}`);
        this.errorText = `${error}`.replace('Error: GraphQL error: ', '');
        this.phoneNumberIsInvalid = true;
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_request-verification-text';
</style>
