<template>
  <div
    class="icon-with-tooltip"
    :class="tooltipIsActive ? 'icon-with-tooltip--active' : ''"
  >
    <icon-component
      container="button"
      :name="iconName"
      aria-label="Show Information"
      class="icon-with-tooltip__toggle-button"
      type="button"
      @click="setTooltipVisibility(!tooltipIsActive)"
    />
    <div
      v-if="tooltipIsActive"
      ref="tooltip"
      class="icon-with-tooltip__tooltip"
      :style="positionStyles"
    >
      <div ref="arrow" class="icon-with-tooltip__arrow" :style="arrowPositionStyles" />
      <div class="icon-with-tooltip__tooltip-inner-wrapper">
        <div class="icon-with-tooltip__content"><slot /></div>
        <button
          class="icon-with-tooltip__confirm-button text-button"
          type="button"
          @click="setTooltipVisibility(false)"
        >Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'IconWithTooltip',
  props: {
    iconName: {
      type: String,
      default: () => 'info',
    },
  },
  data() {
    return {
      tooltipIsActive: false,
      positionStyles: {},
      arrowPositionStyles: {},
    };
  },
  watch: {
    tooltipIsActive(showTooltip) {
      if (showTooltip) this.$nextTick(this.handleDropdownPosition);
    },
  },
  mounted() {
    window.addEventListener('resize', debounce(this.handleDropdownPosition, 200));
  },
  beforeDestroy() {
    window.removeEventListener('resize', debounce(this.handleDropdownPosition, 200));
  },
  methods: {
    setTooltipVisibility(showTooltip) {
      this.tooltipIsActive = showTooltip;
    },
    resetPositionStyles() {
      this.positionStyles = {};
      this.arrowPositionStyles = {};
    },
    setArrowPosition(overlapAmount) {
      // TO DO: Add some logic to keep the arrow within the bounds of the tooltip box.
      const arrowAdjustment = 4;
      const arrowRectangle = this.$refs.arrow.getBoundingClientRect();
      const arrowFiftyPercentInPixels = arrowRectangle.width / 2;

      this.arrowPositionStyles = { transform: `translateX(${overlapAmount - arrowFiftyPercentInPixels + arrowAdjustment}px) rotate(45deg)` };
    },
    handleDropdownPosition() {
      if (!this.tooltipIsActive) {
        this.resetPositionStyles();
        return;
      }

      const screenPadding = 8;
      const tooltipRectangle = this.$refs.tooltip.getBoundingClientRect();
      const fiftyPercentInPixels = tooltipRectangle.width / 2;

      const leftLimit = screenPadding;
      const tooltipLeftX = tooltipRectangle.x;
      const leftOverlap = tooltipLeftX - leftLimit;
      const tooltipOverlapsOnTheLeft = tooltipRectangle.x < leftLimit;

      if (tooltipOverlapsOnTheLeft) {
        this.positionStyles.transform = `translateX(-${leftOverlap + fiftyPercentInPixels}px)`;

        this.setArrowPosition(leftOverlap);
      }

      const rightLimit = window.innerWidth - screenPadding;
      const tooltipRightX = tooltipRectangle.x + tooltipRectangle.width;
      const rightOverlap = tooltipRightX - rightLimit;
      const tooltipOverlapsOnTheRight = tooltipRightX > rightLimit;

      if (tooltipOverlapsOnTheRight) {
        this.positionStyles.transform = `translateX(-${rightOverlap + fiftyPercentInPixels}px)`;

        this.setArrowPosition(rightOverlap);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_icon-with-tooltip';
</style>
