// This utility implements a shared LRU cache for long running queries
// The cache can be busted for the entire app (on a given site/node) by adding ?nexus-refresh-cache
// to any URL
const LRU = require('lru-cache');

const cacheOptions = {
  max: 1000,
  length(n, key) { return n * 2 + key.length; },
  maxAge: 1000 * 60 * 60,
};

const cache = new LRU(cacheOptions);

export default cache;
