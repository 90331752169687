const configurationOptionsState = () => ({
  openOptions: [],
});

const getters = {
  openConfigOptions: (state) => state.openOptions,
};

const mutations = {
  openConfigOption(state, data) {
    state.openOptions.push(data);
  },
  closeConfigOption(state, data) {
    state.openOptions.splice(state.openOptions.map((n) => n.id).indexOf(data), 1);
  },
};

const actions = {
  openConfigOption({ commit }, data) {
    commit('openConfigOption', data);
  },
  closeConfigOption({ commit }, data) {
    commit('closeConfigOption', data);
  },
};

export default () => ({
  state: configurationOptionsState(),
  getters,
  mutations,
  actions,
});
