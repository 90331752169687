import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'isParentSite',
      'isChildSite',
      'siteSortOrders',
    ]),
    siteName() {
      return this.$site.name;
    },
    parentCommunityName() {
      if (this.isParentSite) {
        return this.$site.name;
      }
      return this.$site.parentSite.name;
    },
    parentCommunityHref() {
      if (this.isChildSite) {
        return `https://${this.$site.parentSite.name.toLowerCase()}`;
      }
      return '/';
    },
    order() {
      return this.siteSortOrders(this.siteName);
    },
    sortedChildCommunities() {
      const children = this.isParentSite
        ? this.$site.childSites
        : this.$site.siblingSites.concat(this.$site);

      if (!this.order) {
        return children;
      }

      return children.slice().sort(
        (a, b) => (this.order.indexOf(a.prefix) > this.order.indexOf(b.prefix) ? 1 : -1),
      );
    },
    childCommunityNames() {
      return this.sortedChildCommunities.map((child) => child.name);
    },
  },
  methods: {
    formattedCommunityName(community) {
      const communityName = community.split('.')[0];
      const updatedCommunityNames = {
        Postpartum: 'Postpartum Depression',
        Bipolar: 'Bipolar Disorder',
      };

      const formattedCommunityName = communityName.replace(/([a-z])([A-Z])/g, '$1 $2');
      return updatedCommunityNames[communityName] || formattedCommunityName;
    },
  },
};
