<template>
  <div class="cross-publishing-indicator">
    <div class="cross-publishing-indicator__logos">
      <img
        v-for="prefix in sortedPrefixes.firstTwo"
        :key="`${prefix}_logo`"
        :src="logoSrc(prefix)"
        :alt="siteName(prefix)"
        :width="21"
        :height="21"
        class="cross-publishing-indicator__logo"
      >
      <div
        v-if="sortedPrefixes.overflow.length"
        class="cross-publishing-indicator__logo"
      >+{{ sortedPrefixes.overflow.length }}</div>
    </div>
    <p class="cross-publishing-indicator__details">
      Published on
      <template v-if="!sortedPrefixes.overflow.length">
        {{ siteName(sortedPrefixes.firstTwo[0]) }} and
        <a
          class="cross-publishing-indicator__link"
          :href="linkForOtherSite(sortedPrefixes.firstTwo[1])"
          target="_blank"
        >
          {{ siteName(sortedPrefixes.firstTwo[1]) }}
        </a>
      </template>
      <template v-else-if="!allowTooltip">{{ prefixes.length }} communities</template>
      <button
        v-else
        class="text-button text-button--link-style"
        @click="showOverflow = !showOverflow"
      >
        {{ prefixes.length }} communities
      </button>
    </p>
    <ul
      v-if="showOverflow"
      v-click-outside="() => showOverflow = false"
      class="cross-publishing-indicator__overflow-list"
    >
      <li
        v-for="overflowPrefix in prefixesAlphabetizedBySiteName"
        :key="overflowPrefix"
        class="cross-publishing-indicator__overflow-item"
      >
        <component
          :is="overflowPrefix !== $site.prefix ? 'a' : 'span'"
          :target="overflowPrefix !== $site.prefix ? '_blank' : null"
          class="cross-publsihing-indicator__overflow-link"
          :href="overflowPrefix !== $site.prefix ? linkForOtherSite(overflowPrefix) : null"
        >
          <img
            :key="`${overflowPrefix}_logo`"
            :src="logoSrc(overflowPrefix)"
            :alt="siteName(overflowPrefix)"
            :width="21"
            :height="21"
            class="cross-publishing-indicator__logo"
          >
          {{ siteName(overflowPrefix) }}
        </component>
      </li>
    </ul>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';

export default {
  name: 'CrossPublishingIndicator', // AKA The XPI©
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    prefixes: {
      type: Array,
      required: true,
    },
    allowTooltip: {
      type: Boolean,
      default: true,
    },
    originalSite: {
      type: String,
      required: true,
    },
    postPath: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showOverflow: false,
  }),
  computed: {
    ...mapGetters(['getSiteSummary']),
    prefixesAlphabetizedBySiteName() {
      return this.prefixes
        .map((prefix) => this.getSiteSummary(prefix))
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .map((siteSummary) => siteSummary.prefix);
    },
    sortedPrefixes() {
      const prefixes = [...this.prefixesAlphabetizedBySiteName];

      const indexOfCurrentSite = prefixes.indexOf(this.$site.prefix);
      const firstTwoPrefixes = [
        prefixes.splice(indexOfCurrentSite, 1)[0],
        prefixes.shift(),
      ];

      return {
        firstTwo: firstTwoPrefixes,
        overflow: prefixes,
      };
    },
  },
  methods: {
    siteName(prefix) {
      return (this.getSiteSummary(prefix) && this.getSiteSummary(prefix).name) || prefix;
    },
    siteHost(prefix) {
      return this.getSiteSummary(prefix) && this.getSiteSummary(prefix).host;
    },
    logoSrc(prefix) {
      return `https://${this.siteHost(prefix)}/images/logo_avatar.svg`;
    },
    linkForOtherSite(prefix) {
      let url = `https://${this.siteHost(prefix)}/${this.postPath}`;

      if (prefix !== this.originalSite) url += `?original_site=${this.originalSite}`;

      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_cross-publishing-indicator';
</style>
