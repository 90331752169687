<template>
  <form-component
    method="post"
    class="subscription-form"
    @submit.prevent="handleSubscribe()"
  >
    <label
      class="subscription-form__label heading heading--3"
      for="emailaddressnexus"
    >Stay connected with the community</label>
    <div class="subscription-form__group">
      <input
        id="emailaddressnexus"
        v-model="email"
        placeholder="Enter your email"
        class="subscription-form__input"
        required
        type="email"
      >
      <input
        id="email-address"
        v-model="decoyEmail"
        autocomplete="off"
        class="decoyfield"
        tabindex="-1"
        placeholder="Enter email address"
        type="email"
      >
      <button-component
        type="submit"
        class="subscription-form__button"
        button-type="subscribe to newsletter"
      >Subscribe</button-component>
    </div>
    <p class="subscription-form__disclaimer disclaimer-text">
      By providing your email address, you are agreeing to our
      <a
        href="https://health-union.com/privacy-policy/"
        target="_blank"
        rel="noopener"
      >Privacy Policy</a>
      and
      <a
        href="https://health-union.com/terms-of-use/"
        target="_blank"
        rel="noopener"
      >Terms of Use</a>.
    </p>
  </form-component>
</template>

<script>
import { GENERATE_LEAD } from '@/graphql/mutations/lead-gen-mutations';
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import dataLayerMixin from '@/mixins/data-layer-mixin';

export default {
  name: 'SubscriptionForm',
  components: {
    ButtonComponent,
  },
  mixins: [dataLayerMixin],
  data() {
    return {
      email: '',
      decoyEmail: '',
    };
  },
  methods: {
    handleSubscribe() {
      this.$apollo.mutate({
        mutation: GENERATE_LEAD,
        variables: {
          campaign: 'site',
          form: 'footer',
          email: this.email,
          decoyEmail: this.decoyEmail,
          pageviewId: this.$store.getters.pageViewId,
          medium: 'Site',
        },
      }).catch(() => {
        this.$logger.warn('problem submitting new lead.');
      });

      this.$store.dispatch('setUserHasSubscribed', true);
      this.setDataLayer();
      this.pushDataLayer();
      this.setTrackingInAC(this.email);

      // always display success toast to avoid delay between submitting & getting a response from API
      this.$store.dispatch('addToastNotification', {
        toastType: 'success',
        description: 'Thanks for signing up for emails!',
      });
      this.email = '';
      this.decoyEmail = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_subscription-form';
</style>

<docs>
Email subscription form shown in the site footer, this is different to the
leadgen form.

</docs>
