<template>
  <div class="verification-options">
    <button-component
      class="verification-options__button verification-options__button--text button--block"
      data-test-id="send-text-verification-options-button"
      @click="$store.dispatch('setAuthStep', { authStep: 'requestVerificationText' })"
    >
      <icon-component
        name="text-bubble-ellipsis"
        class="button__icon"
      />
      Send me a text message
    </button-component>
    <button-component
      class="verification-options__button verification-options__button--email button--block"
      data-test-id="send-email-verification-options-button"
      @click="handleSendingEmail()"
    >
      <icon-component
        name="envelope"
        class="button__icon"
      />
      Send me an email
    </button-component>
    <progress-indicator :current-step="getCurrentStep" />
  </div>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import { RESEND_EMAIL_VERIFICATION } from '@/graphql/mutations/user-mutations';
import { mapGetters } from 'vuex';
import authRedirectMixin from '@/mixins/auth-redirect-mixin';

export default {
  name: 'VerificationOptions',
  components: {
    ButtonComponent,
    ProgressIndicator,
  },
  mixins: [authRedirectMixin],
  computed: {
    ...mapGetters([
      'emailAlreadySent',
      'userIsLoggedIn',
      'userIsUnverified',
      'siteHasFeature',
      'ProgressIndicatorSteps',
      'authDialogRedirect',
    ]),
    getCurrentStep() {
      if (this.siteHasFeature('social_network')) {
        return `${this.ProgressIndicatorSteps}`;
      }
      return '3';
    },
  },
  methods: {
    handleSendingEmail() {
      if (!this.userIsLoggedIn || !this.userIsUnverified) {
        this.$store.dispatch('addToastNotification', { toastType: 'error', description: 'You must be logged in and unverified to resend a verification email.' });
        this.$logger.error('[Invalid Resend Email Verification Attempt: VerificationOptions]');
        return;
      }

      if (!this.emailAlreadySent) {
        this.$apollo.mutate({
          mutation: RESEND_EMAIL_VERIFICATION,
          variables: {
            verificationRedirect: this.redirectToInfluitive ? this.redirect : null,
          },
        }).catch((error) => {
          this.$logger.error(`[Resend Email Verification] ${error}`);
        });
      }
      this.$store.dispatch('openAuthDialog', { authStep: 'verifyViaEmail' });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_verification-options';
</style>
