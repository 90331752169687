<template>
  <button
    id="a11y-tools-toggle-button"
    type="button"
    aria-label="Accessibility Tools"
    :aria-expanded="a11yMenuOpen ? 'true' : 'false'"
    aria-controls="accessibility-menu"
    :class="classes"
    @click="handleClick"
  >
    <icon-component
      name="accessibility"
      class="header__button-icon"
    />
    <span class="header__button-text">Accessibility</span>
  </button>
</template>

<script>
export default {
  name: 'AccessibilityButton',
  computed: {
    a11yMenuOpen() {
      return this.$store.state.accessibility.menuOpen;
    },
    classes() {
      const classes = ['header__button', 'header__button--accessibility'];
      if (this.a11yMenuOpen) {
        classes.push('header__button--accessibility-open');
      }
      return classes;
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('a11y_menu', { label: 'toggle', opening: !this.a11yMenuOpen });
      this.$emit('a11yButtonClicked');
      this.$store.dispatch('setA11yMenuOpen', !this.a11yMenuOpen);
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'a11y',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
    },
  },
};
</script>

<docs>
</docs>
