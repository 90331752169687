<template>
  <form
    class="request-disorder-demographic-info"
    @submit.prevent="handleFormSubmit"
  >
    <div
      class="request-disorder-demographic-info__field"
      data-test-id="request-disorder-demographic-info-field"
    >
      <label
        for="user-condition-select"
        class="request-disorder-demographic-info__label"
      >
        {{ conditionSelectLabel }} <span v-if="!siteIsSHN">(optional)</span>
      </label>
      <div
        class="validation-group"
        :class="{ 'validation-group--invalid': siteIsSHN && $v.condition.$invalid }"
      >
        <validation-error
          v-if="siteIsSHN"
          ref="condition"
          class="validation-error--label"
          :model="conditionName"
          :validator="$v.condition"
        />
        <condition-select
          input-id="user-condition-select"
          type="USER"
          name="condition"
          :click-handler-for-options="setCondition"
          :render-label="false"
        />
      </div>
    </div>
    <div
      class="request-disorder-demographic-info__field"
      data-test-id="request-disorder-demographic-info-field"
    >
      <label
        for="user-relationship-select"
        class="request-disorder-demographic-info__label"
      >
        Relationship to condition <span v-if="!siteIsSHN">(optional)</span>
      </label>
      <div
        class="validation-group"
        :class="{ 'validation-group--invalid': siteIsSHN && $v.relationship.$invalid }"
      >
        <validation-error
          v-if="siteIsSHN"
          ref="relationship"
          class="validation-error--label"
          :model="relationship"
          :validator="$v.relationship"
        />
        <select-input
          id="user-relationship-select"
          class="request-disorder-demographic-info__relationship-select select-input--block select-input--cyan"
          :options="getRelationshipOptions"
          @change="setRelationship"
        />
      </div>
    </div>
    <div
      class="request-disorder-demographic-info__field request-disorder-demographic-info__field--submit"
      data-test-id="request-disorder-demographic-info-field"
    >
      <loading-button
        class="button button--large"
        type="submit"
        :loading="tryingToSubmit"
      >Continue</loading-button>
    </div>
    <progress-indicator :current-step="getCurrentStep" />
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import ConditionSelect from '@/components/Disorder/ConditionSelect.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import SelectInput from '@/components/Inputs/SelectInput.vue';
import { SET_DISORDER_RELATIONSHIP } from '@/graphql/mutations/user-mutations';

export default {
  name: 'RequestDisorderDemographicInfo',
  components: {
    ProgressIndicator,
    ConditionSelect,
    SelectInput,
    LoadingButton,
    ValidationError,
  },
  data() {
    return {
      tryingToSubmit: null,
      condition: null,
      relationship: null,
    };
  },
  validations: {
    condition: { required },
    relationship: { required },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'fastTrackToContent',
      'siteHasFeature',
    ]),
    siteIsSHN() {
      return this.siteHasFeature('social_network');
    },
    getRelationshipOptions() {
      const defaultOptions = [
        {
          value: '', name: 'Select', selected: true, disabled: true,
        },
        { value: 'PATIENT', name: 'Patient', selected: false },
        { value: 'CAREGIVER', name: 'Caregiver', selected: false },
        { value: 'HEALTHCARE_PROVIDER', name: 'Healthcare Provider', selected: false },
      ];
      const additionalOptions = this.siteIsSHN
        ? []
        : [{ value: 'OTHER', name: 'Other', selected: false }];

      return [...defaultOptions, ...additionalOptions];
    },
    getCurrentStep() {
      return this.siteIsSHN ? '3' : '2';
    },
    conditionSelectLabel() {
      return 'Primary condition';
    },
    conditionName() {
      return this.condition?.name;
    },
  },
  methods: {
    handleFormSubmit() {
      const { username } = this.currentUser;
      const tempUsername = `CommunityMember${this.currentUser.id}`;
      const userNeedsUsername = !username || username === tempUsername;

      let nextStep = userNeedsUsername ? 'setUsername' : 'verificationOptions';
      if (this.siteHasFeature('social_network')) nextStep = 'setSocialSites';

      this.tryingToSubmit = true;
      if ((!this.condition || !this.relationship) && this.siteIsSHN) {
        this.$refs.condition.checkErrors();
        this.$refs.relationship.checkErrors();
        if (this.$v.$invalid) {
          this.tryingToSubmit = false;
          return;
        }
      }
      if (!this.condition && !this.relationship) {
        if (this.fastTrackToContent) {
          this.$store.dispatch('closeAuthDialog');
          return;
        }
        this.$store.dispatch('setAuthStep', { authStep: nextStep });
        return;
      }

      this.$apollo.mutate({
        mutation: SET_DISORDER_RELATIONSHIP,
        variables: {
          disorderId: this.condition?.id,
          relationship: this.relationship,
        },
      }).then(() => {
        if (this.fastTrackToContent) {
          this.$store.dispatch('closeAuthDialog');
          return;
        }
        this.$store.dispatch('setAuthStep', { authStep: nextStep });
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
    setCondition(event, condition) {
      this.condition = condition;
    },
    setRelationship(relationship) {
      this.relationship = relationship;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_request-disorder-demographic-info';
</style>
