const imageUploadsState = () => ({
  uploadedImages: [],
  imageUploadOpen: false,
  mentionBoxID: '',
  imageType: 'ugc',
});

const getters = {
  uploadedImages: (state) => state.uploadedImages,
  mentionBoxID: (state) => state.mentionBoxID,
  imageType: (state) => state.imageType,
};

const mutations = {
  clearUploadedImages(state) {
    state.uploadedImages = [];
  },
  addUploadedImage(state, data) {
    state.uploadedImages.push(data);
  },
  setImageUploadOpen(state, data) {
    state.imageUploadOpen = data;
  },
  setMentionBoxID(state, data) {
    state.mentionBoxID = data;
  },
  setImageType(state, data) {
    state.imageType = data;
  },
};

const actions = {
  clearUploadedImages({ commit }) {
    commit('clearUploadedImages');
    commit('setMentionBoxID', '');
  },
  addUploadedImage({ commit }, data) {
    commit('setImageUploadOpen', false);
    commit('addUploadedImage', data);
  },
  openImageUploadDialog({ commit }, data) {
    commit('setImageUploadOpen', true);
    commit('setMentionBoxID', data);
    commit('setImageType', 'ugc');
  },
  openAvatarUploadDialog({ commit }, data) {
    commit('setImageUploadOpen', true);
    commit('setMentionBoxID', data);
    commit('setImageType', 'avatar');
  },
  closeImageUploadDialog({ commit }) {
    commit('setImageUploadOpen', false);
  },
};

export default () => ({
  state: imageUploadsState(),
  getters,
  mutations,
  actions,
});
