export default {
  computed: {
    hasChildSiteTeasers() {
      return this.$store.getters.siteHasFeature('Child Site Teasers');
    },
    link() {
      const typePath = {
        page: 'posts',
        post: 'posts',
        cas: '',
        gallery: 'images',
        graphic: 'infographic',
        sponsored_article: 'posts',
        quiz: 'quizzes',
        story: 'stories',
        recipe: 'recipes',
      };
      let path = '';
      if (this.post.permalink) {
        path = `/${this.post.permalink}`;
      } else {
        path = `/${typePath[this.post.type]}/${this.post.slug}`.replace(/\/\//g, '/');
      }
      if (this.hasChildSiteTeasers
        && this.post.siteName
        && this.post.siteName !== this.$site.name
      ) {
        // Note that it is necessary to specify https here since this ends up being
        // used in AppLink.vue and should not be a router-link
        return `https://${this.post.siteName.toLowerCase()}${path}`;
      }

      if (
        this.post.originalSite
        && this.post.originalSite !== this.$site.prefix
      ) path += `?original_site=${this.post.originalSite}`;

      return path;
    },
  },
};
