<template>
  <div class="loading-spinner" data-test-id="loading-spinner">
    <span class="loading-spinner__dot" />
    <span class="loading-spinner__dot" />
    <span class="loading-spinner__dot" />
    <span class="loading-spinner__hidden-text">
      Loading...
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_loading-spinner';
</style>

<docs>
The loading spinner component can be added to another component in the UI to
indicate that something is happening. This will usually be during a network
request or a long running process.

Note: the spinner includes visually hidden text for accessibility.

Can be rendered in black or white for high contrast modes, and centered within a
relative position container.

Font size can be adjusted to change Y-axis offset of loading animation

</docs>
