import gql from 'graphql-tag';

export const REACTIONS_QUERY = gql`
  query reactions($reactables: [ReactableIdentifier!]) {
    reactions(reactables: $reactables) {
      assocId
      assocType
      reactionAggregates {
        count
        isSelected
        type
      }
    }
  }
`;
