<template>
  <form
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </form>
</template>

<script>
export default {
  name: 'FormComponent',
  mounted() {
    this.$snowplow.enableFormTracking();
  },
};
</script>
<docs>

FormComponent should be used on all forms in this app so we can call
enableFormTracking on the mounted hook once a form has been added to the DOM.

</docs>
