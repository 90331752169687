import gql from 'graphql-tag';

export const GET_ALL_TAXONOMY_SLUGS = gql`
query taxonomies {
  categories {
    slug
    name
    hasPost
    indexType
  }
}
`;
