import { CONTENT_FLAG, MODERATION_CONTENT_FLAG } from '@/graphql/mutations/moderation-mutations';
import { openInModeration } from '@/utils/moderation-helpers';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isModeratorOrAdmin', 'userIsLoggedIn', 'isAdmin', 'userIsUnverified']),
    moderationDataIsValid() {
      return this.moderation && this.moderation.assocId && this.moderation.assocType;
    },
  },
  created() {
    if (!this.moderationDataIsValid) {
      // This mixin requires any component that uses it to include a moderation object
      // which includes assocType and assocId that will be used in moderation mutations.
      throw new Error('Moderation data is not valid');
    }
  },
  methods: {
    checkModeratorPermission(adminOnly = true) {
      const hasPermission = adminOnly ? this.isAdmin : this.isModeratorOrAdmin;
      if (!hasPermission) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Not authorized',
        });
        return false;
      }
      return true;
    },
    openModerationContentFlag() {
      if (!this.checkModeratorPermission()) { return; }
      const path = `content-flag/${this.moderation.assocType}/${this.moderation.assocId}`;
      openInModeration(path);
    },
    moderatorDelete() {
      if (!this.checkModeratorPermission()) { return; }

      this.moderatorOperation('DELETE').then(({ data }) => {
        if (data) {
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Deleted',
          });
          this.navigateByAssocType();
        }
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
    navigateByAssocType() {
      if (this.moderation.assocType === 'THREAD') {
        this.$router.push({ name: 'forums' });
      } else {
        window.setTimeout(() => {
          this.$router.go();
        }, 1000);
      }
    },
    markAsSpam() {
      if (!this.checkModeratorPermission(false)) { return; }

      this.moderatorOperation('SPAM').then(({ data }) => {
        if (data) {
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Marked as spam',
          });
          window.setTimeout(() => {
            this.$router.go();
          }, 1000);
        }
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
    moderatorOperation(operation) {
      return this.$apollo.mutate({
        mutation: MODERATION_CONTENT_FLAG,
        variables: {
          assocId: this.moderation.assocId,
          assocType: this.moderation.assocType,
          operation,
        },
      });
    },
    reportContentOrUser() {
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog');
        return;
      }
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return;
      }

      this.$apollo.mutate({
        mutation: CONTENT_FLAG,
        variables: {
          assocId: this.moderation.assocId,
          assocType: this.moderation.assocType,
        },
      }).then(({ data }) => {
        if (data) {
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Thank you! Our moderators will review.',
          });
        }
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
  },
};
