<template>
  <button-component
    class="button--block button--social-login"
    @click="$emit('click')"
  >
    <icon-component
      name="google"
      class="button__icon"
    />
    {{ buttonText }}
  </button-component>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'GoogleLogin',
  components: {
    ButtonComponent,
  },
  props: {
    buttonTextOverride: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonText() {
      if (this.buttonTextOverride) return this.buttonTextOverride;
      return 'Log in with Google';
    },
  },
};
</script>

<docs>

Button for social login / register with Google

</docs>
