<template>
  <li
    class="nav-item"
    :class="classes"
  >
    <app-link
      class="nav-item__link"
      :to="item.url"
      v-html="item.title"
    />
    <button
      v-if="hasChildren"
      class="nav-item__button"
      :aria-expanded="expanded ? 'true' : 'false'"
      :aria-controls="childrenId"
      @click="toggleExpanded"
    >
      <icon-component
        name="caret"
        :title="`Expand ${item.title} navigation`"
        :class="['nav-item__icon', { 'nav-item__icon--expanded': expanded }]"
      />
    </button>
    <ul
      v-if="hasChildren && expanded"
      :id="childrenId"
      class="nav-item__children"
    >
      <nav-item
        v-for="childItem in item.items"
        :key="`nav-item-${childItem.menuId}`"
        is-child
        :item="childItem"
        @navChange="bubbleNavChange"
      />
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavItem',
  components: {
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters(['expandedNavItems']),
    classes() {
      return {
        'nav-item--child': this.isChild,
        'nav-item--selected': this.isSelected,
      };
    },
    isSelected() {
      return this.item.url === this.$route.path;
    },
    hasChildren() {
      return this.item.items && this.item.items.length > 0;
    },
    childrenId() {
      return `nav-item-children-${this.item.menuId}`;
    },
    containsSelectedItem() {
      if (this.isSelected) return false;

      return this.itemContainsSelectedItem(this.item.items);
    },
  },
  watch: {
    $route(to) {
      if (this.item.url === to.path) {
        this.expanded = true;
        this.navItemSelected();
      }
    },
    expandedNavItems(to) {
      this.expanded = to.includes(this.item.menuId);
    },
  },
  created() {
    if (this.isSelected || this.containsSelectedItem) {
      this.expanded = true;
      this.navItemSelected();
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    navItemSelected() {
      this.$emit('navChange', [this.item.menuId]);
    },
    bubbleNavChange(selectedItems) {
      selectedItems.push(this.item.menuId);
      this.$emit('navChange', selectedItems);
    },
    itemContainsSelectedItem(items) {
      return items && items.some((item) => {
        const itemIsSelected = item.url === this.$route.path;

        if (itemIsSelected) return true;

        return this.itemContainsSelectedItem(item.items);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_nav-item';
</style>
