<template>
  <div v-if="hasAdblock && promptEnabled" class="adblock-prompt">
    <img
      src="/common/nexus/ad-blocker-prompt.png"
      alt=""
      class="adblock-prompt__img"
    >
    <p class="adblock-prompt__copy">
      Showing ads helps us support this community.
      <strong>Your ad blocker is currently on.</strong>
      Consider turning it off for this site.
    </p>
    <button-component
      class="adblock-prompt__button"
      @click="userDisabledAdblock"
    >
      I disabled my ad blocker
    </button-component>
    <a
      class="adblock-prompt__privacy-link"
      href="https://health-union.com/privacy-policy/"
      target="_blank"
      rel="noopener"
    >
      Privacy Policy
    </a>
  </div>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import { isFeatureFlagEnabled } from '@/utils/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'AdblockPrompt',
  components: {
    ButtonComponent,
  },
  computed: {
    ...mapGetters(['hasAdblock']),
    promptEnabled() {
      return isFeatureFlagEnabled(this.$site, 'adblock_prompt');
    },
  },
  methods: {
    userDisabledAdblock() {
      this.$store.dispatch('addToastNotification', {
        toastType: 'success',
        description: 'Thank you for your support! This page will now refresh.',
      });
      window.setTimeout(() => window.location.reload(), 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/components/_adblock-prompt';
</style>

<docs>
This component is supposed to appear if we detect an ad blocker is enabled.
</docs>
