const ForumsIndex = () => import('@/pages/Forum/ForumsIndex.vue');
const ForumThread = () => import('@/pages/Forum/ForumThread.vue');
const CreateThread = () => import('@/pages/Forum/CreateThread.vue');

export default [
  {
    path: '/forums',
    name: 'forums',
    component: ForumsIndex,
  },
  {
    path: '/forums/:slug',
    name: 'forumThread',
    component: ForumThread,
  },
  {
    path: '/create-thread',
    name: 'createThread',
    component: CreateThread,
    meta: { requiresVerify: true, authDialogSettings: { dialogText: 'Create an account to post a forum.' }, UGC: { isUGC: true } },
  },
  { path: '/forum', redirect: { name: 'forums' } },
  { path: '/forum/:tagName', redirect: { name: 'forums' } },
  { path: '/topic/:slug', redirect: { name: 'forumThread' } },
  { path: '/q-and-a', redirect: { name: 'forums' } },
  { path: '/q-and-a/:slug', redirect: { name: 'forumThread' } },
  { path: '/q_and_a', redirect: { name: 'forums' } },
  { path: '/q_and_a/:slug', redirect: { name: 'forumThread' } },
];
