function asyncVersion(config) {
  return `
    <script>
      (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
      window.DD_RUM.onReady(function() {
        window.DD_RUM.init(${config});
      })
    </script>
  `;
}

function syncVersion(config) {
  return `
    <script
        src="https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js"
        type="text/javascript">
    </script>
    <script>
        window.DD_RUM && window.DD_RUM.init(${config});
    </script>
  `;
}

function rumConfig(hostname, sampleRate, replaySampleRate, privacyLevel, datadogEnv, datadogVersion) {
  return `{
    env: '${datadogEnv}',
    applicationId: '6dbb804b-34d3-414a-aab5-e1e04ad60812',
    clientToken: 'puba199aadfc46b4e00b21366c98db15a6b',
    site: 'datadoghq.com',
    service: 'client',
    version: '${datadogVersion}',
    sessionSampleRate: ${sampleRate},
    sessionReplaySampleRate: ${replaySampleRate},
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: '${privacyLevel}',
    allowedTracingUrls: ["https://${hostname}/", /https:\\/\\/${hostname.split('.').join('\\.')}\\/.*/, "http://api.${datadogEnv}.healthunion.local"]
  }`;
}

const parseNumberAndDefaultToZero = (value) => {
  let parsedValue = Number.parseInt(value, 10);

  if (Number.isNaN(parsedValue)) parsedValue = 0;

  return parsedValue;
};

export default (app, hostname, datadogEnv, datadogVersion, inject) => new Promise((resolve) => {
  let rumScript = '';
  const inclusionMode = app.$site.settings.datadog_rum_script_inclusion_mode;
  const sampleRate = parseNumberAndDefaultToZero(app.$site.settings.datadog_sample_rate);
  const replaySampleRate = parseNumberAndDefaultToZero(app.$site.settings.datadog_replay_sample_rate);
  const privacyLevel = app.$site.settings.datadog_privacy_level || 'mask';
  const config = rumConfig(hostname, sampleRate, replaySampleRate, privacyLevel, datadogEnv, datadogVersion);

  if (inclusionMode === 'async') rumScript = asyncVersion(config);
  if (inclusionMode === 'sync') rumScript = syncVersion(config);

  inject(app, '$datadogRum', {
    tags() {
      return rumScript;
    },
  });

  resolve();
});
