<template>
  <div class="all-site-links">
    <h2 class="all-site-links__heading heading--3">OUR COMMUNITIES</h2>
    <ul
      class="all-site-links__list"
      :class="{ 'all-site-links__list--truncated' : showAsTruncatedList }"
    >
      <li
        v-for="site in sitesToDisplay"
        :key="site.name"
        class="all-site-links__site"
      >
        <a
          :href="`https://${site.toLowerCase()}`"
          class="all-site-links__link link link--unstyled"
          rel="noopener"
          target="_blank"
          @focus="showAsTruncatedList = false"
        >
          {{ site }}
        </a>
      </li>
    </ul>
    <button
      class="all-site-links__toggle text-button"
      tabindex="-1"
      @click="handleClick"
    >
      <icon-component
        name="caret"
        title="caret icon"
        class="all-site-links__toggle-icon icon--small"
        :class="{ 'all-site-links__toggle-icon--expanded' : !showAsTruncatedList }"
      />
      {{ toggleButtonText }}
    </button>
  </div>
</template>

<script>
import { footerDomains } from '@/utils/site-domains';

export default {
  name: 'AllSiteLinks',
  data() {
    return {
      showAsTruncatedList: true,
    };
  },
  computed: {
    sitesToDisplay() {
      return footerDomains || [];
    },
    toggleButtonText() {
      return this.showAsTruncatedList ? 'View all' : 'View less';
    },
  },
  methods: {
    handleClick() {
      this.showAsTruncatedList = !this.showAsTruncatedList;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_all-site-links';
</style>

<docs>
This component is used to render links for every site in the global footer

</docs>
