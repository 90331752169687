const PagedPosts = () => import('@/pages/Blog/PagedPosts.vue');
const AuthorIndex = () => import('@/pages/Blog/AuthorIndex.vue');
const CollectionByType = () => import('@/pages/Blog/CollectionByType.vue');
const SingleEngagement = () => import('@/pages/Blog/SingleEngagement.vue');
const LegacySinglePollWrapper = () => import('@/pages/Blog/LegacySinglePollWrapper.vue');
const HomeComponent = () => import('@/pages/HomeComponent.vue');

export default [
  {
    path: '/page/:pageNumber',
    name: 'pagedPosts',
    component: PagedPosts,
  },
  {
    path: '/author/:slug',
    name: 'authorIndex',
    component: AuthorIndex,
  },
  {
    path: '/images',
    name: 'imageGalleries',
    component: CollectionByType,
    meta: {
      type: ['gallery'],
    },
  },
  {
    path: '/sponsored',
    name: 'sponsoredArticles',
    component: CollectionByType,
    meta: {
      type: ['sponsored_article'],
    },
  },
  {
    path: '/featured',
    name: 'contentHubs',
    component: CollectionByType,
    meta: {
      type: ['cas'],
    },
  },
  {
    path: '/homepage/:slug',
    name: 'Home',
    component: HomeComponent,
  },
  {
    path: '/polls',
    name: 'LegacySinglePollWrapper',
    component: LegacySinglePollWrapper,
    beforeEnter(to, from, next) {
      const queryKeys = Object.keys(to.query);
      const rewriteQuery = queryKeys.some((k) => k === 'poll_id');

      if (rewriteQuery && to.query.poll_id) {
        next({ name: 'LegacyPoll', params: { legacyPollId: to.query.poll_id }, query: to.query });
      } else {
        // Redirect to homepage if there's no pollId
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/polls/:legacyPollId',
    name: 'LegacyPoll',
    component: LegacySinglePollWrapper,
    props: true,
  },
  {
    path: '/vote/:engagementType/:engagementId',
    name: 'Engagement',
    component: SingleEngagement,
    props: true,
  },
];
