<template>
  <div class="login-options">
    <social-login />
    <email-login-button @click="goToLoginWithEmail" />
  </div>
</template>

<script>

import SocialLogin from '@/components/Social/SocialLogin.vue';
import EmailLoginButton from '@/components/Auth/EmailLoginButton.vue';

export default {
  name: 'LoginOptions',
  components: {
    SocialLogin,
    EmailLoginButton,
  },
  methods: {
    goToLoginWithEmail() {
      this.$store.dispatch('openLoginWithEmailDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_login-options';
</style>

<docs>
Form for users to pick a login method for new registration flow

</docs>
