<template>
  <button
    :class="classes"
    @click="handleClick"
  >
    <span class="accessibility-option__hidden-text">{{ name }}</span>
    <span class="accessibility-option__letter" aria-hidden="true">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'AccessibilityOption',
  props: {
    name: {
      type: String,
      required: true,
    },
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settingValue: Object.values(this.setting)[0],
    };
  },
  computed: {
    settingActive() {
      return Object.values(this.$store.state.accessibility.settings).includes(this.settingValue);
    },
    classes() {
      const classList = [
        'accessibility-option',
        `accessibility-option--${this.settingValue}`,
      ];
      if (this.settingActive) {
        classList.push('accessibility-option--selected');
      }
      return classList;
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('setAccessibilitySettings', this.setting);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_accessibility-option';
</style>

<docs>
</docs>
