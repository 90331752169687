<template>
  <div
    v-if="message"
    class="validation-error"
  >
    <p
      class="validation-error__message"
      data-test-id="validation-error-message"
    >
      <slot>{{ message }}</slot>
    </p>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'ValidationError',
  props: {
    validator: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checking: false,
      message: '',
    };
  },
  computed: {
    hasError() {
      return this.errors.length > 0;
    },
    validations() {
      return Object.keys(this.validator.$params);
    },
    errors() {
      return this.validations.filter((validation) => !this.validator[validation]);
    },
    validationMessage() {
      const messages = {
        required: 'This field is required',
        email: 'Please enter a valid email address',
        sameAsEmail: 'The email address you entered does not match',
        sameAsPassword: 'The password you entered does not match',
        minValue: `Please enter a value greater than ${this.messageValue}`,
        maxValue: `Please enter a value less than ${this.messageValue}`,
        minLength: `Please enter at least ${this.messageValue} characters`,
        maxLength: `Maximum length of ${this.messageValue} exceeded`,
        alpha: 'Please use alphabetic characters only',
        alphaNum: 'Please use alphabetic or numeric characters only',
        alphaNumHelper: 'Please use alphabetic or numeric characters only',
        containsNumber: 'Please include at least one numeric character',
        passwordRulesHelper: 'Please enter at least 8 characters, including a number',
        notJustNums: 'Passwords cannot be only numbers',
        notJustMention: 'Please include additional text or an image',
        phone: 'Please enter a valid US phone number',
        zipcode: 'Please enter a valid US zipcode',
        socialSites: 'Please enter at least one social media handle',
        noDuplicateSocials: 'Please remove duplicate entries',
        twitterHandle: 'Your username must be more than 4 characters long and can be up to 15 characters or less',
        instagramHandle: 'Your username cannot exceed 30 characters',
        url: 'Please check that your url is valid',
      };

      return messages[this.errors[0]] || 'something went wrong';
    },
    messageValue() {
      const errorValues = {
        minValue: this.validator.$params.minValue ? this.validator.$params.minValue.min : '',
        maxValue: this.validator.$params.maxValue ? this.validator.$params.maxValue.max : '',
        minLength: this.validator.$params.minLength ? this.validator.$params.minLength.min : '',
        maxLength: this.validator.$params.maxLength ? this.validator.$params.maxLength.max : '',
      };

      return errorValues[this.errors[0]] || '';
    },
  },
  watch: {
    model: debounce(function modelWatcher() {
      if (!this.validations.includes('notJustMention')) {
        this.checkErrors();
      }
    }, 500),
    hasError: debounce(function modelWatcher() {
      if (!this.validations.includes('notJustMention')) {
        this.checkErrors();
      }
    }, 500),
  },
  methods: {
    checkErrors() {
      this.setMessage();
    },
    resetErrors() {
      if (this.checking) {
        clearTimeout(this.checking);
      }
      this.message = '';
    },
    setMessage() {
      if (this.hasError) {
        this.message = this.validationMessage;
      } else {
        this.message = '';
      }
      this.checking = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_validation-error';
</style>

<style lang="scss"> // no scoped - for styling container element (see below comment block)
/**
 * All validated inputs and ValidationError components must be wrapped in a div with
 * .validation-group to prevent loss of focus when validation states change.
 * Bug report: https://github.com/vuejs/vue/issues/6929
 */
  @import '@/stylesheets/components/_validation-group';
</style>

<docs>
This component is used alongside vuelidate to display stylized error messages when
A field does not meet validation rules.

The ValidationError component should be displayed *above* the field it is validating against.

The model prop expects the model vuelidate is watching, you can either pass this via
Vuelidate's $v.fieldname.$model or directly from your data.

The validator prop expects an object from vuelidate, typically $v.fieldname, see the example below.

Default Slot can be used for custom messaging.

Multi-line messages supported.

</docs>
