function wrapAreaCodeInParens(phoneNumber) {
  if (!phoneNumber) return '';
  let newValue = phoneNumber;

  if (newValue.length >= 6) newValue = `${newValue.slice(0, 3)}(${newValue.slice(3, 6)})${newValue.slice(6)}`;

  return newValue;
}

function insertHyphenIntoPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  let newValue = phoneNumber;

  if (newValue.length > 8) newValue = `${newValue.slice(0, 8)}-${newValue.slice(8)}`;
  if (newValue.length > 12) newValue = `${newValue.slice(0, 12)}-${newValue.slice(12)}`;

  return newValue;
}

function restrictPhoneNumberLength(phoneNumber) {
  if (!phoneNumber) return '';
  let newValue = phoneNumber;

  if (newValue.length > 17) newValue = newValue.slice(0, 17);

  return newValue;
}

function clearCountryCodeAndNonNumerics(phoneNumber) {
  if (!phoneNumber) return '';
  const numbersOnly = /\D/g;
  const countryCode = '+1 ';
  let newValue = phoneNumber;

  if (newValue.slice(0, 3) === countryCode) newValue = newValue.slice(3);
  if (newValue.slice(0, 2) === countryCode.slice(0, 2)) newValue = newValue.slice(2);
  newValue = newValue.replace(numbersOnly, '');

  return newValue;
}

function formatPhoneNumber(phoneNumber) {
  const countryCode = '+1 ';
  let newValue = clearCountryCodeAndNonNumerics(phoneNumber);

  newValue = `${countryCode}${newValue}`;
  newValue = wrapAreaCodeInParens(newValue);
  newValue = insertHyphenIntoPhoneNumber(newValue);
  newValue = restrictPhoneNumberLength(newValue);

  return newValue;
}

function validatePhoneNumber(phoneNumber) {
  return !phoneNumber || clearCountryCodeAndNonNumerics(phoneNumber).length === 10;
}

function phoneInputHandler(value, event) {
  if (event?.inputType?.includes('delete')) return value;

  return formatPhoneNumber(value);
}

export {
  clearCountryCodeAndNonNumerics,
  formatPhoneNumber,
  validatePhoneNumber,
  phoneInputHandler,
};
