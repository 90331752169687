<template>
  <div v-if="recommend.length > 0" class="recommendation">
    <div
      class="recommendation__post-teasers"
    >
      <div
        v-for="post in recommend"
        :key="`post-${post.id}`"
        class="recommendation__post-teaser"
      >
        <recommendation-post-teaser
          :post="post"
          :suppress-type="suppressType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RecommendationPostTeaser from '@/components/Posts/RecommendationPostTeaser.vue';
import { GET_RECOMMENDATIONS, GET_RECENTS_AS_RECOMMENDATIONS } from '@/graphql/queries/recommendation-queries';

export default {
  name: 'RecommendationComponent',
  components: {
    RecommendationPostTeaser,
  },
  props: {
    suppressType: {
      type: Boolean,
      default: false,
    },
    useRecents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recommend: [],
    };
  },
  apollo: {
    recommend: {
      query: GET_RECOMMENDATIONS,
      prefetch: false,
      variables() {
        return {
          assocId: this.$store.state.post.id,
          assocType: 'POST',
          docResultType: 'POST',
          limit: 2,
        };
      },
      result({ data }) {
        if (data && data.recommend) {
          this.$store.dispatch('updateGlobalContext', {
            recommendedContent: {
              location: 'Content',
              ids: data.recommend.map((rec) => rec.id),
            },
          });
        }
      },
      skip() { return this.useRecents; },
    },
    recentsAsRecommendations: {
      query: GET_RECENTS_AS_RECOMMENDATIONS,
      prefetch: false,
      result({ data }) {
        if (!data || !data.recentsAsRecommendations) return;

        this.recommend = data.recentsAsRecommendations;
      },
      skip() { return !this.useRecents; },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_recommendation';
</style>
