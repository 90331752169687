<template>
  <div :class="classNames">
    <label
      v-if="!excludeLabel"
      class="text-input__label heading--4"
      :for="id"
    >
      <!-- @slot Label contents -->
      <slot />
    </label>
    <div
      class="text-input__input-wrapper"
      :class="{ 'text-input__input-wrapper--mock-input' : inlinePrefix }"
    >
      <span v-if="inlinePrefix" class="text-input__inline-prefix">
        {{ inlinePrefix }}
      </span>
      <input
        :id="id"
        ref="input"
        class="text-input__input"
        :type="inputType"
        :value="value"
        v-bind="$attrs"
        @input.prevent="emitInputEvent"
        @keypress="emitKeypressEvent"
        @focus="emitFocusEvent"
        @blur="emitBlurEvent"
      >
      <icon-component
        v-if="includeIcon"
        class="text-input__icon"
        name="pencil"
        title="Editable field"
      />
      <div v-if="$slots.inlineButton" class="text-input__inline-button">
        <slot name="inlineButton" />
      </div>
    </div>
  </div>
</template>

<script>
const allowedTypes = ['text', 'email', 'password', 'tel', 'url'];

export default {
  name: 'TextInput',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    /**
     * Input type can be text, email, password, tel, url.
     */
    inputType: {
      type: String,
      default: 'text',
      validator: (value) => allowedTypes.includes(value),
    },
    emitKeypress: {
      type: Boolean,
      default: false,
    },
    excludeLabel: {
      type: Boolean,
      default: false,
    },
    includeIcon: {
      type: Boolean,
      default: false,
    },
    inlinePrefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    classNames() {
      return {
        'text-input': true,
        'text-input--has-button': !!this.$slots.inlineButton,
      };
    },
  },
  methods: {
    emitFocusEvent() {
      this.$emit('focus');
    },
    emitBlurEvent() {
      this.$emit('blur');
    },
    emitInputEvent(event) {
      this.$emit('input', event.target.value, event);
    },
    emitKeypressEvent(event) {
      if (!this.emitKeypress) {
        return;
      }
      this.$emit('keypress', event);
    },
  },
};
</script>

<docs>

For a full width text input, use the `text-input--full-width` class.

</docs>
