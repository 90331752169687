<template>
  <div class="subscription-wall">
    <div class="subscription-wall__prompt">
      <div
        class="subscription-wall__image"
        v-html="require(`../../assets/subscription-wall-lock.svg`)"
      />
      <lead-gen
        id="subscription-wall-input"
        class="subscription-wall__form"
        data-test-id="subscription-wall-form"
        :show-logo="false"
        campaign="subscription wall"
        form="subscription wall"
      >
        <label
          class="subscription-wall__label heading heading--3"
          data-test-id="subscription-wall-input-label"
          for="subscription-wall-input"
        >
          Subscribe or
          <button type="button" class="text-button text-button--link-style" @click="openAuthDialog">log in</button>
          to get full access to the community.
        </label>
      </lead-gen>
    </div>
  </div>
</template>

<script>
import LeadGen from '@/components/Global/LeadGen.vue';
import { mapActions } from 'vuex';

export default {
  name: 'SubscriptionWall',
  components: {
    LeadGen,
  },
  methods: {
    ...mapActions(['openAuthDialog']),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_subscription-wall';
</style>
