<template>
  <div class="new-message">
    <form-component
      class="new-message__form"
      @submit="sendMessage"
    >
      <textarea-component
        id="new-message-input"
        ref="messageInput"
        v-model="body"
        emit-keypress
        placeholder="Write a reply..."
        autocomplete="off"
        :disabled="$attrs.disabled || sendingMessage"
        @keypress="handleKeypress"
        @focus="handleInputFocus"
      />
      <send-message-button
        :disabled="body.length <= 0"
        @click="sendMessage"
      />
    </form-component>
  </div>
</template>

<script>
import TextareaComponent from '@/components/Inputs/TextareaComponent.vue';
import SendMessageButton from '@/components/PrivateMessaging/SendMessageButton.vue';
import { SEND_MESSAGE } from '@/graphql/mutations/private-messaging-mutations';
import { mapGetters } from 'vuex';

export default {
  name: 'NewMessage',
  components: {
    TextareaComponent,
    SendMessageButton,
  },
  props: {
    conversationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      body: '',
      sendingMessage: false,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
  },
  methods: {
    autoExpand(textBox) {
      // the scrollHeight plus 2 pixels to ensure no scroll bar is present
      const expandedHeight = textBox.scrollHeight + 2;

      if (expandedHeight > textBox.offsetHeight) {
        // Reset textBox height to avoid expanding with each new character
        // eslint-disable-next-line no-param-reassign
        textBox.style.height = '0px';

        // eslint-disable-next-line no-param-reassign
        textBox.style.height = `${expandedHeight}px`;
      }
    },
    handleInputFocus() {
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
      }
    },
    handleKeypress(event) {
      const RETURN = 13;
      if (event.keyCode === RETURN) {
        event.preventDefault();
        this.sendMessage();
      }
      this.autoExpand(event.target);
    },
    resetForm() {
      this.body = '';
      // Reset textbox height to follow the max-height set with css
      // eslint-disable-next-line no-param-reassign
      this.$refs.messageInput.$refs.input.style.height = '0px';
    },
    sendMessage() {
      this.sendingMessage = true;

      if (this.body.length <= 0) {
        this.sendingMessage = false;
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Please write a message to send',
          type: 'Messaging',
        });
        return;
      }

      this.$apollo.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          conversationId: this.conversationId,
          body: this.body,
        },
      })
        .then(() => {
          this.resetForm();
        })
        .catch(() => {
          this.$store.dispatch('addGenericErrorNotification');
        }).finally(() => {
          this.sendingMessage = false;
          this.$nextTick(() => {
            this.$refs.messageInput.$refs.input.focus();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_new-message';
</style>

<docs>
</docs>
