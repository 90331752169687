const RecipesIndex = () => import('@/pages/Recipes/RecipesIndex.vue');
const PostComponent = () => import('@/pages/Blog/PostComponent.vue');
const CreateRecipe = () => import('@/pages/Recipes/CreateRecipe.vue');

export default [
  {
    path: '/recipes',
    name: 'recipes',
    component: RecipesIndex,
  },
  {
    path: '/recipes/new',
    name: 'createRecipe',
    component: CreateRecipe,
    meta: { requiresVerify: true, authDialogSettings: { dialogText: 'Ask questions. Stay up on the latest news. And so much more. Create your free account now.' }, UGC: { isUGC: true } },
  },
  {
    path: '/recipes/:slug',
    name: 'recipe',
    component: PostComponent,
    meta: { type: 'recipe', types: ['recipe'] },
  },
];
