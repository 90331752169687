<template>
  <transition
    appear
    name="toast-notifications"
  >
    <div class="toast-notifications">
      <toast-notification
        v-for="notification in this.$store.getters.toastNotifications"
        :key="notification.id"
        :notification="notification"
      />
    </div>
  </transition>
</template>

<script>
import ToastNotification from '@/components/ToastNotifications/ToastNotification.vue';

export default {
  name: 'ToastNotifications',
  components: {
    ToastNotification,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_toast-notifications';
</style>
