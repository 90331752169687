function organizationSchema(hostName, siteData) {
  return {
    '@type': 'Organization',
    '@id': `https://${hostName}#organization`,
    name: siteData.name,
    url: `https://${hostName}`,
    // Array of social domains, filter removes empty strings
    sameAs: siteData.settings.social ? Object.values(siteData.settings.social).filter((social) => social) : [],
    logo: {
      '@type': 'ImageObject',
      '@id': `https://${hostName}#logo`,
      url: `https://${hostName}/images/logo_avatar.jpg`,
      width: 1024,
      height: 1024,
      caption: siteData.name,
    },
    image: {
      '@id': `https://${hostName}#logo`,
    },
  };
}

function websiteSchema(hostName, siteName) {
  return {
    '@type': 'WebSite',
    '@id': `https://${hostName}#website`,
    url: `https://${hostName}`,
    name: siteName,
    publisher: {
      '@id': `https://${hostName}#organization`,
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: `https://${hostName}/search?s={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };
}

function articleBodyContent(post) {
  const noPostContent = !post || Object.keys(post).length <= 0 || post.content === 'null';
  if (noPostContent) { return ''; }
  const contentSections = JSON.parse(post.content).sections;
  if (!contentSections) { return ''; }

  const articleContent = contentSections.map((s) => {
    if (s.type === 'HTML') {
      return s.options.content;
    }
    if (s.type === 'Feature' && s.options.posts.length > 0) {
      return s.options.posts[0].content;
    }
    if (s.type === 'Columns') {
      const column = s.options.columns[0];
      if (!column) { return ''; }
      return column.map((c) => c.content);
    }
    return '';
  });
    // We are stripping out all the html tags so that we don't break the script tags
  return articleContent.join(' ').replace(/(<([^>]+)>)/ig, '');
}

function webpageSchema(content, route, hostName, siteName, opts = {}) {
  if (!content || Object.keys(content).length <= 0) { return {}; }

  const webPageSchemaObject = {
    '@type': 'WebPage',
    '@id': `https://${hostName}${route}#webpage`,
    url: `https://${hostName}${route}`,
    inLanguage: 'en-US',
    name: content.title || content.name || '',
    isPartOf: {
      '@id': `https://${hostName}#website`,
    },
    primaryImageOfPage: {
      '@id': `https://${hostName}${route}#primaryimage`,
    },
    datePublished: content.datePublished,
    dateModified: content.dateModified || content.datePublished,
    audience: {
      '@type': 'Audience',
      name: `People affected by or interested in ${siteName}`,
    },
  };

  if (opts.hasAuthContent) {
    webPageSchemaObject.isAccessibleForFree = 'False';
    webPageSchemaObject.hasPart = {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: '.content__auth-content-mask, .content__subscription-required',
    };
  }

  return webPageSchemaObject;
}

function authorSchema(author, hostName) {
  if (!author || Object.keys(author).length <= 0) { return {}; }

  return {
    '@type': 'Person',
    '@id': `https://${hostName}#/schema/person/${author.id}`,
    name: author ? author.username : 'Editorial Team',
    image: {
      '@type': 'ImageObject',
      '@id': `https://${hostName}#authorlogo`,
      url: author && author.avatar ? author.avatar.full : null,
      caption: author ? author.username : 'Editorial Team',
    },
  };
}

function authorCollectionSchema(author, count, route, hostName) {
  if (!author || Object.keys(author).length <= 0) { return {}; }

  return {
    '@type': 'Collection',
    headline: 'Author Index',
    description: `A list of posts or articles written by ${author.username}`,
    url: route,
    image: '/images/logo_avatar@48x48.png',
    collectionSize: count || 0,
    author: {
      '@id': `https://${hostName}#/schema/person/${author.id}`,
    },
    publisher: {
      '@id': `https://${hostName}#organization`,
    },
  };
}

function postSchema(post, route, hostName) {
  if (!post || Object.keys(post).length <= 0) { return {}; }

  // keywords matches the Yoast implementation, but might not be the best option?
  // post.seo.metakeywords might be better.
  const keywords = post.tags ? post.tags.map((tag) => tag.name).join(',') : '';
  const articleSection = post.categories ? post.categories.map((category) => category.name).join(' ') : '';

  const blogPosting = {
    '@type': 'BlogPosting',
    headline: post.title,
    datePublished: post.date,
    dateModified: post.modified || post.date,
    description: post.seo.metadesc,
    url: route,
    mainEntityOfPage: 'True',
    isPartOf: {
      '@id': `https://${hostName}#webpage`,
    },
    image: {
      '@id': `${route}#primaryimage`,
    },
    publisher: {
      '@id': `https://${hostName}#organization`,
    },
    articleBody: articleBodyContent(post),
    keywords,
    articleSection,
  };

  if (post.author) {
    blogPosting.author = {
      '@id': `https://${hostName}#/schema/person/${post.author.id}`,
    };
  }

  return blogPosting;
}

function recipeSchema(post, route, hostName) {
  if (!post || Object.keys(post).length <= 0) { return {}; }

  const recipe = {
    '@type': 'Recipe',
    name: post.title,
    datePublished: post.date,
    dateModified: post.modified || post.date,
    isPartOf: {
      '@id': `https://${hostName}#webpage`,
    },
    image: {
      '@id': `${route}#primaryimage`,
    },
    description: post.seo.metadesc || `A recipe for ${post.title}`,
    nutrition: {
      '@type': 'NutritionInformation',
      calories: `${post.nutrition.calories} calories per serving`,
      carbohydrateContent: `${post.nutrition.carbohydrate} of carbohydrates per serving`,
      fatContent: `${post.nutrition.fat} of fat per serving`,
      proteinContent: `${post.nutrition.protein} of protein per serving`,
    },
  };

  if (post.author) {
    recipe.author = {
      '@id': `https://${hostName}#/schema/person/${post.author.id}`,
    };
  }

  return recipe;
}

function threadSchema(thread, route, hostName) {
  if (!thread || Object.keys(thread).length <= 0) { return {}; }

  const headline = thread.seo && thread.seo.title ? thread.seo.title : thread.name;
  const tagNames = thread.tags ? thread.tags.map((t) => t.name) : [];
  const keywords = thread.seo && thread.seo.metakeywords ? thread.seo.metakeywords : tagNames;
  const url = thread.seo && thread.seo.canonical ? thread.seo.canonical : route;

  const article = {
    '@type': 'Article',
    headline,
    articleBody: thread.body,
    dateModified: thread.insertedAt,
    dateCreated: thread.insertedAt,
    datePublished: thread.insertedAt,
    commentCount: thread.replies ? thread.replies.length : 0,
    keywords,
    url,
    image: {
      '@id': `${route}#primaryimage`,
    },
    mainEntityOfPage: 'True',
    isPartOf: {
      '@id': `https://${hostName}#webpage`,
    },
  };

  if (thread.user && thread.user.username) {
    article.author = {
      '@type': 'Person',
      name: thread.user.username,
    };
  }

  return article;
}

function imageSchema(post, route, hostName) {
  const imageForSchema = {
    url: post.featuredMedia.length ? post.featuredMedia[0].url : '/images/logo_avatar@48x48.png',
    alt: post.featuredMedia.length ? post.featuredMedia[0].alt : `Image for ${post.title}`,
  };
  return {
    '@type': 'ImageObject',
    '@id': `https://${hostName}${route}#primaryimage`,
    url: imageForSchema.url,
    width: 1468, // TODO: expose this data in the API and use it here (and elsewhere)
    height: 560,
    caption: imageForSchema.alt,
  };
}

function collectionSchema(title, count, route, hostName, siteName) {
  let type = 'posts';

  switch (route) {
    case '/forums':
      type = 'threads';
      break;
    case '/stories':
      type = 'stories';
      break;
    case '/recipes':
      type = 'recipes';
      break;
    default:
      break;
  }

  return {
    '@type': 'Collection',
    headline: title,
    url: route,
    description: `A collection of ${type} written by different members of ${siteName}`,
    image: '/images/logo_avatar@48x48.png',
    collectionSize: count,
    isPartOf: {
      '@id': `https://${hostName}#webpage`,
    },
  };
}

function breadcrumbSchema(post, hostName) {
  if (!post) { return []; }
  let categories = [];

  switch (post.type) {
    case 'recipe':
      categories = [{ slug: 'recipes', name: 'Recipes' }];
      break;
    case 'story':
      categories = [{ slug: 'stories', name: 'Stories' }];
      break;
    default:
      categories = post.categories || [];
      break;
  }

  return categories.map((c) => (
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem', position: 1, item: `https://${hostName}/${c.slug}`, name: c.name,
        },
        {
          '@type': 'ListItem', position: 2, name: post.title,
        },
      ],
    }
  ));
}

function siteJsonLd(hostName, siteData) {
  if (!siteData || Object.keys(siteData).length <= 0) { return {}; }

  const schema = [organizationSchema(hostName, siteData)];

  return schema;
}

function homeJsonLd(homepage, hostName, siteName) {
  if (!homepage || Object.keys(homepage).length <= 0) { return {}; }

  return [
    // WebSite Schema object has been moved from siteJsonLd() to
    // homeJsonLd() to conform to Google's searchbox requirements.
    websiteSchema(hostName, siteName),
    webpageSchema(homepage, '/', hostName, siteName),
  ];
}

function postJsonLd(post, route, hostName, siteName, opts) {
  if (!post) { return []; }
  return [
    webpageSchema(post, route, hostName, siteName, opts),
    post.type === 'recipe' ? recipeSchema(post, route, hostName) : postSchema(post, route, hostName),
    imageSchema(post, route, hostName),
    authorSchema(post.author, hostName),
  ].concat(breadcrumbSchema(post, hostName));
}

function threadJsonLd(thread, route, hostName, siteName) {
  if (!thread) { return []; }
  return [
    webpageSchema(thread, route, hostName, siteName),
    threadSchema(thread, route, hostName),
    authorSchema(thread.author, hostName),
  ];
}

function collectionJsonLd(title, count, route, hostName, siteName) {
  return [
    webpageSchema({}, route, hostName, siteName),
    collectionSchema(title, count, route, hostName, siteName),
  ];
}

function authorCollectionJsonLd(author, count, route, hostName, siteName) {
  if (!author) { return []; }
  return [
    webpageSchema({}, route, hostName, siteName),
    authorCollectionSchema(author, count, route, hostName),
    authorSchema(author, hostName),
  ];
}

export {
  siteJsonLd,
  homeJsonLd,
  postJsonLd,
  threadJsonLd,
  collectionJsonLd,
  authorCollectionJsonLd,
};
