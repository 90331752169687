<template>
  <p class="disclaimer-text">
    By providing your email address, you are agreeing to our
    <a href="https://health-union.com/privacy-policy/" target="_blank" rel="noopener">privacy policy</a>
    and <a href="https://health-union.com/terms-of-use/" target="_blank" rel="noopener">terms of use</a>.
    This site is protected by reCAPTCHA and the Google
    <a
      class="auth-dialog__disclaimer-link"
      href="https://policies.google.com/privacy"
    >Privacy Policy</a>
    and
    <a
      class="auth-dialog__disclaimer-link"
      href="https://policies.google.com/terms"
    >Terms of Service</a>
    apply.
  </p>
</template>

<script>
export default {
  name: 'DisclaimerComponentWithRecaptcha',
};
</script>

<docs>
Disclaimer for Auth Pages

</docs>
