import { STATUS_REPLY_FRAGMENT } from '@/graphql/fragments/user-fragments';
import gql from 'graphql-tag';

export const GET_USER_SUMMARY_QUERY = gql`
query user($id: NexusId, $slug: String, $originalSite: String) {
  user(id: $id, slug: $slug, originalSite: $originalSite) {
    id
    badge
    secondaryBadge
    username
    slug
    avatar {
      thumb
      full
    }
    roleId
    legacyRole
    description
    isFollowed
    site {
      name
    }
    socialSites {
      id
      type
      url
    }
  }
}
`;

export const GET_USER_PROFILE_QUERY = gql`
${STATUS_REPLY_FRAGMENT}
query user($slug: String) {
  user(slug: $slug) {
    id
    badge
    secondaryBadge
    username
    firstName
    lastName
    slug
    avatar {
      thumb
      full
    }
    roleId
    legacyRole
    insertedAt
    isFollowed
    bio
    profileStatus {
      id
      body
      insertedAt
      replies {
        ...StatusReplyFragment
      }
    }
    socialSites {
      id
      type
      url
    }
  }
}
`;

export const GET_EDIT_USER_QUERY = gql`
query user($slug: String) {
  user(slug: $slug) {
    id
    username
    slug
    avatar {
      thumb
      full
    }
    email
    isUpdatingEmail
    receiveEmails
    authAccounts {
      id
      provider
    }
    socialSites {
      id
      type
      url
    }
  }
}
`;

export const GET_USER_STATUSES_QUERY = gql`
${STATUS_REPLY_FRAGMENT}
query userProfileStatusHistory($userSlug: String, $limit: Int) {
  userProfileStatusHistory(slug: $userSlug, limit: $limit) {
    id
    body
    insertedAt
    user {
      username
      id
    }
    replies {
      ...StatusReplyFragment
    }
  }
}
`;

export const GET_SINGLE_USER_STATUS_QUERY = gql`
${STATUS_REPLY_FRAGMENT}
query userProfileStatus($id: NexusId!, $slug: String) {
  userProfileStatus(id: $id, slug: $slug) {
    id
    body
    insertedAt
    user {
      username
      id
    }
    replies {
      ...StatusReplyFragment
    }
  }
}
`;

// Query against cache when updating following REPLY_TO_USER_STATUS mutation
export const STATUS_REPLY_UPDATE_QUERY = gql`
${STATUS_REPLY_FRAGMENT}
query userProfileStatusHistory($userSlug: String, $limit: Int) {
  userProfileStatusHistory(slug: $userSlug, limit: $limit) {
    id
    user {
      username
      id
    }
    replies {
      ...StatusReplyFragment
    }
  }
}
`;

export const USER_SEARCH_QUERY = gql`
query users($text: String, $type: Mentionable, $id: NexusId, $adminsOnly: Boolean) {
  userSearch(username: $text, type: $type, id: $id, adminsOnly: $adminsOnly) {
    id
    username
    slug
    avatar {
      thumb
    }
  }
}
`;

export const PUBLIC_PROFILE_QUERY = gql`
query publicProfile($userId: NexusId) {
  publicProfile(userId: $userId) {
    id
    value
    profileField {
      icon
    }
  }
}
`;

export const UNREAD_COUNTS = gql`
query unreads {
  unreads {
    messages
    notifications
  }
}
`;

export const USER_DISORDERS = gql`
query userDisorders($userId: NexusId!) {
  userDisorders(userId: $userId) {
    id
    relationshipType
    disorder {
      id
      name
    }
  }
}
`;

export const USER_RECOMMENDATIONS_QUERY = gql`
query userRecommendations($limit: Int, $cacheKey: String) {
  userRecommendations(limit: $limit, cacheKey: $cacheKey) {
    id
    username
    roleId
    avatar {
      thumb
      full
    }
    shortBio
  }
}
`;

export const USER_AWARDS_QUERY = gql`
query userAwards($userId: NexusId!) {
  userAwards(userId: $userId) {
    id
    status
    earnedAt
    award {
      id
      name
      year
    }
  }
}
`;

export const USER_PRIVACY_SETTINGS_QUERY = gql`
query userPrivacySettings($userId: NexusId!, $name: String) {
  userPrivacySettings(userId: $userId, name: $name) {
    id
    name
    isPrivate
  }
}
`;

export const USER_SOCIAL_SITES_QUERY = gql`
query userSocialSites($userId: NexusId!) {
  userSocialSites(userId: $userId) {
    id
    type
    url
  }
}
`;
