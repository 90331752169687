<template>
  <div
    class="single-message"
    :class="messageClasses"
  >
    <user-avatar v-if="!fromCurrentUser" :user="user" class="single-message__avatar" />
    <div class="single-message__bubble">
      <p class="single-message__body" v-html="message.body" />
      <div v-if="!fromCurrentUser" class="single-message__reaction-menu">
        <reaction-menu
          :id="message.id"
          content-type="message"
          :menu-direction-override="openReactionsAbove ? 'above' : ''"
        />
      </div>
      <div
        v-if="fromCurrentUser && hasReactions"
        class="single-message__sent-reaction"
      >
        <reaction-button
          :reaction-count="1"
          :selected-reaction="message.reactions[0].type"
          content-type="message"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ReactionMenu from '@/components/Reactions/ReactionMenu.vue';
import ReactionButton from '@/components/Reactions/ReactionButton.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';

export default {
  name: 'SingleMessage',
  components: {
    ReactionMenu,
    ReactionButton,
    UserAvatar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    openReactionsAbove: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    messageClasses() {
      return {
        'single-message--sent': this.fromCurrentUser,
        'single-message--sent-reacted': this.fromCurrentUser && this.hasReactions,
      };
    },
    fromCurrentUser() {
      return this.message.userId === this.currentUser.id;
    },
    hasReactions() {
      return this.message.reactions.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_single-message';
</style>

<docs>
</docs>
