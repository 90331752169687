import gql from 'graphql-tag';

export const GENERATE_LEAD = gql`
mutation generateLead($campaign: String!, $email: String!, $decoyEmail: String, $form: String!, $pageviewId: String!, $medium: String, $contentType: String) {
  generateLead(campaign: $campaign, email: $email, decoyEmail: $decoyEmail, form: $form, pageviewId: $pageviewId, medium: $medium, contentType: $contentType) {
    discardReason
    user {
      id
    }
  }
}
`;
