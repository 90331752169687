const StoriesIndex = () => import('@/pages/Stories/StoriesIndex.vue');
const PostComponent = () => import('@/pages/Blog/PostComponent.vue');
const CreateStory = () => import('@/pages/Stories/CreateStory.vue');

export default [
  {
    path: '/stories',
    name: 'stories',
    component: StoriesIndex,
  },
  {
    path: '/stories/new',
    name: 'createStory',
    component: CreateStory,
    meta: { requiresVerify: true, authDialogSettings: { dialogText: 'Create an account to share your story.' }, UGC: { isUGC: true } },
  },
  {
    path: '/stories/:slug',
    name: 'story',
    component: PostComponent,
    meta: { type: 'story', types: ['story'] },
  },
  { path: '/story/:slug', redirect: { name: 'story' } },
];
