<template>
  <vue-recaptcha
    ref="recaptcha"
    :sitekey="$site.recaptchaKey"
    size="invisible"
    badge="none"
    :load-recaptcha-script="true"
    @expired="onCaptchaExpired"
    @verify="onCaptchaVerify"
    @error="onCatptchaError"
  />
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'RecaptchaComponent',
  components: {
    VueRecaptcha,
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerify(recaptchaToken) {
      this.$emit('verify', recaptchaToken);
    },
    onCatptchaError() {
      this.$logger.error('error in recaptcha');
    },
    execute() {
      this.$snowplow.trackRecaptcha({
        data: {
          // Could be 'token refresh', but our implementation doesn't appear to use refresh tokens.
          recaptcha_type: 'token',
        },
      });
      if (process.env.VUE_APP_TEST === 'true') {
        this.$emit('verify', 'dummyRecaptchaToken');
      }
      this.$refs.recaptcha.execute();
    },
  },
};
</script>
