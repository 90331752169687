<template>
  <form-component
    class="image-upload-form"
    @submit.prevent="handleSubmit"
  >
    <p
      v-if="imageType !== 'avatar'"
      class="image-upload-form__text"
    >
      Your image will be reviewed before it’s displayed.
    </p>
    <div class="image-upload-form__file-upload">
      <label for="images" class="image-upload-form__label">Select an image:</label>
      <input
        id="images"
        type="file"
        name="images"
        class="image-upload-form__input"
        accept="image/png,image/gif,image/jpeg,image/jpg"
        @change="processFile"
      >
      <span v-if="images.length > 0" class="image-upload-form__preview">{{ images[0].name }}</span>
    </div>
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.altText.$invalid }"
    >
      <validation-error
        ref="altText"
        :model="altText"
        :validator="$v.altText"
      />
      <text-input
        v-if="imageType !== 'avatar'"
        id="imageUploadAlt"
        v-model="altText"
        class="text-input--full-width"
      >Describe your image (optional):</text-input>
    </div>
    <p class="disclaimer-text">
      By clicking upload, you acknowledge that your post complies with our
      <a
        href="https://health-union.com/terms-of-use/"
        target="_blank"
        rel="noopener"
      >Terms of Use</a> and
      <a
        href="https://health-union.com/community-rules/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >Community Rules</a>. {{ $site.name }} reserves the right to not publish submitted images.
    </p>
    <div class="align-center">
      <loading-button
        type="submit"
        :loading="uploading"
        button-type="upload image"
      >
        Upload
      </loading-button>
    </div>
  </form-component>
</template>

<script>
import TextInput from '@/components/Inputs/TextInput.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import ValidationError from '@/components/Global/ValidationError.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { apiPath } from '@/utils/host-helpers';
import { helpers } from 'vuelidate/lib/validators';

const alphaNumHelper = helpers.regex('alphaNumHelper', /^[a-z\d.\-_(),& ]*$/i);

export default {
  name: 'ImageUploadForm',
  components: {
    TextInput,
    LoadingButton,
    ValidationError,
  },
  data() {
    return {
      uploading: false,
      images: [],
      altText: '',
    };
  },
  validations: {
    altText: {
      alphaNumHelper,
    },
  },
  computed: {
    ...mapGetters(['mentionBoxID', 'imageType']),
  },
  methods: {
    processFile(event) {
      this.images = event.target.files;
    },
    uploadImage() {
      // TODO: Absinthe 1.5
      // Implement this as a graphQL mutation, we were unable to get apollo and
      // absinthe to play nicely together, so using axios for now.
      const data = new FormData();
      let i = 0;

      for (i = 0; i < this.images.length; i += 1) {
        const file = this.images.item(i);
        data.append(`images[${i}]`, file, file.name);
      }
      data.append('alt', this.altText);
      data.append('image_type', this.imageType);

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        withCredentials: true,
      };

      const endpoint = `${apiPath(this.$root.$options.context)}/api/upload-image`;
      return axios.post(endpoint, data, config);
    },
    handleSubmit() {
      this.$refs.altText.checkErrors();
      if (this.$v.$invalid) {
        this.uploading = false;
        return;
      }
      const type = this.imageType;
      if (this.images.length === 0) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Please select an image to upload',
        });
        return;
      }
      if (this.images.length && this.images[0].size > 4000000) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Images must be 4 MB or smaller.  Please try again.',
        });
        return;
      }
      this.uploading = true;
      this.uploadImage()
        .then((response) => {
          let image = {};
          if (type === 'avatar') {
            image = response.data.images.full;
          } else {
            image = response.data.images.medium;
          }
          const payload = { ...image, ...{ mentionBoxId: this.mentionBoxID } };
          this.$store.dispatch('addUploadedImage', payload);
        })
        .catch(() => {
          this.$store.dispatch('addGenericErrorNotification');
        }).finally(() => {
          this.uploading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_image-upload-form';
</style>

<docs>
</docs>
