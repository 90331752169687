<template>
  <fieldset class="composite-input">
    <legend class="visually-hidden">Verification Code</legend>
    <div
      v-for="digitNumber in numberOfDigits"
      :key="`digit_${digitNumber}`"
      class="composite-input__input-wrapper"
    >
      <label
        class="composite-input__input-label visually-hidden"
        :for="`digit_${digitNumber}`"
      >Verification Code Digit {{ digitNumber }}</label>
      <input
        :id="`digit_${digitNumber}`"
        :ref="`digit_${digitNumber}`"
        v-model="digits[digitNumber-1]"
        type="text"
        pattern="\d*"
        :name="`digit_${digitNumber}`"
        :disabled="disabled"
        :class="`composite-input__input text-input`"
        @focus="handleFocus(digitNumber, $event)"
        @keydown="handleKeyDown(digitNumber, $event)"
        @input="handleInput(digitNumber, $event)"
      >
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'CompositeInput',
  components: {},
  props: {
    numberOfDigits: {
      type: Number,
      default: 6,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      digits: [],
      value: '',
    };
  },
  computed: {
    fullCode() {
      return this.digits.join('');
    },
  },
  watch: {
    fullCode(to, from) {
      if (to !== from && to.length === this.numberOfDigits) this.$emit('fullcodeentered', to);
    },
  },
  methods: {
    focusFirstInput() {
      this.$refs.digit_1[0].focus();
    },
    cleanupDigits() {
      const numbersOnly = /\D/g;
      const fullCode = this.digits.join('');
      const fullCodeNumbersOnly = fullCode.replace(numbersOnly, '');
      this.digits = fullCodeNumbersOnly.split('');
      this.digits = this.digits.slice(0, this.numberOfDigits);
    },
    handleBackspace(inputNumber) {
      const cursorPosition = this.$refs[`digit_${inputNumber}`][0].selectionStart;
      const backspaceIntoPreviousInput = cursorPosition === 0 && inputNumber !== 1;

      if (backspaceIntoPreviousInput) {
        const previousDigit = `digit_${inputNumber - 1}`;
        this.$refs[previousDigit][0].focus();
      }
    },
    handleDelete(inputNumber) {
      const cursorPosition = this.$refs[`digit_${inputNumber}`][0].selectionStart;
      const index = inputNumber - 1;
      const nextIndex = index + 1;
      const deleteIntoNextInput = cursorPosition === 1 && inputNumber !== this.numberOfDigits;

      if (deleteIntoNextInput) this.digits.splice(nextIndex, 1);
    },
    handleArrow(inputNumber, direction) {
      const cursorPosition = this.$refs[`digit_${inputNumber}`][0].selectionStart;
      const moveLeft = cursorPosition === 0
        && direction === 'left'
        && inputNumber !== 1;
      const moveRight = cursorPosition === 1
        && direction === 'right'
        && inputNumber !== this.numberOfDigits;

      if (moveLeft) this.$refs[`digit_${inputNumber - 1}`][0].focus();
      if (moveRight) this.$refs[`digit_${inputNumber + 1}`][0].focus();
    },
    handleKeyDown(inputNumber, event) {
      const isBackspace = event.code === 'Backspace';
      const isDelete = event.code === 'Delete';
      const isLeftArrow = event.code.includes('ArrowLeft');
      const isRightArrow = event.code.includes('ArrowRight');

      if (isBackspace) {
        this.handleBackspace(inputNumber);
      } else if (isDelete) {
        this.handleDelete(inputNumber);
      } else if (isLeftArrow || isRightArrow) {
        this.handleArrow(inputNumber, isLeftArrow ? 'left' : 'right');
      }
    },
    handleInput(inputNumber, event) {
      const isBackspace = event.inputType === 'deleteContentBackward';
      const isDelete = event.inputType === 'deleteContentForward';
      const isPaste = event.inputType === 'insertFromPaste';
      this.cleanupDigits();

      if (isBackspace || isDelete) return;

      const index = inputNumber - 1;
      const nextDigit = isPaste ? `digit_${this.digits.length}` : `digit_${inputNumber + 1}`;
      const isLastDigit = inputNumber === this.numberOfDigits;
      const digitIsEntered = !!this.digits[index];

      if (!isLastDigit && digitIsEntered) {
        this.$refs[nextDigit][0].focus();
      }
    },
    handleFocus(inputNumber) {
      const previousDigitHasValue = !!`${this.digits[inputNumber - 2]}`;

      if (inputNumber !== 1 && !previousDigitHasValue) {
        const previousDigit = `digit_${inputNumber - 1}`;

        this.$refs[previousDigit][0].focus();
      }
    },
    clearDigits() {
      this.digits = [];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_composite-input';
</style>
