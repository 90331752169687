<template>
  <nav class="footer-links">
    <ul class="footer-links__list">
      <li
        v-for="(link, index) in links"
        :key="index"
        class="footer-links__item"
      >
        <component
          :is="link.component ? link.component : 'a'"
          :id="link.id"
          :href="link.targetLocation"
          :target="link.opensNewTab ? '_blank' : null"
          :rel="link.opensNewTab ? 'noopener' : null"
          :class="`footer-links__link link link--unstyled ${link.class ? link.class : ''}`"
        >
          {{ link.label }}
        </component>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'FooterLinks',
  data() {
    return {
      links: [
        {
          label: 'About Us',
          targetLocation: '/about-us',
        },
        {
          label: 'Terms of Use',
          targetLocation: 'https://health-union.com/terms-of-use/',
          opensNewTab: true,
        },
        {
          label: 'Privacy Policy',
          targetLocation: 'https://health-union.com/privacy-policy/',
          opensNewTab: true,
        },
        {
          label: 'Your Privacy Choices',
          targetLocation: 'https://health-union.com/privacy-choices/',
          opensNewTab: true,
        },
        {
          component: 'button',
          id: 'ot-sdk-btn',
          label: 'Cookie Settings',
          class: 'ot-sdk-show-settings',
        },
        {
          label: 'Community Rules',
          targetLocation: 'https://health-union.com/community-rules/',
          opensNewTab: true,
        },
        {
          label: 'Ad Choices',
          targetLocation: 'https://optout.aboutads.info/?c=2&lang=EN',
          opensNewTab: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_footer-links';
</style>

<docs>
</docs>
