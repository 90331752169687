import gql from 'graphql-tag';

export const SET_CURRENT_USER = gql`
mutation updateUser($username: String!) {
  updateUser(username: $username) {
    username
    id
    email
    slug
  }
}
`;
