const dataLayerState = () => ({
  siteDataLayer: {},
  dataLayer: {},
});

const getters = {
  siteDataLayer: (state) => state.siteDataLayer,
  dataLayer: (state) => ({ ...state.siteDataLayer, ...state.dataLayer }),
};

const mutations = {
  setSiteDataLayer(state, data) {
    state.siteDataLayer = data;
  },
  setDataLayer(state, data) {
    state.dataLayer = data;
  },
};

const actions = {
  setSiteDataLayer({ commit }, data) {
    commit('setSiteDataLayer', data);
  },
  setDataLayer({ commit, dispatch }, data) {
    commit('setDataLayer', data);
    dispatch('updateGlobalContext');
  },
  clearDataLayer({ commit }) {
    commit('setDataLayer', {});
  },
};

export default () => ({
  state: dataLayerState(),
  getters,
  mutations,
  actions,
});
