import gql from 'graphql-tag';
import { CONVERSATION_FRAGMENT } from '@/graphql/fragments/private-messaging-fragments';

// NOTE: Changes to this query must be applied to the CONVERSATIONS_SUBSCRIPTION
// in src/graphql/subscriptions/private-messaging-subscriptions.js
export const GET_CONVERSATIONS = gql`
  query conversations($isArchived: Boolean, $sort: ConversationSort) {
    conversations(isArchived: $isArchived, sort: $sort) {
      conversationId
      insertedAt
      isRead
      isArchived
      isBlocked
      lastMessageAt
      lastMessageBody
      user {
        id
        username
        avatar {
          thumb
        }
      }
    }
  }
`;

export const GET_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  query conversation($id: NexusId!) {
    conversation(id: $id) {
      ...conversationFragment
    }
  }
`;

export const GET_BLOCKED_USERS = gql`
  query blockedUsers {
    blockedUsers {
      id
      username
      avatar {
        thumb
      }
    }
  }
`;
