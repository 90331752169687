const logInFormState = () => ({
  email: '',
  password: '',
});

const getters = {
  logInEmail: (state) => state.email,
  logInPassword: (state) => state.password,
};

const mutations = {
  setEmail(state, email) {
    state.email = email;
  },
  setPassword(state, password) {
    state.password = password;
  },
};

const actions = {
  clearLogInForm({ commit }) {
    commit('setEmail', '');
    commit('setPassword', '');
  },
};

export default () => ({
  state: logInFormState(),
  getters,
  mutations,
  actions,
});
