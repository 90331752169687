<template>
  <form-component
    class="f-name-and-l-name"
    @submit.prevent="tryToSubmit"
  >
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.fname.$invalid }"
    >
      <validation-error
        ref="fname"
        class="validation-error--label"
        :model="fname"
        :validator="$v.fname"
      />
      <text-input
        id="fname"
        v-model="fname"
        name="fname"
        input-type="text"
        class="text-input--full-width"
      >First name
      </text-input>
    </div>
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.lname.$invalid }"
    >
      <validation-error
        ref="lname"
        class="validation-error--label"
        :model="lname"
        :validator="$v.lname"
      />
      <text-input
        id="lname"
        v-model="lname"
        name="lname"
        input-type="text"
        class="text-input--full-width"
      >Last name
      </text-input>
    </div>
    <p class="input-disclaimer disclaimer-text">
      <slot name="disclaimer">
        Your name will be publicly visible.  You can change this setting in your profile.
      </slot>
    </p>
    <div class="f-name-and-l-name__button--submit">
      <loading-button
        class="button button--large"
        type="submit"
        :loading="tryingToSubmit"
      >
        <slot>Continue</slot>
      </loading-button>
    </div>
    <progress-indicator current-step="1" />
  </form-component>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import TextInput from '@/components/Inputs/TextInput.vue';
import ValidationError from '@/components/Global/ValidationError.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import LoadingButton from '@/components/Global/LoadingButton.vue';
import { UPDATE_USER } from '@/graphql/mutations/user-mutations';

export default {
  name: 'FNameAndLName',
  components: {
    TextInput,
    ProgressIndicator,
    LoadingButton,
    ValidationError,
  },
  data() {
    return {
      fname: '',
      lname: '',
      tryingToSubmit: false,
    };
  },
  validations: {
    fname: {
      required,
    },
    lname: {
      required,
    },
  },
  methods: {
    tryToSubmit() {
      this.tryingToSubmit = true;
      this.$v.$touch();

      this.$refs.fname.checkErrors();
      this.$refs.lname.checkErrors();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          firstName: this.fname,
          lastName: this.lname,
        },
      }).then(() => {
        this.$store.dispatch('setAuthStep', { authStep: 'setUsername' });
      }).catch(() => {
        this.tryingToSubmit = false;
      }).finally(() => {
        this.tryingToSubmit = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_f-name-and-l-name';
  @import '@/stylesheets/components/_input-disclaimer';
</style>
