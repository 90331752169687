/* eslint-disable no-underscore-dangle */
import { loadAsyncComponents } from '@akryum/vue-cli-plugin-ssr/client';
import { createApp } from '@/app';

createApp({
  context: {
    ssr: false,
    host: window.location.host,
    url: window.location.href,
  },
  beforeApp: async ({
    router,
  }) => {
    await loadAsyncComponents({ router });
  },

  afterApp: ({
    app,
    router,
    store,
  }) => {
    if (window.__INITIAL_STATE__) {
      if (window.__INITIAL_STATE__.referer) {
        sessionStorage.setItem('referer', window.__INITIAL_STATE__.referer);
      }
      // We initialize the store state with the data injected from the server
      store.replaceState(window.__INITIAL_STATE__);
    }
    router.onReady(() => {
      // To debug SSR issues it can be useful to drop a debugger here, this will
      // stop execution before hydration in the browser
      // debugger;
      app.$mount('#app');
    });
  },
});
