<template>
  <div
    id="site-wrapper"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['accessibilityClasses', 'userMousing']),
    classes() {
      const classes = [...this.accessibilityClasses];
      classes.push(this.$site.name.toLowerCase().split('.')[0]);
      if (this.$store.state.userMousing) {
        classes.push('user-mousing');
      }
      return classes;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleFirstTab);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleFirstTab);
    window.removeEventListener('mousedown', this.handleMouseDownOnce);
  },
  methods: {
    handleFirstTab(e) {
      if (e.keyCode === 9) {
        this.$store.dispatch('setUserMousing', false);

        window.removeEventListener('keydown', this.handleFirstTab);
        window.addEventListener('mousedown', this.handleMouseDownOnce);
      }
    },
    handleMouseDownOnce() {
      this.$store.dispatch('setUserMousing', true);

      window.removeEventListener('mousedown', this.handleMouseDownOnce);
      window.addEventListener('keydown', this.handleFirstTab);
    },
  },
};
</script>
