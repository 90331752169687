/* eslint-disable no-underscore-dangle */
import { siteDomains } from '@/utils/site-domains';

const createEventPayload = (el, { suffix = '' } = {}) => {
  const action = el.dataset.trackEvent + (suffix || '');
  let value = el.dataset.trackValue || el.value || undefined;
  if (el.type === 'checkbox' && !el.checked) {
    value = false;
  }

  const data = {
    label: el.dataset.trackLabel,
    property: el.dataset.trackProperty,
    value,
    context: el.dataset.trackContext,
  };

  // Remove any properties of data where the value is undefined
  // This is the same as using lodash omitBy(data, isUndefined)
  Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]);

  return {
    action,
    data,
  };
};

const eventHandler = (e, func, opts = {}) => {
  const el = e.target.closest('[data-track-event]');

  if (!el) { return; }

  const { action, data } = createEventPayload(el, opts);
  func(opts.category, action, data);
};

const eventHandlers = (category, func) => {
  const handler = (opts) => (e) => eventHandler(e, func, { ...{ category }, ...opts });
  const handlers = [];
  handlers.push({ name: 'click', func: handler() });
  // Custom event handlers can be added here if needed.
  return handlers;
};

class Tracking {
  constructor(store) {
    this.store = store;
  }

  static trackable() {
    return !['1', 'yes'].includes(
      window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack,
    );
  }

  static enabled() {
    const isNotHuSite = !/hu-site./.test(window.location.origin);
    return isNotHuSite
      && process.browser
      && typeof window.snowplow_hu === 'function'
      && this.trackable();
  }

  static event(category = document.body.dataset.page, action = 'generic', data = {}) {
    if (!this.enabled()) { return false; }
    if (!category) {
      throw new Error('Tracking: no category provided for tracking.');
    }

    const {
      label, property, value, context,
    } = data;
    const contexts = context ? [context] : undefined;
    return window.snowplow_hu('trackStructEvent', {
      category, action, label, property, value, context: contexts,
    });
  }

  static selfDescribingEvent(eventJson, context) {
    if (!this.enabled()) { return false; }
    if (!eventJson) { throw new Error('Tracking: no event data provided for tracking.'); }
    if (!eventJson.schema) { throw new Error('Tracking: no schema provided for tracking.'); }
    if (!eventJson.data) { throw new Error('Tracking: no data provided for tracking.'); }

    return window.snowplow_hu(
      'trackSelfDescribingEvent',
      {
        event: eventJson,
        context,
      },
    );
  }

  trackError(message, filename, lineNumber = null, colNumber = null, errorEvent = null) {
    if (!Tracking.enabled()) { return; }
    // https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#trackError
    window.snowplow_hu('trackError', {
      message,
      filename,
      lineno: lineNumber,
      colno: colNumber,
      error: errorEvent,
      context: [
        this.store.getters.complianceContext,
        this.store.getters.globalContext,
        this.store.getters.userContext,
      ],
    });
  }

  trackPageView(pageTitle = null) {
    if (!Tracking.enabled()) { return; }
    // https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#311-trackpageview
    window.snowplow_hu('trackPageView', {
      title: pageTitle,
      context: [
        this.store.getters.complianceContext,
        this.store.getters.globalContext,
        this.store.getters.userContext,
        this.store.getters.a11yContext,
      ],
    }); // must be after enableActivityTracking
  }

  trackSiteSearch(searchTerms = [], filter, totalResults, firstPageResults) {
    if (!Tracking.enabled()) { return; }

    window.snowplow_hu('trackSiteSearch', {
      terms: searchTerms,
      filters: filter,
      totalResults,
      pageResults: firstPageResults,
      context: [
        this.store.getters.complianceContext,
        this.store.getters.globalContext,
        this.store.getters.userContext,
      ],
    });
  }

  enableFormTracking() {
    if (!Tracking.enabled()) { return; }
    window.snowplow_hu('enableFormTracking', {
      context: [
        this.store.getters.complianceContext,
        this.store.getters.globalContext,
        this.store.getters.userContext,
      ],
    });
  }

  // eslint-disable-next-line class-methods-use-this
  refreshLinkTracking() {
    if (!Tracking.enabled()) { return; }
    window.snowplow_hu('refreshLinkClickTracking');
  }

  static bindDocument(category = document.body.dataset.page, parent = document) {
    if (!this.enabled() || parent.trackingBound) { return []; }

    // eslint-disable-next-line no-param-reassign
    parent.trackingBound = true;

    const handlers = eventHandlers(category, (...args) => this.event(...args));
    handlers.forEach((event) => parent.addEventListener(event.name, event.func));
    return handlers;
  }

  static trackLoadEvents(category = document.body.dataset.page, parent = document) {
    if (!this.enabled()) { return []; }

    const loadEvents = parent.querySelectorAll('[data-track-event="render"]');

    loadEvents.forEach((element) => {
      const { action, data } = createEventPayload(element);
      this.event(category, action, data);
    });

    return loadEvents;
  }
}

const initUserTracking = (store) => {
  if (!Tracking.enabled()) { return; }

  const crossDomainLinker = (linkElement) => {
    const sites = siteDomains.map((site) => `^https://${site.toLowerCase()}`);
    const pattern = new RegExp(
      sites
        .map((site) => site.replace(/[/\\.*+?${}()|[\]\\]/g, '\\$&'))
        .join('|'),
      'gi',
    );

    return pattern.test(linkElement.href);
  };

  let inProduction;

  if (process.browser) {
    inProduction = /prod/.test(window.__INITIAL_STATE__.dataLayer.siteDataLayer.env);
  } else {
    inProduction = /prod/.test(store.getters.siteDataLayer.env);
  }
  // To test with snowplow-micro use this:
  // const hostname = `${window.location.hostname}/snowplow`;
  const hostname = inProduction
    ? 'webhooks.fivetran.com/snowplow/da6b9e44-72ae-4a29-9813-2c0488beadc0'
    : 'webhooks.fivetran.com/snowplow/6e09de35-d79e-4e2b-8db8-2157743f5286';

  const SNOWPLOW_OPTIONS = {
    namespace: 'hu',
    hostname,
    cookieDomain: window.location.hostname,
    cookieSecure: true,
    appId: window.location.hostname,
    platform: 'web',
    respectDoNotTrack: true,
    crossDomainLinker,
    contexts: {
      webPage: true,
      performanceTiming: true,
      gaCookies: true,
      geolocation: false,
    },
  };

  window.snowplow_hu(
    'newTracker',
    SNOWPLOW_OPTIONS.namespace,
    SNOWPLOW_OPTIONS.hostname,
    SNOWPLOW_OPTIONS,
  );

  window.snowplow_hu('enableActivityTracking', {
    minimumVisitLength: 20,
    heartbeatDelay: 10,
  });

  window.snowplow_hu('enableLinkClickTracking', {
    pseudoClicks: true,
    trackContent: false,
    contexts: [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
    ],
  });

  window.snowplow_hu('enableFormTracking', {
    context: [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
    ],
  });

  // https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#enableErrorTracking
  window.snowplow_hu('enableErrorTracking', {
    contextAdder: () => [
      store.getters.complianceContext,
      store.getters.globalContext,
      store.getters.userContext,
    ],
  });

  Tracking.bindDocument();
  Tracking.trackLoadEvents();
};

export {
  Tracking,
  initUserTracking,
};
