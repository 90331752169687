<template>
  <div class="content-driver-banner">
    <!-- The element below should be a div and not a p tag
      to protect against invalid markup if the content
      contains one.
    -->
    <div
      class="content-driver-banner__text"
      v-html="this.$site.settings.content_driver_banner"
    />
  </div>
</template>

<script>
export default {
  name: 'ContentDriverBanner',
};
</script>

<style lang="scss">
  @import '@/stylesheets/components/_content-driver-banner';
</style>
