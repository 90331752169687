<template>
  <div class="skip-links">
    <a
      v-for="(link, index) in links"
      :key="index"
      :href="link.href"
      class="skip-links__link button"
      @click="skipTo"
    >
      {{ link.text }}
    </a>
  </div>
</template>

<script>
import scrollToMixin from '@/mixins/scroll-to-mixin';

export default {
  name: 'SkipLinks',
  mixins: [scrollToMixin],
  computed: {
    links() {
      return [
        {
          href: '#a11y-tools-toggle-button',
          text: 'Skip to Accessibility Menu',
        },
        {
          href: this.$store.getters.userIsLoggedIn ? '#profile-button' : '#login-button',
          text: this.$store.getters.userIsLoggedIn ? 'Skip to Profile Menu' : 'Skip to Login',
        },
        {
          href: '#layout-content',
          text: 'Skip to Content',
        },
        {
          href: '#footer',
          text: 'Skip to Footer',
        },
      ];
    },
  },
  methods: {
    skipTo(event) {
      // Approach largely based on information presented here:
      // https://axesslab.com/skip-links
      event.preventDefault();
      const skipTargetId = `#${event.target.href.split('#')[1]}`;
      const skipToElement = document.querySelector(skipTargetId);

      // Make skipToElement programmatically focussable
      skipToElement.setAttribute('tabindex', -1);
      skipToElement.focus();

      this.scrollTo(skipTargetId, 'instant');

      // Remove tabindex from rootElement on blur, remove the listener when envoked.
      // Reason: https://axesslab.com/skip-links/#update-3-a-comment-from-gov-uk
      skipToElement.addEventListener('blur', (e) => {
        e.target.removeAttribute('tabindex');
      }, { once: true });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_skip-links';
</style>
