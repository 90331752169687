const ProfileComponent = () => import('@/pages/User/ProfileComponent.vue');
const ProfileOverview = () => import('@/pages/User/ProfileOverview.vue');
const EditProfile = () => import('@/pages/User/EditProfile.vue');
const NotificationsComponent = () => import('@/pages/User/NotificationsComponent.vue');
const NotificationsSettings = () => import('@/pages/User/NotificationsSettings.vue');
const UnsubscribeComponent = () => import('@/pages/User/UnsubscribeComponent.vue');
const ProfileQuestions = () => import('@/pages/User/ProfileQuestions.vue');
const FollowingDetails = () => import('@/pages/User/FollowingDetails.vue');
const AllFollowing = () => import('@/pages/User/AllFollowing.vue');
const BookmarksComponent = () => import('@/pages/User/BookmarksComponent.vue');
const UserAwards = () => import('@/pages/User/UserAwards.vue');

const MessageCenter = () => import('@/pages/Messages/MessageCenter.vue');

export default [
  {
    path: '/profile',
    name: 'userProfile',
    component: ProfileComponent,
    children: [
      {
        path: '/profile',
        name: 'userProfileOverview',
        component: ProfileOverview,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/edit',
        name: 'userEditProfile',
        component: EditProfile,
        meta: { requiresAuth: true },
      },
      {
        path: '/profile/notifications',
        name: 'userNotifications',
        component: NotificationsComponent,
        meta: { requiresAuth: true },
      },
      {
        path: '/profile/notifications/settings',
        name: 'userNotificationsSettings',
        component: NotificationsSettings,
        meta: { requiresAuth: true },
      },
      {
        path: '/profile/questions',
        name: 'profileQuestions',
        component: ProfileQuestions,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/questions/:slug',
        name: 'extendedProfileSection',
        component: ProfileQuestions,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/questions/:slug',
        name: 'socialProfileSection',
        component: ProfileQuestions,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/following',
        name: 'userFollowingDetails',
        component: FollowingDetails,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/following/:followType',
        name: 'AllFollowing',
        component: AllFollowing,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/bookmarks',
        name: 'userBookmarks',
        component: BookmarksComponent,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/profile/awards',
        name: 'userAwards',
        component: UserAwards,
        meta: { requiresAuth: true, hasLeaderboard: true },
      },
      {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: UnsubscribeComponent,
        meta: { requiresAuth: true },
      },
      // Private Messaging routes
      {
        path: '/messages',
        name: 'messages',
        component: MessageCenter,
        meta: { requiresAuth: true },
      },
      {
        path: '/messages/archive',
        name: 'messagesArchive',
        component: MessageCenter,
        meta: { requiresAuth: true, filter: 'archive' },
      },
      {
        path: '/messages/archive/:conversationId',
        name: 'archivedConversation',
        component: MessageCenter,
        meta: { requiresAuth: true, filter: 'archive' },
      },
      {
        path: '/messages/blocked',
        name: 'messagesBlocked',
        component: MessageCenter,
        meta: { requiresAuth: true, filter: 'blocked' },
      },
      {
        path: '/messages/new',
        name: 'createConversation',
        component: MessageCenter,
        meta: {
          requiresVerify: true, create: true, authDialogSettings: { dialogText: 'Create an account to send a private message.' }, UGC: { isUGC: true },
        },
      },
      {
        path: '/messages/:conversationId',
        name: 'conversation',
        component: MessageCenter,
        meta: { requiresAuth: true },
      },
    ],
  },
];
