import { getCLS, getFID, getLCP } from 'web-vitals';

const mantainReferrerForSpaNavigation = (to, from) => {
  /**
    * document.referrer is an immutable document property so there is no way to
    * update it based on navigation in a Single Page App. Instead, we maintain a
    * separate dynamic referrer property on the window object. This was added
    * to ensure that Snowplow referrer values are maintained through client-side
    * redirects. See setReferrer in snowplow.js for use case.
    */

  if (!process.browser) return;

  const navigationEventIsNotASameSlugRedirect = to.params.slug !== from.params.slug;

  if (navigationEventIsNotASameSlugRedirect) {
    window.referrer = `${window.location.protocol}//${window.location.host}${from.fullPath}`;
  }
};

export default function navigationGuards(router, store) {
  router.beforeResolve((to, from, next) => {
    if (process.browser) {
      store.dispatch('resetPageViewId');
    }

    if (store.state.is404) {
      store.dispatch('setPrev404Urls', from.path);
    }

    if (store.state.prev404Urls?.includes(to.path)) {
      store.dispatch('setIs404', true);
    } else {
      store.dispatch('setIs404', false);
    }

    store.dispatch('setIsPreviewError', false);
    // If this isn't an initial page load.
    if (to.name) {
      // This doesn't work in the snowplow plugin and breaks a bunch of things
      // for unknown reasons, possibly because that plugin isn't installed during ssr?
      store.dispatch('resetGlobalContext');
    }
    store.dispatch('setNavOpen', false);
    store.dispatch('gam/resetAdsOnPage');
    store.dispatch('clearContentJsonLd');
    next();
  });

  router.beforeEach(async (to, from, next) => {
    store.dispatch('clearDataLayer');
    // eslint-disable-next-line arrow-body-style
    const requiresCurrentUser = to.matched.some((record) => {
    // This condition determines if the Navigation guard should await the checkForCurrentUser
      return record.meta.notLoggedIn
      || record.meta.requiresAuth
      || record.meta.requiresVerify
      || /memberProfile/i.test(record.name);
    });

    mantainReferrerForSpaNavigation(to, from);

    // TODO this feature functionality should be added to each route, it should not be done here
    const featureBasedRoutes = {
      community: 'community',
      conversation: 'private_messaging',
      createConversation: 'private_messaging',
      forums: 'Forums',
      forumThread: 'Forums',
      memberProfileStatus: 'status_updates',
      MemberProfileStatusById: 'status_updates',
      messages: 'private_messaging',
      messagesArchive: 'private_messaging',
      messagesBlocked: 'private_messaging',
      stories: 'Stories',
      story: 'Stories',
      recipe: 'Recipes',
      recipes: 'Recipes',
      register: 'Accounts',
    };

    Object.keys(featureBasedRoutes).forEach((page) => {
      if (page === to.name && !store.getters.siteHasFeature(featureBasedRoutes[page])) {
        next({ name: 'home' });
      }
    });

    let { currentUser } = store.getters;
    let { userIsLoggedIn, userIsUnverified } = store.getters;

    if (requiresCurrentUser && !userIsLoggedIn) {
      await store.dispatch('checkForCurrentUser', router.app).then(() => {
        currentUser = store.getters.currentUser;
        userIsLoggedIn = store.getters.userIsLoggedIn;
        userIsUnverified = store.getters.userIsUnverified;
      });
      // User has email but no username - indicates incomplete registration
      if (!!currentUser.email && !currentUser.username) {
        next({ name: 'RegisterUsername', query: { redirect: to.fullPath, is302: true } });
        return;
      }
    }

    // Navigation guard for logged out only routes like /register
    if (to.matched.some((record) => record.meta.notLoggedIn) && userIsLoggedIn) {
      next({
        path: '/profile',
        query: { ...to.query, is302: true },
      });
      return;
    }

    if (/^\/?api\/dashboard-redirect/.test(to.query.redirect)) {
      store.dispatch('setAuthDialogRedirect', to.query.redirect);
    }

    // Navigation guard for logged in only routes like /profile
    if (!userIsLoggedIn && to.matched.some((record) => record.meta.requiresAuth || record.meta.requiresVerify)) {
      if (process.browser) {
        store.dispatch('setAuthDialogRedirect', to.fullPath);
        const authDialogSettings = to.meta.authDialogSettings || { dialogText: 'Please create an account or log in to continue.' };
        const isUGC = to.meta.UGC || { isUGC: false };
        const settings = {
          ...authDialogSettings,
          ...isUGC,
        };
        store.dispatch('openRegisterDialog', settings);
        return;
      }
      next({
        path: '/member-login',
        query: { redirect: to.fullPath, is302: true },
      });
      return;
    }

    if (userIsUnverified && to.matched.some((record) => record.meta.requiresVerify)) {
      if (process.browser) {
        store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return;
      }
    }

    // Navigation guard for members accessing their own profile
    // if (to.matched.some((record) => /memberProfile/.test(record.name))) {
    if (to.matched.some((record) => record.name === 'memberProfile')) {
      const isCurrentUsersProfile = currentUser && currentUser.slug === to.params.slug;

      if (isCurrentUsersProfile) {
        next({ ...to, ...{ name: 'userProfileOverview', query: { is302: true } } });
        return;
      }
    }

    if (process.browser) {
      if (to.path === '/privacy-policy' || to.path === '/terms-of-use') {
        window.location = `https://health-union.com/${to.path}`;
        return;
      }

      if (to.matched.some((record) => record.meta.external)) {
        window.location = to.fullPath;
        return;
      }
    }

    // if meta startAtTop is set to true, the user will be
    // brought back up to the top of the page on refresh
    if (to.matched.some((record) => record.meta.startAtTop)) {
      window.scrollTo(0, 0);
    }

    next();
  });

  router.afterEach((to) => {
    store.dispatch('updateGlobalContext', {
      page: {
        type: to.name,
      },
    });

    if (!process.browser) { return; }

    function sendToGTM({ name, delta, id }) {
      router.app.$gtm.trackEvent({
        event: 'web-vitals',
        category: 'Web Vitals',
        action: name,
        label: id,
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
      });
    }
    getCLS(sendToGTM);
    getFID(sendToGTM);
    getLCP(sendToGTM);
  });
}
