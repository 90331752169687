import { onError } from 'apollo-link-error';
import { loggerApi } from '@/plugins/logger';

const filename = 'graphql/error-link.js';

// Note that if you have a query which should ignore 404s you can pass a variable
// ignore404: true which will prevent any redirects happening. This can be useful
// if you're querying within a dialog or nested component
const is404 = (message) => {
  // This regex sucks but the API message doesn't have a consistent pattern
  // we should improve that APi side and make this more robust here
  const notFoundRegex = new RegExp('not found', 'ig');
  return notFoundRegex.test(message);
};

const isPreviewError = (message) => message === 'No preview permission';

const link = (tracker, store, context) => onError(({
  graphQLErrors, networkError, response, operation,
}) => {
  const logger = loggerApi({ context });
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (process.browser) {
        tracker.trackError(message, filename);
      }

      if (is404(message) && !operation.variables.ignore404) {
        store.dispatch('setIs404', true);
        logger.warn(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          { graphQL: operation },
        );
      } else if (isPreviewError(message)) {
        store.dispatch('setIsPreviewError', true);
      } else {
        logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          { graphQL: operation },
        );
      }
    });
  }
  if (networkError) {
    const codeReloaderRegex = /<div class="output-block">(.|\n)*<\/div>/;
    const debuggerRegex = /<header class="exception-info">(.|\n)*<\/header>/;

    // The API errors match HTML generated by Phoenix's code reloader or Plug's debugger.
    // Neither should trigger in production.
    if (codeReloaderRegex.test(networkError.bodyText)) {
      const errorText = networkError.bodyText.match(codeReloaderRegex)[0];
      if (process.browser) {
        tracker.trackError(errorText, filename);
      }
      logger.error(`[API error]: ${errorText}`, { graphQL: operation });
    } else if (debuggerRegex.test(networkError.bodyText)) {
      const errorText = networkError.bodyText.match(debuggerRegex)[0];
      if (process.browser) {
        tracker.trackError(errorText, filename);
      }
      logger.error(`[API error]: ${errorText}`, { graphQL: operation });
    } else {
      const errorText = networkError.bodyText ? networkError.bodyText : `${networkError}`;

      if (process.browser) {
        tracker.trackError(errorText, filename);
      }
      logger.error(`[Network error]: ${errorText}`, { graphQL: operation, networkError });
    }
  }
  // Ignore errors During SSR to prevent 500 if a query fails
  if (!process.browser && (graphQLErrors || networkError) && response) {
    // eslint-disable-next-line no-param-reassign
    response.errors = undefined;
  }
});

export default link;
