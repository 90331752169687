<template>
  <div
    v-if="siteFeatureNeedsProgressIndicator"
    class="progress-indicator"
  >
    <div
      v-for="(step, index) in ProgressIndicatorSteps"
      :key="index"
      class="progress-indicator__dot"
      :class="{ 'progress-indicator__dot--current': isCurrentStepDot(index) }"
      :data-test-id="`${ isCurrentStepDot(index) ? 'current-' : '' }progress-indicator-dot`"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProgressIndicator',
  components: {
  },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['ProgressIndicatorSteps', 'siteHasFeature']),
    siteFeatureNeedsProgressIndicator() {
      return this.siteHasFeature('rare_disease') || this.siteHasFeature('social_network');
    },
  },
  methods: {
    isCurrentStepDot(index) {
      return Number(this.currentStep) === index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_progress-indicator';
</style>

<docs>

Indicates what step of the registration flow the user is on.

</docs>
