<template>
  <button
    id="profile-button"
    type="button"
    aria-label="Your Account"
    :aria-expanded="profileMenuOpen ? 'true' : 'false'"
    aria-controls="profile-menu"
    class="header__button header__button--profile profile-button"
    @click="toggleProfileMenu"
  >
    <div
      class="profile-button__user-avatar-wrapper"
      aria-label="Notifications"
      aria-live="polite"
      role="region"
    >
      <user-avatar :user="currentUser" :display-tooltip="false" />
      <div
        v-if="unreadsCount > 0"
        class="profile-button__notification-indicator"
      >
        <span class="visually-hidden">You have {{ unreadsCount }} unreads.</span>
      </div>
      <span v-else class="visually-hidden">You have no new notifications.</span>
    </div>
  </button>
</template>

<script>

import UserAvatar from '@/components/User/UserAvatar.vue';
import { mapGetters } from 'vuex';
import { UNREAD_COUNTS } from '@/graphql/queries/user-queries';

export default {
  name: 'ProfileButton',
  components: {
    UserAvatar,
  },
  data: () => ({
    unreads: () => {},
    unreadsCount: 0,
  }),
  computed: {
    ...mapGetters([
      'currentUser',
      'profileMenuOpen',
    ]),
  },
  watch: {
    $route() {
      this.getUnreads();
    },
    unreads() {
      this.updateCount();
    },
  },
  mounted() {
    this.getUnreads();
  },
  methods: {
    toggleProfileMenu() {
      this.$store.dispatch('setProfileMenuOpen', !this.profileMenuOpen);
    },
    updateCount() {
      if (!this.unreads || !this.unreads.messages) { this.unreadsCount = 0; }

      this.unreadsCount = this.unreads.messages + this.unreads.notifications;
    },
    getUnreads() {
      this.$apollo.query({
        query: UNREAD_COUNTS,
      }).then(({ data: { unreads } }) => {
        this.unreads = unreads;
        this.updateCount();
      }).catch((error) => {
        this.$logger.error(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_profile-button';
</style>
