<template>
  <div class="subscribe-trigger">
    <button-component
      :use-button-class="false"
      class="subscribe-trigger__button"
      data-test-id="subscribe-trigger-button"
      @click="opensLeadGenFormInDialog"
    >
      <span class="subscribe-trigger__button-text">Subscribe</span>
      <icon-component
        name="envelope"
        class="subscribe-trigger__button-icon"
      />
    </button-component>
  </div>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'SubscribeTrigger',
  components: {
    ButtonComponent,
  },
  methods: {
    opensLeadGenFormInDialog() {
      this.$store.dispatch('openLeadGenFormInDialog', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/components/_subscribe-trigger';
</style>
