import gql from 'graphql-tag';

export const CONTENT_FLAG = gql`
mutation contentFlag($assocId: NexusId!, $assocType: ContentFlagType!) {
  contentFlag(assocId: $assocId, assocType: $assocType)
}
`;

export const MODERATION_CONTENT_FLAG = gql`
mutation moderationContentFlag($assocId: NexusId!, $assocType: ContentFlagType!, $operation: ContentFlagOperation) {
  moderationContentFlag(assocId: $assocId, assocType: $assocType, operation: $operation)
}
`;
