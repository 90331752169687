import TransportStream from 'winston-transport';
import { datadogLogs } from '@datadog/browser-logs';

export default class DatadogBrowserTransport extends TransportStream {
  constructor(options = {}) {
    super(options);

    this.name = 'datadogBrowser';
    this.validLevels = ['debug', 'info', 'warn', 'error'];

    this.setMaxListeners(30);
  }

  validDatadogLevel(level) {
    return this.validLevels.includes(level);
  }

  log(info, callback) {
    window.setTimeout(() => this.emit('logged', info), 0);
    // Clone info removing message and level properties
    const attrs = (({ message, level, ...o }) => o)(info);

    if (process.env.NODE_ENV === 'development') { // Local dev only
      // eslint-disable-next-line no-console
      console[info.level](info.message, attrs);
    } else if (this.validDatadogLevel(info.level)) {
      datadogLogs.logger.log(info.message, attrs, info.level);
    }

    if (callback) {
      callback();
    }
  }
}
