<template>
  <form-component
    class="site-search"
    role="search"
    @submit.prevent="searchFunction"
  >
    <button class="site-search__button">
      <span class="site-search__button-hidden-text">Search</span>
      <icon-component
        name="magnifying-glass"
        class="site-search__button-icon"
      />
    </button>
    <input
      id="search"
      ref="search"
      v-model="term"
      name="search"
      class="site-search__input"
      type="search"
      placeholder="Search"
      aria-label="Search text"
      @blur="emitBlurEvent"
    >
  </form-component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SiteSearch',
  data() {
    return {
      term: '',
    };
  },
  computed: {
    ...mapGetters(['focusSearch']),
  },
  watch: {
    focusSearch(isFocused) {
      if (isFocused) {
        this.$refs.search.focus();
      }
    },
  },
  mounted() {
    if (this.focusSearch) {
      this.$refs.search.focus();
    }
  },
  methods: {
    searchFunction() {
      if (this.term.toLowerCase().replace(/\s/g, '') === 'supersecretdebugtool') {
        this.$router.push({ name: 'debugTool' });
        return;
      }
      this.$router.push({ name: 'search', query: { s: this.term } });
      this.term = '';
    },
    emitBlurEvent() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_site-search';
</style>

<style lang="scss"> // no scoped - for HTML element selectors
  /**
   * This styles the input search `x` icon.
   * https://stackoverflow.com/questions/46791545/display-pointer-on-hover-close-icon-in-search-textbox
   */
  input[type="search"]::-webkit-search-decoration:hover,
  input[type="search"]::-webkit-search-cancel-button:hover {
    cursor:pointer;
  }
</style>

<docs>
</docs>
