<template>
  <button-component
    class="facebook-login button--block button--social-login"
    @click="$emit('click')"
  >
    <icon-component
      name="facebook"
      class="button__icon"
    />
    {{ buttonTextContent }}
  </button-component>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'FacebookLogin',
  components: {
    ButtonComponent,
  },
  props: {
    buttonText: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    buttonTextContent() {
      if (this.buttonText) return this.buttonText;

      return 'Log in with Facebook';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_facebook-login';
</style>

<docs>

Button for social login / register with facebook

</docs>
