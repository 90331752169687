<template>
  <div class="username-input">
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.username.$invalid }"
    >
      <validation-error
        ref="username"
        class="validation-error--label"
        :model="username"
        :validator="$v.username"
      />
      <text-input
        id="username"
        v-model="username"
        name="username"
        class="text-input--full-width"
        data-test-id="username-input"
        @input="(value) => $emit('input', value)"
      >
        <slot name="input">
          Username
        </slot>
        <template v-slot:inlineButton>
          <icon-with-tooltip class="username-input__tooltip">
            <p>Note: Please use a minimum of 4 characters and limit to alphabetic or numeric characters only.</p>
          </icon-with-tooltip>
        </template>
      </text-input>
      <p v-if="showDisclaimer" class="input-disclaimer disclaimer-text">
        <slot name="disclaimer">
          Your username will be publicly visible.
          {{ isSHN && firstTimeLoggingIntoSHN ?
            'You can continue using your existing username or choose a new one.' :
            'We will assign you a generic username if you do not select one.'
          }}
        </slot>
      </p>
    </div>
  </div>
</template>

<script>
import {
  requiredIf, minLength, maxLength, helpers,
} from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import IconWithTooltip from '@/components/Global/IconWithTooltip.vue';
import { mapGetters } from 'vuex';

const alphaNumHelper = helpers.regex('alphaNumHelper', /^[a-z\d.\-_™®()/, ]*$/i);

export default {
  name: 'UsernameInput',
  components: {
    ValidationError,
    TextInput,
    IconWithTooltip,
  },
  props: {
    currentUsername: {
      type: String,
      default: '',
    },
    showDisclaimer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      username: this.currentUsername || '',
    };
  },
  validations: {
    username: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        return !this.isShn;
      }),
      minLength: minLength(4),
      maxLength: maxLength(60),
      alphaNumHelper,
    },
  },
  computed: {
    ...mapGetters(['siteHasFeature', 'firstTimeLoggingIntoSHN', 'currentUser']),
    isSHN() {
      return this.siteHasFeature('social_network');
    },
  },
  mounted() {
    if (this.siteHasFeature('social_network') && this.firstTimeLoggingIntoSHN) {
      this.username = this.currentUser?.username || '';
      this.$emit('input', this.username);
    }
  },
  methods: {
    checkErrors() {
      this.$refs.username.checkErrors();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_username-input';
  @import '@/stylesheets/components/_input-disclaimer';
</style>
