<template>
  <dialog-base
    class="dialog-base--profile-tooltip"
    :max-width="250"
    :position="this.$store.getters.profileTooltipPosition"
    dialog-type="profile-tooltip"
    :aria-label-text="ariaLabelText"
    @close="close"
  >
    <div class="profile-tooltip" data-test-id="profile-tooltip">
      <loading-spinner v-if="!user.id" />
      <div v-else class="profile-tooltip__content">
        <user-avatar
          :user="user"
          :is-cross-published="isCrossPublished"
          :original-site="originalSite"
          size="full"
          class="user-avatar--profile-tooltip"
        />
        <h4
          class="profile-tooltip__username"
          data-test-id="profile-tooltip-username"
        >{{ user.username }}
        </h4>
        <role-badge :user="user" />
        <div
          v-if="memberUnavailable"
          class="profile-tooltip__disabled"
        >
          <p>This member is no longer available.</p>
        </div>
        <div v-else class="profile-tooltip__actions">
          <a
            v-if="showViewAuthorArchive && onCrossPublishedSite && !isEditorialTeam"
            :href="crossPublishingAuthorIndex(user, originalSite)"
            class="
              profile-tooltip__action
              profile-tooltip__action--view-articles
              link--unstyled
            "
            data-test-id="profile-tooltip-view-articles-link"
            target="_blank"
          >
            <icon-component
              name="view-articles"
              title="view-articles icon"
              container="div"
              class="profile-tooltip__action-icon"
            />
            View articles
          </a>

          <router-link
            v-else-if="showViewAuthorArchive"
            class="
              profile-tooltip__action
              profile-tooltip__action--view-articles
              link--unstyled
            "
            data-test-id="profile-tooltip-view-articles-link"
            :to="{ name: 'authorIndex', params: { slug: user.slug } }"
            @click.native="close"
          >
            <icon-component
              name="view-articles"
              title="view-articles icon"
              container="div"
              class="profile-tooltip__action-icon"
            />
            View articles
          </router-link>

          <a
            v-if="onCrossPublishedSite && !isEditorialTeam"
            :href="crossPublishingAuthorProfile(user, originalSite)"
            class="
              profile-tooltip__action
              profile-tooltip__action--view-profile
              link--unstyled
            "
            data-test-id="profile-tooltip-view-profile-link"
            target="_blank"
          >
            <icon-component
              name="user"
              title="user icon"
              container="div"
              class="profile-tooltip__action-icon"
            />
            View profile
          </a>

          <router-link
            v-else
            class="
              profile-tooltip__action
              profile-tooltip__action--view-profile
              link--unstyled
            "
            data-test-id="profile-tooltip-view-profile-link"
            :to="{ name: 'memberProfile', params: { slug: user.slug } }"
            @click.native="close"
          >
            <icon-component
              name="user"
              title="user icon"
              container="div"
              class="profile-tooltip__action-icon"
            />
            View profile
          </router-link>

          <start-conversation-button
            v-if="showsPrivateMessaging && !onCrossPublishedSite"
            class="
              profile-tooltip__action
              profile-tooltip__action--send-message
              text-button
            "
            :user="user"
            @click="close"
          >
            <icon-component
              name="envelope"
              title="envelope icon"
              container="div"
              class="profile-tooltip__action-icon"
            />
            Send message
          </start-conversation-button>

          <follow-button
            v-if="!onCrossPublishedSite && !userIsOnTheirOwnProfile"
            :currently-following="followedByCurrentUser"
            is-in-profile-tooltip-or-member-profile
            :user="user"
            class="
              profile-tooltip__action
              profile-tooltip__action--follow
              text-button
            "
            @click="closeIfUserNotLoggedIn"
          />

          <button-component
            v-if="!onCrossPublishedSite && isModeratorOrAdmin && !userIsOnTheirOwnProfile"
            class="profile-tooltip__flag-user-button"
            data-test-id="profile-tooltip-flag-user-button"
            @click="reportContentOrUser"
          >
            <icon-component
              name="flag"
              title="flag icon"
              class="profile-tooltip__flag-user-icon icon--small"
            />
            Flag User
          </button-component>
        </div>
      </div>
    </div>
  </dialog-base>
</template>

<script>
import DialogBase from '@/components/Global/DialogBase.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import RoleBadge from '@/components/User/RoleBadge.vue';
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import FollowButton from '@/components/User/Following/FollowButton.vue';
import StartConversationButton from '@/components/PrivateMessaging/StartConversationButton.vue';
import moderationActionsMixin from '@/mixins/moderation-actions-mixin';
import { isFeatureFlagEnabled } from '@/utils/utils';
import { GET_USER_SUMMARY_QUERY } from '@/graphql/queries/user-queries';
import { typeToAssocType } from '@/utils/moderation-helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileTooltip',
  components: {
    DialogBase,
    UserAvatar,
    RoleBadge,
    ButtonComponent,
    FollowButton,
    StartConversationButton,
  },
  mixins: [moderationActionsMixin],
  data() {
    return {
      userId: this.$store.getters.profileTooltipUserId,
      isCrossPublished: this.$store.getters.isCrossPublished,
      originalSite: this.$store.getters.originalSite,
      user: {},
      memberUnavailable: false,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'isAdmin',
      'isModeratorOrAdmin',
      'siteHasFeature',
      'getSiteSummary',
      'currentUser',
    ]),
    showViewAuthorArchive() {
      return ['moderator_contributor', 'contributor'].includes(this.user.badge) || ['author', 'contributor', 'administrator'].includes(this.user.legacyRole);
    },
    followedByCurrentUser() {
      return this.user.isFollowed;
    },
    onCrossPublishedSite() {
      return this.originalSite && this.$site.prefix !== this.originalSite;
    },
    isEditorialTeam() {
      return this.user.username === 'Editorial Team';
    },
    userIsOnTheirOwnProfile() {
      return this.user.id === this.currentUser.id;
    },
    moderation() {
      return {
        assocId: this.$store.getters.profileTooltipUserId,
        assocType: typeToAssocType('user'),
      };
    },
    ariaLabelText() {
      return `Profile tooltip for ${this.user.username}`;
    },
    profileBelongsToAdmin() {
      return [1, 5, 6].includes(this.user.roleId);
    },
    showsPrivateMessaging() {
      return !isFeatureFlagEnabled(this.$site, 'restrict_private_messaging') || this.isAdmin || this.profileBelongsToAdmin;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('removeProfileTooltip');
    },
    closeIfUserNotLoggedIn() {
      if (!this.userIsLoggedIn) {
        this.close();
      }
    },
    refetchUserSummary() {
      this.$apollo.queries.user.refetch();
    },
    siteHost(prefix) {
      return this.getSiteSummary(prefix) && this.getSiteSummary(prefix).host;
    },
    crossPublishingAuthorIndex(user, site) {
      return `https://${this.siteHost(site)}/author/${user.slug}`;
    },
    crossPublishingAuthorProfile(user, site) {
      return `https://${this.siteHost(site)}/members/${user.slug}`;
    },
  },
  apollo: {
    user: {
      query: GET_USER_SUMMARY_QUERY,
      variables() {
        return {
          id: this.userId,
          originalSite: this.originalSite,
          ignore404: true,
        };
      },
      // "loading" does not increment when using a fetchPolicy -> apollo-client bug
      fetchPolicy: 'no-cache',
      error(error, vm) {
        // eslint-disable-next-line no-param-reassign
        vm.memberUnavailable = true;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_profile-tooltip';
</style>

<docs>
</docs>
