const getDefaultUser = () => ({
  id: '',
  slug: '',
  username: '',
  fname: '',
  lname: '',
  email: '',
  avatar: {
    thumb: '',
    full: '',
  },
  roleId: '',
  legacyRole: '',
  insertedAt: '',
  bookmarks: [],
  token: '',
  sessionId: '',
  bio: '',
  ethnicity: '',
  gender: '',
  birthday: '',
  zipcode: '',
  openConfirmationDialog: false,
  loggedInFromSocial: false,
});

const currentUserState = () => ({
  user: getDefaultUser(),
  loggedIn: false,
  loaded: false,
  userHasSubscribed: false,
  userHasBeenLoggedIn: false,
  userWasReferredByEmail: false,
  authDialogWasShown: false,
  hasLeadGenPopUpDisabledCookie: false,
  firstTimeLoggingIntoSHN: false,
});

const getters = {
  currentUser: (state) => state.user,
  // Note that users without username should appear logged out
  userIsLoggedIn: (state) => state.loggedIn && !!state.user.username,
  userIsUnverified: (state) => state.loggedIn && !state.user.isVerified,
  userHasSubscribed: (state) => state.userHasSubscribed,
  userHasBeenLoggedIn: (state) => state.userHasBeenLoggedIn,
  userWasReferredByEmail: (state) => state.userWasReferredByEmail,
  authDialogWasShown: (state) => state.authDialogWasShown,
  hasLeadGenPopUpDisabledCookie: (state) => state.hasLeadGenPopUpDisabledCookie,
  firstTimeLoggingIntoSHN: (state) => state.firstTimeLoggingIntoSHN,
  isModeratorOrAdmin: (state) => ['1', '2', '5', '6'].includes(state.user.roleId),
  isAdmin: (state) => ['1', '5', '6'].includes(state.user.roleId),
  isModerator: (state) => state.user.roleId === '2',
  userToken: (state) => state.user.token,
  userWasChecked: (state) => state.loaded,
  loggedInFromSocial: (state) => state.user.loggedInFromSocial,
  visitorType: (state) => {
    if (!state.loggedIn) {
      return 'visitor-logged-out';
    }
    const visitorTypesByRoleId = {
      1: 'Administrator',
      2: 'Moderator',
      3: 'Member',
      4: 'Spammer',
      5: 'Super Admin',
      6: 'Community Tech',
    };
    return visitorTypesByRoleId[state.user.roleId];
  },
};

const mutations = {
  setUserLoggedIn(state, data) {
    state.loggedIn = data;
  },
  setCurrentUser(state, data) {
    // merge data with user and spread to ensure getter reactivity
    state.user = { ...Object.assign(state.user, data) };
  },
  setUserLoaded(state, data) {
    state.loaded = data;
  },
  setUserHasSubscribed(state, data) {
    state.userHasSubscribed = data;
  },
  setUserHasBeenLoggedIn(state, data) {
    state.userHasBeenLoggedIn = data;
  },
  setUserWasReferredByEmail(state, data) {
    state.userWasReferredByEmail = data;
  },
  setAuthDialogWasShown(state, data) {
    state.authDialogWasShown = data;
  },
  setHasLeadGenPopUpDisabledCookie(state, data) {
    state.hasLeadGenPopUpDisabledCookie = data;
  },
  setFirstTimeLoggingIntoSHN(state, data) {
    state.firstTimeLoggingIntoSHN = data;
  },
  addBookmarkToCurrentUser(state, data) {
    state.user.bookmarks.push(data);
  },
  removeBookmarkFromCurrentUser(state, data) {
    // IE11 support - findIndex would be a cleaner solution
    let index = -1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < state.user.bookmarks.length; ++i) {
      if (state.user.bookmarks[i].assocId === data.assocId
        && state.user.bookmarks[i].assoctype === data.assoctype) {
        index = i;
        break;
      }
    }
    state.user.bookmarks.splice(index, 1);
  },
  openConfirmationDialog(state, data) {
    state.user.openConfirmationDialog = data;
  },
  closeConfirmationDialog(state, data) {
    state.user.openConfirmationDialog = data;
  },
  verifyUser(state) {
    state.user.isVerified = true;
  },
  setLoggedInFromSocialToTrue(state, data) {
    state.user.loggedInFromSocial = data;
  },
};

const actions = {
  clearCurrentUser({ commit, dispatch }, data) {
    commit('setCurrentUser', { ...getDefaultUser(), ...data });
    commit('setUserLoggedIn', false);
    dispatch('updateUserContext');
  },
  setCurrentUser({ commit, dispatch }, data) {
    if (data?.id) {
      commit('setUserLoggedIn', true);
      commit('setUserHasBeenLoggedIn', true);
    }
    commit('setCurrentUser', data);
    dispatch('updateUserContext');
    commit('setSubscriptionWallStatus', false, { root: true });
  },
  setUserLoaded({ commit }) {
    commit('setUserLoaded', true);
  },
  setUserHasSubscribed({ commit }, data) {
    commit('setUserHasSubscribed', data);
    commit('setSubscriptionWallStatus', !data, { root: true });
  },
  setUserHasBeenLoggedIn({ commit }) {
    commit('setUserHasBeenLoggedIn', true);
  },
  setUserWasReferredByEmail({ commit }) {
    commit('setUserWasReferredByEmail', true);
  },
  setAuthDialogWasShown({ commit }) {
    commit('setAuthDialogWasShown', true);
  },
  setHasLeadGenPopUpDisabledCookie({ commit }) {
    commit('setHasLeadGenPopUpDisabledCookie', true);
  },
  setFirstTimeLoggingIntoSHN({ commit }, data) {
    commit('setFirstTimeLoggingIntoSHN', data);
  },
  checkForCurrentUser({ dispatch }, _vue) {
    // 'this' is probably not the Vue instance...
    return _vue.$auth.checkSession().then((response) => {
      if (!response) {
        _vue.$logger.error('NEXUS-3962 Unexpected response from $auth.checkSession');
        dispatch('clearCurrentUser');
      } else if (response.data.status === 'error') {
        // eslint-disable-next-line no-param-reassign
        delete response.data.status;
        dispatch('clearCurrentUser', response.data);
      } else {
        dispatch('setCurrentUser', response.data.user);
      }
      if (response?.headers['set-cookie'] && _vue.$options?.context?.req?.res) {
        try {
          _vue.$options.context.req.res.setHeader('set-cookie', response.headers['set-cookie']);
        } catch {
          // probably a redirect and headers already sent
        }
      }
      dispatch('setUserLoaded');
    });
  },
  addBookmarkToCurrentUser({ commit }, data) {
    commit('addBookmarkToCurrentUser', data);
  },
  removeBookmarkFromCurrentUser({ commit }, data) {
    commit('removeBookmarkFromCurrentUser', data);
  },
  openConfirmationDialog({ commit }) {
    commit('openConfirmationDialog', true);
  },
  closeConfirmationDialog({ commit }) {
    commit('closeConfirmationDialog', false);
  },
  verifyUser({ commit }) {
    commit('verifyUser');
  },
  setLoggedInFromSocial({ commit }) {
    commit('setLoggedInFromSocialToTrue', true);
  },
};

export default () => ({
  state: currentUserState(),
  getters,
  mutations,
  actions,
});
