import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['siteHasFeature']),
    profileTooltipEnabled() {
      return this.showTooltip && this.siteHasFeature('Accounts');
    },
  },
  methods: {
    openUserProfileTooltip(event) {
      if (!this.profileTooltipEnabled) {
        return;
      }
      // destructure the bounding rect DOMRect into an object
      const {
        top, right, bottom, left,
      } = event.target.getBoundingClientRect();

      this.$store.dispatch('addProfileTooltip', {
        userId: this.user ? this.user.id : this.userId,
        isCrossPublished: this.isCrossPublished,
        originalSite: this.originalSite,
        position: {
          top, right, bottom, left,
        },
      });
    },
  },
};
