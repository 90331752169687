// numbers must always match up with stylesheets/tools/_media_queries.scss
function isTablet() {
  return window.innerWidth >= 600;
}

function isDesktop() {
  return window.innerWidth >= 900;
}

export {
  isTablet,
  isDesktop,
};
