<template>
  <client-only>
    <span>{{ timestamp }}</span>
  </client-only>
</template>

<script>
import {
  formatDistanceToNow,
  differenceInDays,
  isSameDay,
  isYesterday,
  format,
} from 'date-fns';

const validFormats = ['dateAndTime', 'timeOrShortDate', 'timeOrDateTime'];

export default {
  name: 'TimeStamp',
  props: {
    date: {
      type: String,
      required: true,
    },
    showDateStamp: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '',
      validator: (value) => !value || validFormats.includes(value),
    },
  },
  computed: {
    inLastWeek() {
      return differenceInDays(new Date(), new Date(this.date)) <= 7;
    },
    timeOrShortDate() {
      const shortDate = format(new Date(this.date), 'MMM d');

      if (isSameDay(new Date(this.date), new Date())) {
        return format(new Date(this.date), 'p');
      }
      return shortDate;
    },
    timeOrDateTime() {
      const DateTime = format(new Date(this.date), 'MMMM d, p');

      if (isSameDay(new Date(this.date), new Date())) {
        return format(new Date(this.date), 'p');
      }
      return DateTime;
    },
    dateAndTime() {
      const day = format(new Date(this.date), 'MMMM d, y');
      const time = format(new Date(this.date), 'p');

      if (isSameDay(new Date(this.date), new Date())) {
        return `Today, ${time}`;
      }
      if (isYesterday(new Date(this.date), new Date())) {
        return `Yesterday, ${time}`;
      }
      return `${day} ${time}`;
    },
    daysAgo() {
      return `${formatDistanceToNow(new Date(this.date))} ago`;
    },
    dateStamp() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      return new Date(this.date).toLocaleString('en-US', options);
    },
    formattedDate() {
      if (this.format === 'timeOrShortDate') {
        return this.timeOrShortDate;
      }
      if (this.format === 'timeOrDateTime') {
        return this.timeOrDateTime;
      }
      if (this.format === 'dateAndTime') {
        return this.dateAndTime;
      }
      return this.dateStamp;
    },
    timestamp() {
      if (this.format) {
        return this.formattedDate;
      }
      if (this.inLastWeek) {
        return this.daysAgo;
      }
      if (this.showDateStamp) {
        return this.dateStamp;
      }
      return null;
    },
  },
};
</script>

<docs>
</docs>
