<template>
  <p class="disclaimer-text">
    <a
      class="auth-dialog__disclaimer-link"
      href="https://health-union.com/privacy-policy/"
      target="_blank"
      rel="noopener"
    >Privacy policy</a>
    and
    <a
      class="auth-dialog__disclaimer-link"
      href="https://health-union.com/terms-of-use/"
      target="_blank"
      rel="noopener"
    >terms of use</a>.
  </p>
</template>

<script>
export default {
  name: 'PrivacyPolicyAndTermsOfUseLinks',
};
</script>

<docs>
Links to PP and TOU.

</docs>
