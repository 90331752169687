// The winston logger will only work in SSR, client-side logs should never be sent to winston
import { createLogger, format, transports } from 'winston';
import DatadogBrowserTransport from '@/services/datadog-browser-winston-transport';

function extractReqInfo(req) {
  if (!req) { return {}; }

  const useragent = req.headers['user-agent'];
  const headersCopy = { ...req.headers };
  delete headersCopy['user-agent'];
  delete headersCopy.cookie;

  return {
    http: {
      url: req.url,
      method: req.method,
      useragent,
      ...headersCopy,
    },
    network: {
      client: {
        ip: headersCopy['x-forwarded-for'],
      },
    },
  };
}

const {
  combine, timestamp, json, errors,
} = format;

export default function WinstonLog(context) {
  let logger;
  if (process.browser) {
    logger = createLogger({
      exitOnError: true,
      format: combine(timestamp(), json()),
      transports: [
        new DatadogBrowserTransport(),
      ],
    });
  } else {
    logger = createLogger({
      exitOnError: true,
      format: combine(timestamp(), errors({ stack: true }), json()),
      transports: [
        new transports.Console(),
      ],
      defaultMeta: {
        metadata: {
          ssr: true,
          ...extractReqInfo(context.req),
        },
      },
    });
  }

  return logger;
}
