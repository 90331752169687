const navigationState = () => ({
  open: false,
  expandedNavItems: [],
});

const getters = {
  navOpen: (state) => state.open,
  expandedNavItems: (state) => state.expandedNavItems,
};

const mutations = {
  setNavOpen(state, data) {
    state.open = data;
  },
  setExpandedNavItems(state, data) {
    state.expandedNavItems = data;
  },
};

const actions = {
  setNavOpen({ commit }, data) {
    commit('setNavOpen', data);
  },
  setExpandedNavItems({ commit }, data) {
    commit('setExpandedNavItems', data);
  },
};

export default () => ({
  state: navigationState(),
  getters,
  mutations,
  actions,
});
