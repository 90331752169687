const siteListState = () => ({
  sites: {},
});

const getters = {
  getSiteSummary: (state) => (prefix) => state.sites[prefix],
};

const mutations = {
  setSites(state, data) {
    state.sites = data;
  },
};

const actions = {
  formatSiteListArrayAndSetSites({ commit }, data) {
    const formattedSiteList = {};

    data.forEach((site) => {
      formattedSiteList[site.prefix] = site;
    });

    commit('setSites', formattedSiteList);
  },
};

export default () => ({
  state: siteListState(),
  getters,
  mutations,
  actions,
});
