import gql from 'graphql-tag';

export const GET_ALL_MENU_ITEMS = gql`
fragment MenuItemFields on MenuItem { menuId title url }
{
 menu{
   items {
    ... MenuItemFields
    items {
      ... MenuItemFields
      items {
        ... MenuItemFields
        items {
          ... MenuItemFields
          items {
            ... MenuItemFields
          }
        }
      }
    }
  }
 }
}`;
