<template>
  <div class="site-logo">
    <router-link
      v-if="link"
      to="/"
      class="site-logo__link link link--unstyled"
    >
      <img
        :src="logoSrc"
        :alt="this.$site.name"
        :width="logoSize"
        :height="logoSize"
        class="site-logo__image"
        data-test-id="site-logo-image"
      >
    </router-link>
    <img
      v-else
      :src="logoSrc"
      :alt="this.$site.name"
      class="site-logo__image"
      data-test-id="site-logo-image"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SiteLogo',
  props: {
    link: {
      type: Boolean,
      default: false,
    },
    showSecondaryShnLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logoSize: 48,
      logoSrc: '/images/logo_avatar.svg',
    };
  },
  computed: {
    ...mapGetters([
      'siteHasFeature',
    ]),
  },
  created() {
    if (this.siteHasFeature('social_network') && this.showSecondaryShnLogo) {
      this.logoSrc = '/images/shn-logo-v2.svg';
    }
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_site-logo';
</style>

<docs>
Renders the site logo

</docs>
