<template>
  <button
    class="button"
    :disabled="loading"
    @click="handleClick"
  >
    <loading-spinner v-if="loading" class="loading-spinner--white" />
    <slot v-else>Submit</slot>
  </button>
</template>

<script>

export default {
  name: 'LoadingButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * buttonType is being
     * used for snowplow button
     * tracking below.
     * this is not a replacement
     * for the button type attr
     */
    buttonType: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick(event) {
      this.$snowplow.trackButtonEvent({
        data: {
          type: `${this.buttonType}`,
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('click');
    },
  },
};
</script>
