<template>
  <dialog-base
    class="dialog-base--image-upload"
    @close="close"
  >
    <template v-slot:heading>Upload an image</template>
    <image-upload-form />
  </dialog-base>
</template>

<script>
import DialogBase from '@/components/Global/DialogBase.vue';
import ImageUploadForm from '@/components/ImageUpload/ImageUploadForm.vue';

export default {
  name: 'ImageUploadDialog',
  components: {
    DialogBase,
    ImageUploadForm,
  },
  methods: {
    close() {
      this.$store.dispatch('closeImageUploadDialog');
    },
  },
};
</script>

<docs>
</docs>
