<template>
  <div class="crisis-response-banner">
    <!-- The element below should be a div and not a p tag
      to protect against invalid markup if the content
      contains one.
    -->
    <div
      class="crisis-response-banner__text"
      v-html="crisisResponseBanner"
    />
  </div>
</template>

<script>
export default {
  name: 'CrisisResponseBanner',
  computed: {
    crisisResponseBanner() {
      return this.$site.settings.crisis_response_banner;
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '@/stylesheets/components/_crisis-response-banner';
</style>

<docs>
</docs>
