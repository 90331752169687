function typeToAssocType(type) {
  switch (type) {
    case 'comment':
      return 'COMMENT';
    case 'reply':
      return 'REPLY';
    case 'thread':
      return 'THREAD';
    case 'user':
      return 'USER';
    case 'user_profile_status':
      return 'STATUS_UPDATE';
    case 'user_profile_status_reply':
      return 'STATUS_UPDATE_REPLY';
    default:
      return '';
  }
}

function assocTypeToText(assocType) {
  const words = assocType.split('_');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
}

function moderationUrl(path) {
  return `${window.location.origin}/moderation/${path}`;
}

function openInModeration(path, target = '_blank') {
  window.open(moderationUrl(path), target);
}

export {
  typeToAssocType,
  assocTypeToText,
  moderationUrl,
  openInModeration,
};
