const forumsState = () => ({
  refetchForumIndex: false,
  selectedTags: [],
});

const getters = {
  refetchForumIndex: (state) => state.refetchForumIndex,
  selectedTags: (state) => state.selectedTags,
};

const mutations = {
  setRefetchForumIndex(state, data) {
    state.refetchForumIndex = data;
  },
  setSelectedTags(state, data) {
    state.selectedTags = data;
  },
  removeSelectedTag(state, data) {
    state.selectedTags.splice(state.selectedTags.indexOf(data), 1);
  },
};

const actions = {
  setRefetchForumIndex({ commit }, data) {
    commit('setRefetchForumIndex', data);
  },
  setSelectedTags({ commit }, data) {
    commit('setSelectedTags', data);
  },
  removeSelectedTag({ commit }, data) {
    commit('removeSelectedTag', data);
  },
};

export default () => ({
  state: forumsState(),
  getters,
  mutations,
  actions,
});
