<template>
  <div class="account-confirmation">
    <div v-if="!siteHasFeature('social_network')" class="account-confirmation__container">
      <router-link
        class="account-confirmation__cta"
        data-test-id="account-confirmation-cta"
        :to="ctaLink"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/bubbles.png"
          alt=""
          class="account-confirmation__cta-image"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text">
          {{ ctaText }}
        </div>
      </router-link>
      <router-link
        v-if="!siteHasFeature('mental_health')"
        class="account-confirmation__cta"
        data-test-id="account-confirmation-cta"
        :to="{ name: 'community' }"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/megaphone.png"
          alt=""
          class="account-confirmation__cta-image"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text">
          See what's new in the community
        </div>
      </router-link>
      <router-link
        class="account-confirmation__cta"
        data-test-id="account-confirmation-cta"
        :to="{ name: 'userProfileOverview' }"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/pencil.png"
          alt=""
          class="account-confirmation__cta-image"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text">
          Set up your profile
        </div>
      </router-link>
    </div>
    <div v-else class="account-confirmation__container account-confirmation__container--shn">
      <router-link
        class="account-confirmation__cta account-confirmation__cta--shn"
        data-test-id="account-confirmation-cta"
        :to="{ name: 'userProfileOverview' }"
        @click.native="handleClickingCTA"
      >
        <img
          src="/common/nexus/profile-emblem.png"
          alt=""
          class="account-confirmation__cta-image account-confirmation__cta-image--shn"
          loading="lazy"
        >
        <div
          class="account-confirmation__cta-text account-confirmation__cta-text--shn"
          data-test-id="shn-account-confirmation-cta-text"
        >
          Set up your profile
        </div>
      </router-link>
      <a
        :href="dashboardURL"
        class="account-confirmation__cta"
        data-test-id="shn-account-confirmation-cta-text"
      >
        <img
          src="/common/nexus/resources-emblem.png"
          alt=""
          class="account-confirmation__cta-image account-confirmation__cta-image--shn"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text account-confirmation__cta-text--shn">
          Check out Health Leader resources
        </div>
      </a>
      <a
        :href="`${dashboardURL}/channels/46`"
        class="account-confirmation__cta"
        data-test-id="shn-account-confirmation-cta-text"
      >
        <img
          src="/common/nexus/patient-leader-certification.png"
          alt=""
          class="account-confirmation__cta-image account-confirmation__cta-image--shn"
          loading="lazy"
        >
        <div class="account-confirmation__cta-text account-confirmation__cta-text--shn">
          Complete Patient Leader Certification
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import authRedirectMixin from '@/mixins/auth-redirect-mixin';

export default {
  name: 'AccountConfirmation',
  mixins: [authRedirectMixin],
  data() {
    return {
      dashboardURL: '/api/dashboard-redirect',
    };
  },
  computed: {
    ...mapGetters(['siteHasFeature']),
    ctaText() {
      return this.siteHasFeature('mental_health') ? 'Read recent articles' : 'Visit the forums';
    },
    ctaLink() {
      return this.siteHasFeature('mental_health') ? '/page/1' : { name: 'forums' };
    },
  },
  mounted() {
    this.handleLoginRedirect();
  },
  methods: {
    handleClickingCTA() {
      this.$store.dispatch('closeAuthDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_account-confirmation';
</style>
