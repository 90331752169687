<template>
  <div>
    <loading-spinner v-if="loading !== 0 || !conversation" />
    <div
      v-else
      :id="`single-conversation-${conversation.id}`"
      role="tabpanel"
      :aria-labelledby="`conversation-summary-${conversation.id}`"
      class="single-conversation"
      :class="{ 'single-conversation--am-blocked' : conversation.amBlocked }"
    >
      <div
        v-if="conversation.amBlocked"
        class="single-conversation__blocked-alert"
      >
        <h3 class="single-conversation__blocked-heading">
          Private Message with {{ conversation.user.username }}
        </h3>
        <div class="single-conversation__blocked-message">
          <icon-component
            name="x"
            class="single-conversation__blocked-icon"
          />
          <p>
            This recipient has declined to receive messages from you at this time.
          </p>
        </div>
      </div>
      <div
        v-if="isReceiver && messageDeclined"
        class="single-conversation__blocked-alert"
      >
        <p>
          This message has been removed and you will not
          receive future requests from this person.
        </p>
      </div>
      <div
        v-if="conversation.isArchived && !messageDeclined"
        class="single-conversation__archived-alert"
      >
        <div class="single-conversation__archived-message">
          <p>
            This message has been archived
          </p>
        </div>
      </div>
      <conversation-approval
        v-if="newConversation"
        :conversation-id="conversationId"
        @blocked="recipientDeclinedMessage"
      />
      <p
        v-else-if="messages.length === 0"
        class="single-conversation__pop-over-disclaimer disclaimer-text"
      >
        By clicking send, you represent that your message complies with our
        <a
          href="https://health-union.com/terms-of-use/"
          target="_blank"
          rel="noopener"
        >Terms of Use</a> and
        <a href="https://health-union.com/community-rules/"
           target="_blank"
           rel="noopener noreferrer nofollow"
        >
          Community Rules.</a>
      </p>
      <transition-group
        ref="messageList"
        class="single-conversation__messages list-reset"
        tag="ul"
        name="single-conversation__messages"
        aria-role="log"
      >
        <li
          v-for="(message, index) in messages"
          :key="`message-${message.id}`"
          class="single-conversation__message-container"
        >
          <time-stamp
            v-if="showTimeStamp(message, index)"
            :date="message.insertedAt"
            format="timeOrDateTime"
            class="single-conversation__timestamp"
          />
          <single-message
            :message="message"
            :user="conversation.user"
            :open-reactions-above="reactionsMenuCouldOverflow(index)"
          />
        </li>
      </transition-group>
      <new-message
        v-if="userAvailable"
        :conversation-id="conversationId"
        :disabled="conversation.amBlocked"
      />
      <div v-else class="single-conversation__unavailable-user-warning">
        <p>This member is no longer available.</p>
      </div>
    </div>
  </div>
</template>

<script>
import TimeStamp from '@/components/Global/TimeStamp.vue';
import SingleMessage from '@/components/PrivateMessaging/SingleMessage.vue';
import NewMessage from '@/components/PrivateMessaging/NewMessage.vue';
import ConversationApproval from '@/components/PrivateMessaging/ConversationApproval.vue';
import { mapGetters } from 'vuex';
import { GET_CONVERSATION } from '@/graphql/queries/private-messaging-queries';
import { MESSAGES_SUBSCRIPTION } from '@/graphql/subscriptions/private-messaging-subscriptions';
import differenceInMinutes from 'date-fns/differenceInMinutes';

export default {
  name: 'SingleConversation',
  components: {
    TimeStamp,
    SingleMessage,
    NewMessage,
    ConversationApproval,
  },
  props: {
    conversationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      height: 0,
      loading: 0,
      conversation: {},
      messages: [],
      messageDeclined: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'websocketState',
    ]),
    newConversation() {
      return !this.conversation.isAccepted && !this.conversation.isArchived;
    },
    isReceiver() {
      if (this.messages.length > 0) {
        const lastMessage = this.messages[this.messages.length - 1];
        return lastMessage.userId !== this.currentUser.id;
      }
      return false;
    },
    userAvailable() {
      return this.conversation.user
        && !this.conversation.user.isDeleted
        // User roleId 4 is "blocked"
        && this.conversation.user.roleId !== 4;
    },
  },
  watch: {
    websocketState(to, from) {
      if (to === 'open' && from === 'error') {
        this.$apolloProvider.defaultClient.reFetchObservableQueries();
      }
      if (from === 'open' && to === 'error') {
        // if the WS continues to be in the error state for 10 minutes
        // we should disconnect it so we dont spam.
        // If the WS is re-opened before the 10 minutes, then we will refetch.
        window.setTimeout(() => {
          this.$apolloProvider.phoenixSocket.disconnect();
        }, 100000);
      }
    },
  },
  created() {
    this.$apollo.queries.conversation.refetch();
  },
  methods: {
    // Only display the timestamp if the difference in insertedAt time between
    // the current and previous message is more than a minute.
    showTimeStamp(message, index) {
      if (index === 0) { return true; }
      const previousMessage = this.messages[index - 1];
      const timeBetween = differenceInMinutes(
        new Date(message.insertedAt),
        new Date(previousMessage.insertedAt),
      );

      return timeBetween >= 1;
    },
    scrollToLastMessage() {
      if (!this.$refs.messageList) { return; }
      this.$refs.messageList.$el.scrollTop = this.$refs.messageList.$el.scrollHeight;
    },
    reactionsMenuCouldOverflow(index) {
      return this.messages.length > 4 && index >= this.messages.length - 3;
    },
    recipientDeclinedMessage(declined) {
      this.messageDeclined = declined;
    },
  },
  apollo: {
    conversation: {
      query: GET_CONVERSATION,
      variables() {
        return {
          id: this.conversationId,
        };
      },
      result({ data }) {
        if (data && data.conversation) {
          this.messages = data.conversation.messages;
          this.$nextTick(() => {
            this.scrollToLastMessage();
          });
        }
      },
      subscribeToMore: {
        document: MESSAGES_SUBSCRIPTION,
        variables() {
          return {
            conversationId: this.conversationId,
          };
        },
        updateQuery: (cache, { subscriptionData }) => {
          const { messageCreated } = subscriptionData.data;
          const updatingIndex = cache.conversation.messages.findIndex(
            (message) => message.id === messageCreated.id,
          );

          if (updatingIndex >= 0) {
            cache.conversation.messages.splice(updatingIndex, 1, messageCreated);
          } else {
            cache.conversation.messages.push(messageCreated);
          }

          return cache;
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_single-conversation';
</style>

<docs>
</docs>
