<template>
  <div
    v-click-outside="onClickOutside"
    class="reaction-menu"
    :class="{ 'reaction-menu--open': reactionListOpen }"
  >
    <reaction-button
      :reaction-count="totalReactions"
      :selected-reaction="selectedReaction"
      :content-type="contentType"
      :aria-expanded="reactionListOpen ? 'true' : 'false'"
      aria-controls="reaction-menu-options"
      @toggle-reaction-menu="toggleMenu"
    />
    <ul
      v-if="reactionListOpen"
      id="reaction-menu-options"
      ref="menu"
      class="reaction-menu__options"
      :class="{
        'reaction-menu__options--up' : menuOpensAbove,
        'reaction-menu__options--left' : menuOpensLeft,
      }"
    >
      <reaction-option
        v-for="reactionType in Object.keys(reactions)"
        :id="reactions[reactionType].id"
        :key="`${reactions[reactionType].id}-reaction-menu`"
        :type="reactionType"
        :count="reactions[reactionType].count"
        :is-selected="reactionType === selectedReaction"
        @remove-reaction="removeReactionAndCloseMenu(reactionType)"
        @select-reaction="selectReactionAndCloseMenu(reactionType)"
      />
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import ReactionButton from '@/components/Reactions/ReactionButton.vue';
import ReactionOption from '@/components/Reactions/ReactionOption.vue';
import reactionMixin from '@/mixins/reaction-mixin';

export default {
  name: 'ReactionMenu',
  components: {
    ReactionOption,
    ReactionButton,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [reactionMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
    menuDirectionOverride: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reactionListOpen: false,
      menuOpensAbove: false,
      menuOpensLeft: false,
    };
  },
  methods: {
    toggleMenu() {
      this.reactionListOpen = !this.reactionListOpen;
      if (this.reactionListOpen) {
        this.$nextTick(this.setMenuDirection);
      }
      if (!this.reactionListOpen) {
        this.menuOpensAbove = false;
        this.menuOpensLeft = false;
      }
    },
    setMenuDirection() {
      if (this.menuDirectionOverride) {
        this.menuOpensAbove = this.menuDirectionOverride === 'above';
        this.menuOpensLeft = this.menuDirectionOverride === 'left';
      }
      if (this.$refs.menu) {
        const menuSize = this.$refs.menu.getBoundingClientRect();
        if (menuSize.bottom <= document.documentElement.clientHeight) {
          this.menuOpensAbove = false;
        } else {
          this.menuOpensAbove = true;
        }

        if (menuSize.right <= document.documentElement.clientWidth) {
          this.menuOpensLeft = false;
        } else {
          this.menuOpensLeft = true;
        }
      }
    },
    removeReactionAndCloseMenu(reactionType) {
      this.removeReaction(reactionType).then(() => {
        this.toggleMenu();
      });
    },
    selectReactionAndCloseMenu(reactionType) {
      this.selectReaction(reactionType);
      this.toggleMenu();
    },
    onClickOutside() {
      if (!this.reactionListOpen) { return; }
      this.toggleMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_reaction-menu';
</style>

<docs>

ReactionMenu is a top level component that calls ReactionOptions and ReactionButton.
The component handles rendering a list of ReactionOptions (which can be opened and closed)
It uses the reactionMixin

</docs>
