import { loggerApi } from '@/plugins/logger';

export default (context) => {
  // eslint-disable-next-line no-unused-vars
  const logger = loggerApi({ context });

  // This module stores $site because we sometimes need access to $site things in
  // vuex and cannot use this._vm.$site now since the _vm is a different vue instance
  // and $site is no longer on the global Vue due to SSR.
  const siteState = () => ({
    site: {},
    siteSortOrders: {
      'Sleep-Disorders.net': [
        'nsd', 'isd', 'rlssd', 'sasd',
      ],
      'Mental-Health-Community.com': [
        'bip', 'dep', 'ppd', 'sch',
      ],
    },
  });

  const getters = {
    site: (state) => state.site,
    siteHasFeature: (state) => (feature) => {
      if (typeof state.site.features === 'undefined') {
        logger.error('Sites query did not finish yet');
        return false;
      }
      return state.site.features.some(
        (f) => f.replace(/\W/g, '_').toLowerCase() === feature.replace(/\W/g, '_').toLowerCase(),
      );
    },
    isParentSite: (state) => {
      if (typeof state.site.childSites === 'undefined') {
        logger.error('Sites query did not finish yet');
        return false;
      }
      return state.site.childSites.length > 0;
    },
    isChildSite: (state) => {
      if (typeof state.site.parentSite === 'undefined') {
        logger.error('Sites query did not finish yet');
        return false;
      }
      return !!state.site.parentSite;
    },
    isFamilySite: (state) => state.site.childSites.length > 0 || state.site.parentSite,
    siteSortOrders: (state) => (siteName) => {
      if (!state.siteSortOrders[siteName] && state.site.parentSite) {
        return state.siteSortOrders[state.site.parentSite.name];
      }

      return state.siteSortOrders[siteName];
    },
  };

  const mutations = {
    setSite(state, data) {
      state.site = data;
    },
  };

  const actions = {
    setSite({ commit }, data) {
      commit('setSite', data);
    },
  };

  return {
    state: siteState(),
    getters,
    mutations,
    actions,
  };
};
