<template>
  <div class="auth-wall-login-prompt">
    <img
      src="/common/nexus/auth_wall_lock.png"
      alt=""
      loading="lazy"
      width="210"
      height="218"
      class="auth-wall-login-prompt__image"
    >
    <h2 class="auth-wall-login-prompt__heading">Join {{ $site.name }}</h2>
    <p class="auth-wall-login-prompt__text">Create an account to get full access. It's free and easy to do!</p>

    <button
      class="auth-wall-login-prompt__register-button button"
      @click="handleClickingRegister"
    >
      Create an account
    </button>

    <p>
      <strong>Already a member?</strong>
      &nbsp;
      <button
        class="text-button text-button--link-style"
        @click="handleClickingLogin"
      >
        Log in
      </button>
    </p>
  </div>
</template>

<script>

export default {
  name: 'AuthWallLoginPrompt',
  methods: {
    handleClickingRegister() {
      this.$store.dispatch('openRegisterDialog', { progressIndicatorSteps: 2 });
      this.$store.dispatch('setFastTrackToContent', true);
    },
    handleClickingLogin() {
      this.$store.dispatch('openAuthDialog');
      this.$store.dispatch('setFastTrackToContent', true);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_auth-wall-login-prompt';
</style>
