const experimentState = () => ({});

const getters = {
  getVariants: (state) => state,
  getVariantKey: (state) => (symbol) => state[symbol]?.key,
};

const mutations = {
  setVariant(state, data) {
    state[data.symbol] = { key: data.key, experimentId: data.experimentId };
  },
};

export default () => ({
  state: experimentState(),
  getters,
  mutations,
});
