<template>
  <div class="register-options">
    <social-login google-button-text="Sign up with Google" facebook-button-text="Sign up with Facebook" />
    <email-login-button button-text="Sign up with email" @click="goToRegisterWithEmail" />
    <progress-indicator v-if="!siteHasFeature('social_network')" current-step="1" />
  </div>
</template>

<script>

import SocialLogin from '@/components/Social/SocialLogin.vue';
import EmailLoginButton from '@/components/Auth/EmailLoginButton.vue';
import ProgressIndicator from '@/components/Auth/ProgressIndicator.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'RegisterOptions',
  components: {
    SocialLogin,
    EmailLoginButton,
    ProgressIndicator,
  },
  computed: {
    ...mapGetters(['siteHasFeature']),
  },
  methods: {
    goToRegisterWithEmail() {
      this.$store.dispatch('openAuthDialog', { authStep: 'registerWithEmail' });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_register-options';
</style>

<docs>
Form for users to pick a login method for new registration flow

</docs>
