import gql from 'graphql-tag';

export const MESSAGES_SUBSCRIPTION = gql`
subscription onMessageCreated($conversationId: NexusId!) {
  messageCreated(conversationId: $conversationId) {
    id
    body
    userId
    insertedAt
    reactions {
      type
      user {
        id
      }
    }
  }
}
`;

export const CONVERSATIONS_SUBSCRIPTION = gql`
subscription onConversationsSubscription {
  conversationsSubscription {
    conversationId
    insertedAt
    isRead
    isArchived
    isBlocked
    lastMessageAt
    lastMessageBody
    user {
      id
      username
      avatar {
        thumb
      }
    }
  }
}
`;
