<template>
  <div class="conversation-approval">
    <div v-if="!isAccepted && !isDeclined" class="conversation-approval__request">
      <p class="conversation-approval__message">
        {{ conversationApprovalMessage }}
      </p>
      <div class="conversation-approval__actions">
        <button
          class="conversation-approval__button conversation-approval__button--approve text-button"
          @click="approveConversation"
        >
          <icon-component
            name="check-outlined"
            class="conversation-approval__icon conversation-approval__icon--approve"
          />
          Allow
        </button>
        <button
          class="conversation-approval__button conversation-approval__button--decline text-button"
          @click="declineConversation"
        >
          <icon-component
            name="x"
            class="conversation-approval__icon conversation-approval__icon--decline"
          />
          Decline
        </button>
      </div>
    </div>

    <div v-if="isAccepted" class="conversation-approval__result">
      <icon-component
        name="check-outlined"
        class="conversation-approval__icon conversation-approval__icon--approve"
      />
      <p class="conversation-approval__message">
        Future messages from this person will automatically be allowed.
      </p>
    </div>
    <div v-if="isDeclined" class="conversation-approval__result">
      <icon-component
        name="x"
        class="conversation-approval__icon conversation-approval__icon--decline"
      />
      <p class="conversation-approval__message">
        This message has been blocked and you will not receive future requests from this person.
        <br>
        <router-link :to="{ name: 'messagesBlocked' }">View blocked</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { UPDATE_CONVERSATION } from '@/graphql/mutations/private-messaging-mutations';
import { GET_CONVERSATIONS } from '@/graphql/queries/private-messaging-queries';
import { isFeatureFlagEnabled } from '@/utils/utils';

export default {
  name: 'ConversationApproval',
  props: {
    conversationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAccepted: false,
      isDeclined: false,
    };
  },
  computed: {
    restrictPrivateMessaging() {
      return isFeatureFlagEnabled(this.$site, 'restrict_private_messaging');
    },
    conversationApprovalMessage() {
      if (this.restrictPrivateMessaging) {
        return 'This person has requested to send you a message.';
      }
      return 'This person has requested to send you a private message.';
    },
  },
  methods: {
    approveConversation() {
      this.updateConversation(true);
    },
    declineConversation() {
      this.updateConversation(false);
      this.$emit('blocked', true);
    },
    updateConversation(accepted) {
      this.$apollo.mutate({
        mutation: UPDATE_CONVERSATION,
        variables: {
          conversationId: this.conversationId,
          isAccepted: accepted,
        },
        update: (cache, { data: { updateConversation } }) => {
          // read from cache
          const data = cache.readQuery({
            query: GET_CONVERSATIONS,
            variables: {
              isArchived: this.isDeclined,
            },
          });

          // update cache data
          if (updateConversation.isArchived) {
            // remove the archived conversation
            const indexOfConversation = data.conversations.findIndex(
              (c) => c.conversationId === updateConversation.id,
            );
            data.conversations.splice(indexOfConversation, 1);
          }

          // write to cache
          cache.writeQuery({
            query: GET_CONVERSATIONS,
            data,
          });
        },
      }).then(({ data: { updateConversation } }) => {
        if (updateConversation.isAccepted) {
          this.isAccepted = true;
        } else {
          this.isDeclined = true;
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: this.restrictPrivateMessaging ? 'You have declined this message.' : 'You have declined this private message.',
            type: 'Messaging',
          });
          this.$router.push({ name: 'messages' });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_conversation-approval';
</style>

<docs>
This component is rendered when a user receives the first message from another user.
Declining the conversation will move it to the users blocked conversation list and
prevent further messages from this conversation being received.

</docs>
