export function prepRequestHeaders(context, store) {
  const headers = context.req ? { ...context.req.headers } : {};

  // apollo freaks out with connection header on node18
  delete headers.connection;
  // see if this resolves the UND_ERR_REQ_CONTENT_LENGTH_MISMATCH errors
  delete headers['content-length'];

  if (!process.browser) {
    headers['NEX-PATH'] = context.url;
    headers['NEX-SSR'] = 1;
    headers['NEX-UUID'] = store.getters.pageViewId;
    headers['NEX-HOST'] = headers.host;
    headers.accept = '*/*';
  }

  return headers;
}
