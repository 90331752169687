import gql from 'graphql-tag';

export const EXPERIMENT_VARIANT = gql`
query getExperimentVariant($symbol: String!) {
  getExperimentVariant(symbol: $symbol) {
    experimentId
    versionId
    key
  }
}
`;
