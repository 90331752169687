const profileTooltipsState = () => ({
  profileTooltipUserId: '',
  tooltipPosition: {},
  isCrossPublished: false,
  originalSite: '',
});

const getters = {
  showTooltip: (state) => !!state.profileTooltipUserId,
  profileTooltipUserId: (state) => state.profileTooltipUserId,
  profileTooltipPosition: (state) => state.tooltipPosition,
  isCrossPublished: (state) => state.isCrossPublished,
  originalSite: (state) => state.originalSite,
};

const mutations = {
  addProfileTooltip(state, data) {
    state.profileTooltipUserId = data.userId;
    state.tooltipPosition = data.position;
    state.isCrossPublished = data.isCrossPublished;
    state.originalSite = data.originalSite;
  },
  removeProfileTooltip(state) {
    state.profileTooltipUserId = '';
    state.position = {};
    state.isCrossPublished = false;
    state.originalSite = '';
  },
};

const actions = {
  addProfileTooltip({ commit }, data) {
    commit('addProfileTooltip', data);
  },
  removeProfileTooltip({ commit }) {
    commit('removeProfileTooltip');
  },
};

export default () => ({
  state: profileTooltipsState(),
  getters,
  mutations,
  actions,
});
