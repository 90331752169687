import gql from 'graphql-tag';

const RECOMMENDATION_FRAGMENT = gql`
fragment recommendationFragment on SearchResult {
  author {
    id
    username
  }
  body
  commentCount
  date
  id
  lastActiveAt
  modified
  name
  slug
  tags {
    id
    name
  }
  isFeatured
  title
  type
  originalSite
  permalink
  featuredMedia {
    path
    url
    name
    alt
    urlMedium
    urlLarge
    urlRecommended
    huIllustratorName
  }
}
`;

export const GET_RECOMMENDATIONS = gql`
${RECOMMENDATION_FRAGMENT}
  query recommend($assocId: NexusId, $assocType: RecommendAssocType, $docResultType: RecommendDocResultType, $limit: Int ) {
    recommend(assocId: $assocId, assocType: $assocType, docResultType: $docResultType, limit: $limit) {
      ...recommendationFragment
    }
  }
`;

export const GET_RECENTS_AS_RECOMMENDATIONS = gql`
${RECOMMENDATION_FRAGMENT}
query recentsAsRecommendations {
  recentsAsRecommendations {
    ...recommendationFragment
  }
}
`;
