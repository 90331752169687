const meta = (site, url) => [
  { property: 'og:locale', content: 'en_US' },
  { property: 'og:type', content: 'website' },
  { vmid: 'og:title', property: 'og:title', content: site.name },
  { vmid: 'description', name: 'description', content: site.defaultMeta['metadesc-homepage'] },
  { vmid: 'og:description', property: 'og:description', content: site.defaultMeta['metadesc-homepage'] },
  { property: 'og:url', content: url },
  { property: 'og:site_name', content: site.name },
  { vmid: 'og:image', property: 'og:image', content: site.og_image_default },
  { property: 'fb:app_id', content: site.settings.meta ? site.settings.meta.fb_app_id : '' },
  { property: 'fb:page_id', content: site.settings.meta ? site.settings.meta.fb_page_id : '' },
  { name: 'twitter:card', content: 'summary' },
  { vmid: 'twitter:description', name: 'twitter:description', content: site.defaultMeta['metadesc-homepage'] },
  { name: 'twitter:site', content: site.settings.meta ? site.settings.meta.twitterHandle : '' },
  { vmid: 'twitter:image', name: 'twitter:image', content: '/images/logo_avatar@48x48.png' },
  { name: 'theme-color', content: site.settings.pwa_settings && site.settings.pwa_settings.theme_color ? site.settings.pwa_settings.theme_color : '#FFFFFF' },
];

export default meta;
