function hostnameFromContext(context) {
  return new URL(`https://${context.req.headers.host}`).hostname;
}

function host(context) {
  return context && context.req ? hostnameFromContext(context) : window.location.host;
}

function apiPath(context = {}) {
  if (process.browser) {
    return window.location.origin;
  }

  return context.huApiHost;
}

function websocketPath(req) {
  const hostname = host(req);
  if (process.env.VUE_APP_TEST === 'true') {
    return 'wss://localhost:4001/api/socket';
  }

  return `wss://${hostname}/api/socket`;
}

function sameSiteLink(link, req) {
  return link.indexOf(host(req)) > 0;
}

export {
  host,
  apiPath,
  sameSiteLink,
  websocketPath,
};
